import React from 'react';
import{Link} from 'react-router-dom'


export const options = {
    sortOrder: {
        name: 'id',
        direction: 'desc'
    },
    confirmFilters: true,  
    customFilterDialogFooter: (currentFilterList, applyNewFilters) => { 
        return (
            <div style={{ marginTop: '40px' }}>
                <button className="btn btn-info small" onClick={()=>applyNewFilters()}>Приложи филтрите</button>
            </div>
        );
    },
    print: false, 
    viewColumns: false,
    filter:false, 
    selectableRows: "none",   
    download: true,
    downloadOptions: {
        filename: 'brands.csv',
        separator: ',',
        filterOptions: {
          useDisplayedColumnsOnly: true,
        } 
    },
    textLabels: {
        body: {
            noMatch: "Няма намерени резултати",
            toolTip: "Сортирай",
            columnHeaderTooltip: column => `Сортирай по ${column.label}`
        },
        pagination: {
            next: "Следваща страница",
            previous: "Предишна страница",
            rowsPerPage: "Брой записи на страница:",
            displayRows: "от",
        },
        toolbar: {
            search: "Търси",
            downloadCsv: "Download CSV",
            print: "Принтирай",
            viewColumns: "Колони",
            filterTable: "Филтри",
        },
        filter: {
            all: "Всички",
            title: "Филтри",
            reset: "Откажи",
        },
        viewColumns: {
            title: "Покажи Колони",
            titleAria: "Покажи/Скрии Колони",
        },
        selectedRows: {
            text: "запис/и са селектирани за изтриване",
            delete: "Изтриване",
            deleteAria: "Изтриите избраните записи",
        },
    }
}; 

export const columns = [ 
    {
        label: "ID:",
        name: "id",
        options: {
            filter: false,
            sort:true,
           }
    },
    {
        label: "Марка:",
        name: "name",
        options: {
            filter: false,
            sort: false,
           }
    }, 
    {
        label: "Модели:",
        name: "models",
        options: {
            filter: false,
            sort: false,
            customBodyRender: (value, tableMeta, updateValue) => {
                let id = tableMeta.rowData[0];
                const permissions = JSON.parse(localStorage.getItem('permissions'));
                if (permissions.modelsShow) { 
                    if (value) {
                        return(  
                            <div className='model-chip-wrapper'>
                            {value.map(model=>{  
                                return (<Link className="model-chip" key={model.id} to={'/dashboard/model/'+model.id+'?brand_Id='+id}>{model.name}</Link> )
                            })}
                            </div> 
                        ) 
                    }
                }else{
                    return 'нямате достъп'
                }
            }
        }
    },   
    {
        label: "Добави модел:",
        name: "аdd",
        options: {
            filter: false,
            sort: false,
            customBodyRender: (value, tableMeta, updateValue) => {  
                let id = tableMeta.rowData[0];
                const permissions = JSON.parse(localStorage.getItem('permissions'));
                if (permissions.modelsStore) {
                    return <Link to={'/dashboard/create-model/?brand_Id='+id} className='small btn'><i className='fas fa-folder-plus'></i>Добави</Link>
                }else{
                    return 'нямате достъп'
                } 
             
            }
        }
    },   
    {
        label: "Редактирай марка:",
        name: "аdd",
        options: {
            filter: false,
            sort: false,
            customBodyRender: (value, tableMeta, updateValue) => {  
                const permissions = JSON.parse(localStorage.getItem('permissions'));
                let id = tableMeta.rowData[0];
                if (permissions.brandsUpdate) {
                    return <Link to={'/dashboard/brands/'+id} className='small btn btn-info'><i className='flaticon-edit'></i>Редактирай</Link>
                }else{
                    return 'нямате достъп'
                }
             
                
            }
        }
    },   
];
