import React, { Component } from 'react';
import {connect} from 'react-redux' 
import { withRouter } from "react-router-dom";

import TextField from '@material-ui/core/TextField'; 
import InputAdornment from '@material-ui/core/InputAdornment';
 
 
import Grid from '@material-ui/core/Grid'; 

import Autocomplete from '@material-ui/lab/Autocomplete';

import {fetchSaleCarsAutoComplete} from '../../../_actions/carsSaleActions'
import {getCompaniesList} from '../../../_actions/companiesActions' 
import {setTempOffer} from '../../../_actions/offersActions' 


 
class CarInfo extends Component {
    
    state={
        data:{
            carId:'', 
            brand:'', 
            model:'',
            transmission:'',
            engineType:'',
            externalColorDescription:'',
            vin:'',
            inventoryNumber:'',  
            listPrice:'', 
        },
        carName:'',
        selected_car:false,
        errors:[],   
        roleId: JSON.parse(localStorage.getItem('roleId')),

    }  
  
    componentDidMount(){  
        this.getPassedData()
        this.props.getCompaniesList();   
       
       
        // const query = new URLSearchParams(this.props.location.search);
        // const selected_car = query.get('selected_car')
        // if (selected_car) {
        
          
        // }
    }
    
    componentWillUnmount(){
        this.props.handleData('car', this.state.data) 
 
    }

    componentDidUpdate(previousProps, previousState){    
        if(previousProps.offer !== this.props.offer){  
            this.getPassedData()
        }    
    }    

    getPassedData = () =>{
        const{editMode, offer}=this.props;   
        if (offer.car) {     
            this.setState({ 
                data:offer.car,
                carName:offer.car.brand +' - '+ offer.car.model +' - '+  offer.car.vin
            }) 
        } 
    }
    
    openSaleCarList = () =>{
        const{editMode, offer}=this.props;   
        this.props.setTempOffer(offer)
        if (editMode) {  
            this.props.history.push("/dashboard/saleCarsList?offer_Id="+offer.id);  
        }else{ 
            this.props.history.push("/dashboard/saleCarsList?new_offer=true"); 
        }
    }

    onChange = (event) =>{
        const {name,value} = event.target;  
        this.setState({
            data:{
                ...this.state.data,
                [name]:value
            }
        })   

    }
    
    changeCarData = (event, value, reason) => {
        if (value) {  
            this.props.history.push(this.props.match.url) 
            const { id, brand,brandId, model,modelId,transmission,engineType,externalColorDescription,vin,inventoryNumber,listPrice}=value  
            this.setState({
                data:{
                    ...this.state.data, 
                    brand,brandId, model,modelId,transmission,engineType,externalColorDescription,vin,inventoryNumber,listPrice,carId:id
                },
                carName:brand +' - '+ model +' - '+  vin
            })     
        }else{
      
            this.setState({
                data:{
                    ...this.state.data, 
                    carId:'',brand:'',brandId:'',model:'',modelId:'',transmission:'', engineType:'',externalColorDescription:'',vin:'',inventoryNumber:'',listPrice:''
                },
                carName:""
            }) 
        }
    }

    changeCarName = (event, value, reason) => { 
        if (value.length>3) {  
            this.props.fetchSaleCarsAutoComplete(value).then(data => { 
        
                if(data.status==="success" && data.data.length>0){ 
                    const { id, brand,brandId, model,modelId,transmission,engineType,externalColorDescription,vin,inventoryNumber,listPrice }=data.data[0]  
                    this.setState({ 
                        data:{
                            ...this.state.data,
                            brand,brandId, model,modelId,transmission,engineType,externalColorDescription,vin,inventoryNumber,listPrice,carId:id
                        },
                        selected_car:true
                    })
                 }
            })      
            this.setState({errors:{}})
        }   
        this.setState({carName:value})
        if (value.length===0) {
            this.setState({
                data:{
                    ...this.state.data, 
                    carId:'',brand:'',brandId:'',model:'',modelId:'',transmission:'', engineType:'',externalColorDescription:'',vin:'',inventoryNumber:'',listPrice:'',
                   
                },
                carName:""
            }) 
        }
    }

    nextStep = ()=>{
    
        const{carId}=this.state.data;
        if (carId) {
            this.props.nextStep(carId,3)
            this.props.handleData('car', this.state.data) 
        }else{
            this.setState({errors:{carAutoComplete:'Моля изберете Автомобил'}})
        }
   
    }

    render() { 
        const {carId, brand, model,transmission,engineType,externalColorDescription,vin,inventoryNumber,listPrice}= this.state.data;
        const {carName, roleId} = this.state;
        const {errors={}} = this.props;
        const {editMode, companies, saleCars}=this.props;     
   
        
        return (  
            <>   
                <Grid container item md={4} xs={12} alignItems="center"> 
                    <h3 className="section-title">
                        Избери от списък с автомобили:
                        <br /><br /> 
                    </h3>
                   
                </Grid>   
                <Grid container item xs={12} spacing={2}> 
                    <Grid item md={4} xs={12}>  
                        <Autocomplete 
                            disabled
                            options={saleCars}  
                            inputValue={carName}
                            onChange={this.changeCarData} 
                            onInputChange={this.changeCarName} 
                            getOptionLabel={option => { 
                                return (option.brand +' - '+ option.model +' - '+  option.vin )
                            }}
                            renderInput={params => (
                                <TextField {...params}
                                className="full-width"
                                InputLabelProps={{shrink: true}} 
                                margin="normal"
                                variant="outlined"
                                label="Автомобили:" 
                                error ={errors.carAutoComplete?true:false} 
                                helperText={errors.carAutoComplete}  
                                />
                            )} 
                        />  
                        <br /><br /> 
                    </Grid> 
                    <Grid item md={4} xs={12}>  
                        {roleId!==19&&<div onClick={this.openSaleCarList} className="btn btn-focus full-width" >Избери от списък</div>}
                        <br/>
                        <br/>
                    </Grid> 
                   
                </Grid> 
                <Grid container item md={4} xs={12} alignItems="center"> 
                    <h3 className="section-title">
                        Данни за автомобила:
                        <br /><br /> 
                    </h3>
                </Grid> 
                <Grid container spacing={3}> 
                    <Grid item md={3} xs={12}>   
                        <TextField inputProps={{'className':'data-hj-allow'}} className="full-width mb-20" 
                            disabled
                            InputLabelProps={{shrink: true}}  
                            margin="normal" 
                            variant="outlined"  
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <i className="fas fa-car"></i>  
                                    </InputAdornment>
                                ),
                            }}
                            label="Марка"
                            name="brand"
                            value={brand}
                            error ={errors.brand?true:false} 
                            helperText={errors.brand} 
                            onChange={this.onChange}  
                        />
                        <TextField inputProps={{'className':'data-hj-allow'}} className="full-width mb-20" 
                            disabled
                            InputLabelProps={{shrink: true}}  
                            margin="normal" 
                            variant="outlined"  
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <i className="fas fa-car"></i>  
                                    </InputAdornment>
                                ),
                            }}
                            label="Модел"
                            name="model"
                            value={model}
                            error ={errors.model?true:false} 
                            helperText={errors.model} 
                            onChange={this.onChange}  
                        />
                    </Grid>    
                    <Grid item md={3} xs={12}>  
                        <TextField inputProps={{'className':'data-hj-allow'}} className="full-width mb-20" 
                            disabled
                            InputLabelProps={{shrink: true}}  
                            margin="normal" 
                            variant="outlined"  
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <i className="fas fa-cogs"></i> 
                                    </InputAdornment>
                                ),
                            }}
                            label="Скоростна кутия"
                            name="transmission"
                            value={transmission}
                            error ={errors.transmission?true:false} 
                            helperText={errors.transmission} 
                            onChange={this.onChange}  
                        />
                        <TextField inputProps={{'className':'data-hj-allow'}} className="full-width mb-20" 
                            disabled
                            InputLabelProps={{shrink: true}}  
                            margin="normal" 
                            variant="outlined"  
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <i className="fas fa-cogs"></i> 
                                    </InputAdornment>
                                ),
                            }}
                            label="Двигател"
                            name="engineType"
                            value={engineType}
                            error ={errors.engineType?true:false} 
                            helperText={errors.engineType} 
                            onChange={this.onChange}  
                        />
                    </Grid>    
                    <Grid item md={3} xs={12}>  
                        <TextField inputProps={{'className':'data-hj-allow'}} className="full-width mb-20" 
                            disabled
                            InputLabelProps={{shrink: true}}  
                            margin="normal" 
                            variant="outlined"  
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start"> 
                                        <i className="fas fa-palette"></i>
                                    </InputAdornment>
                                ),
                            }}
                            label="Цвят"
                            name="externalColorDescription"
                            value={externalColorDescription}
                            error ={errors.externalColorDescription?true:false} 
                            helperText={errors.externalColorDescription} 
                            onChange={this.onChange}  
                        />
                        <TextField inputProps={{'className':'data-hj-allow'}} className="full-width mb-20" 
                            disabled
                            InputLabelProps={{shrink: true}}  
                            margin="normal" 
                            variant="outlined"  
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <i className="fas fa-file-invoice"></i> 
                                    </InputAdornment>
                                ),
                            }}
                            label="Шаси"
                            name="vin"
                            value={vin}
                            error ={errors.vin?true:false} 
                            helperText={errors.vin} 
                            onChange={this.onChange}  
                        /> 
                    </Grid>    
                    <Grid item md={3} xs={12}>  
                        <TextField inputProps={{'className':'data-hj-allow'}} className="full-width mb-20" 
                            disabled={roleId!==1}
                            InputLabelProps={{shrink: true}}  
                            margin="normal" 
                            variant="outlined"  
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">  
                                        <i className="fas fa-list-ol"></i> 
                                    </InputAdornment>
                                ),
                            }}
                            label="Инв. номер"
                            name="inventoryNumber"
                            value={inventoryNumber}
                            error ={errors.inventoryNumber?true:false} 
                            helperText={errors.inventoryNumber} 
                            onChange={this.onChange}  
                        />
                        <TextField inputProps={{'className':'data-hj-allow'}} className="full-width mb-20" 
                            disabled
                            InputLabelProps={{shrink: true}}  
                            margin="normal" 
                            variant="outlined"  
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">  
                                        <i className="fas fa-list-ol"></i> 
                                    </InputAdornment>
                                ),
                            }}
                            label="Лист цена"
                            name="listPrice"
                            value={listPrice}
                            error ={errors.listPrice?true:false} 
                            helperText={errors.listPrice} 
                            onChange={this.onChange}  
                        /> 
                    </Grid> 
                </Grid>  
                <Grid container spacing={2} >   
                    <Grid item md={4} xs={12} >     
                        <div className="btn btn-info full-width" onClick={()=>this.nextStep()}>
                            <i className="flaticon2-add-square"></i>Напред
                        </div> 
                    </Grid> 
                </Grid> 
            </>
         );
    }
}
 
const mapStateToProps = (state, props) => {     
    return{
        saleCars:state.carsSale.saleCarsAutoComplete,
        companies:state.companies.list,
        tempOffer:state.offers.tempOffer
    }
}

export default withRouter(connect(mapStateToProps, {fetchSaleCarsAutoComplete, getCompaniesList, setTempOffer })(CarInfo));  

 
 