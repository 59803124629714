import React, { Component } from 'react'; 
import {Link} from 'react-router-dom';

import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import InputAdornment from '@material-ui/core/InputAdornment';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox'; 

import Grid from '@material-ui/core/Grid';
import Card from "@material-ui/core/Card";  

import Autocomplete from '@material-ui/lab/Autocomplete';

import {connect} from 'react-redux'
import {editCar,createCar, fetchCars, fetchFixiCars} from '../../../_actions/carsActions'
import {getCompaniesList} from '../../../_actions/companiesActions'
 

class EditCar extends Component {
    
    state={
        data:{
            id:null,
            brand:'',
            model:'',
            registerNumber:'',
            vin:'',
            color:'',
            fuelType:'', 
            runTime:'',
            initialRunTime:'',
            status:'', 
            company:'', 
            active:1 ,
            version:'',
            seats:'',
            enginе:'',
            engineNumber:'',
            volume:'',
            hPower:'',
            inventoryNumber:''
        },
        roleId: JSON.parse(localStorage.getItem('roleId')),
        editMode:false,
        errors:[]
    }
    
    componentDidMount(){
        this.props.fetchCars('');   
        this.props.fetchFixiCars();   
        this.props.getCompaniesList();   

        if (this.props.match.params._id) {
            this.setState({editMode:true})
        }else{
            this.setState ({
                data:{
                    ...this.state.data,
                    company:localStorage.getItem('companyName'),
                    status:0
                }
            })
        }
    } 
 
    componentDidUpdate(previousProps, previousState){
        if(previousProps.cars !== this.props.cars){
            const {brand,model,registerNumber,vin,color,fuelType,runTime,initialRunTime,status,id,company,active,version,seats,enginе,engineNumber,volume,hPower,inventoryNumber} = this.props.cars
            this.setState ({
                data:{
                    ...this.state.data,
                    brand,model,registerNumber,vin,color,fuelType,runTime,initialRunTime,status,id,company,active,version,seats,enginе,engineNumber,volume,hPower,inventoryNumber
                }
               
            })   
        }
    }

    onChange = (event) =>{
        const {name,value, checked} = event.target; 
        
        if (name==='status' && value) {
            if (checked) {
                this.setState ({
                    data:{
                        ...this.state.data,
                        status:2
                    }
                })
            }else{
                this.setState ({
                    data:{
                        ...this.state.data,
                        status:0
                    }
                })
            }
        }
        else if (name==='active' && value) {
            if (checked) {
                this.setState ({
                    data:{
                        ...this.state.data,
                        active:0
                    }
                }) 
            }else{
                this.setState ({
                    data:{
                        ...this.state.data,
                        active:1
                    }
                })  
            }  
        }else{
            this.setState({
                data:{
                    ...this.state.data,
                    [name]:value
                }
            }) 
        }
         
    }
 
    
    onChangeCarData = (event, value, reason) =>{   
        if (value) { 
            const {brand, model, runTime,initialRunTime, registerNumber} = value
            this.setState({
                data:{
                    ...this.state.data,
                     brand, model, runTime,initialRunTime, registerNumber
                }
            })  
        }else{
            this.setState({
                data:{
                    ...this.state.data,
                    brand:'', model:'', runTime:'',initialRunTime:'', registerNumber:''
                }
            })   
        } 
    }

    onChangeRegisterNumber = (event, value, reason) =>{    
        if(event || value){ 
            this.setState({
                data:{
                    ...this.state.data,
                    registerNumber:value
                }
            }) 
        } 
 
        if (value.length>8) {
            this.setState({
                errors:{...this.state.errors, registerNumber:['Внимание въвели сте повече от 8 символа!']}
            })
        }else{
            this.setState({
                errors:{...this.state.errors, registerNumber:""}
            })
        }
    }
 
    onChangeCompany = (event, value, reason) =>{   
        this.setState({
            data:{
                ...this.state.data,
                company:value
            }
        }) 
    }

    onSubmit = (event) =>{
        event.preventDefault();
        const {editMode} = this.state;
        if (editMode) {
            this.props.editCar(this.state.data).then(data => {
            
                
                if(data.status==="success"){
                    this.setState({errors:[]}) 
                    this.props.history.push('/dashboard/cars')
                } 
                else if (data.errors) {
                    this.setState({errors:data.errors})   
                }   
            }) 
        }else{
            this.props.createCar(this.state.data).then(data => { 
                if(data.status==="success"){
   
                    this.setState({
                        data:{
                            ...this.state.data,
                            id:'',brand:'',model:'',registerNumber:'',vin:'',color:'',fuelType:'',version:'',seats:'',enginе:'',engineNumber:'',volume:'',hPower:'',inventoryNumber:''
                        },
                        errors:[]
                    })
                } 
                else if (data.errors) {
                    this.setState({errors:data.errors})   
                }  
            }) 
        }
    }

    render() { 
        const {brand,model,registerNumber,vin,color,fuelType,runTime,initialRunTime,status,unactiveStatus,company,ListCompanies,active,version,seats,enginе,engineNumber,volume,hPower,inventoryNumber}= this.state.data;
        const {errors,editMode, roleId} = this.state;
        const {fixiCars, companies} = this.props;  
        
        const pageTitle = editMode ? 'Редактиране на тестови aвтомобил:' : 'Създаване на тестови aвтомобил:'; 

        return (  
            <React.Fragment>  
                <Card >
                    <Grid container className="section-title-wrapper padding"> 
                        <Grid container item xs={8} alignItems="center"> 
                            <h3 className="section-title">
                                <i className="flaticon2-gear"></i> {pageTitle}
                            </h3>
                        </Grid>  
                        <Grid container item md={4} xs={12}alignItems="center" justify="flex-end">  
                            <Link  
                                className="btn btn-secondary"
                                to="/dashboard/cars" 
                                ><i className="flaticon2-circular-arrow"/>
                                Назад
                            </Link>   
                        </Grid>  
                    </Grid>  
                    <Grid container className="padding">
                        <form  className='form' noValidate onSubmit={this.onSubmit}>
                            <Grid container spacing={3}> 
                                <Grid item md={4} xs={12}>
                                    <Autocomplete
                                        freeSolo  
                                        inputValue={registerNumber} 
                                        options={fixiCars} 
                                        onChange={this.onChangeCarData} 
                                        onInputChange={this.onChangeRegisterNumber}  
                                        getOptionLabel={option => option.registerNumber} 
                                        renderInput={params => ( 
                                            <TextField {...params}   
                                                className="full-width mb-20" 
                                                InputLabelProps={{shrink: true}} 
                                                required 
                                                margin="normal" 
                                                variant="outlined"   
                                                label="Регистрационнен Номер" 
                                                error ={errors.registerNumber?true:false} 
                                                helperText={errors.registerNumber}  
                                            />   
                                        )}
                                    /> 
                                 
                                    <TextField inputProps={{'className':'data-hj-allow'}} className="full-width mb-20" 
                                        InputLabelProps={{shrink: true}} 
                                        required 
                                        margin="normal" 
                                        variant="outlined"  
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <i className="flaticon2-rocket"></i>  
                                                </InputAdornment>
                                            ),
                                        }}
                                        label="Марка"
                                        name="brand"
                                        value={brand}
                                        error ={errors.brand?true:false} 
                                        helperText={errors.brand} 
                                        onChange={this.onChange}  
                                    />  
                                    <TextField inputProps={{'className':'data-hj-allow'}} className="full-width mb-20" 
                                        InputLabelProps={{shrink: true}} 
                                        required 
                                        margin="normal" 
                                        variant="outlined"  
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <i className="flaticon2-start-up"></i>  
                                                </InputAdornment>
                                            ),
                                        }}
                                        label="Модел"
                                        name="model"  
                                        value={model}
                                        error ={errors.model?true:false} 
                                        helperText={errors.model} 
                                        onChange={this.onChange} 
                                    />     
                                    
                                    
                                </Grid>   
                                <Grid item md={4} xs={12}>
                                    <TextField inputProps={{'className':'data-hj-allow'}} className="full-width mb-20" 
                                        InputLabelProps={{shrink: true}} 
                                        required 
                                        margin="normal" 
                                        variant="outlined"  
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <i className="flaticon-notepad"></i>  
                                                </InputAdornment>
                                            ),
                                        }}
                                        label="Шаси №"
                                        name="vin"   
                                        value={vin}
                                        error ={errors.vin?true:false} 
                                        helperText={errors.vin} 
                                        onChange={this.onChange} 
                                    />  
                                     <TextField inputProps={{'className':'data-hj-allow'}} className="full-width mb-20" 
                                        InputLabelProps={{shrink: true}} 
                                        required 
                                        margin="normal" 
                                        variant="outlined"  
                                        InputProps={{
                                            startAdornment: (
                                            <InputAdornment position="start">
                                                        <i className="flaticon-notepad"></i>  
                                            </InputAdornment>
                                            ),
                                        }}
                                        label="Цвят"
                                        name="color"   
                                        value={color}
                                        error ={errors.color?true:false} 
                                        helperText={errors.color} 
                                        onChange={this.onChange} 
                                    />   
                                    <Autocomplete
                                        freeSolo   
                                        disabled={roleId!==1}
                                        options={companies}  
                                        inputValue={company}
                                        onInputChange={this.onChangeCompany} 
                                        getOptionLabel={option => option.name} 
                                        renderInput={params => ( 
                                            <TextField
                                                {...params}
                                                className="full-width mb-20 " 
                                                InputLabelProps={{shrink: true}} 
                                                margin="normal" 
                                                variant="outlined" 
                                                label="Компания"  
                                                error={errors.company?true:false} 
                                                helperText={errors.company}   
                                            />  
                                        )}
                                    />   
                                </Grid>   
                                <Grid item md={4} xs={12}> 
                                    
                                    <FormControl variant="outlined" error={errors.fuelType?true:false} margin="normal" className='mb-20 full-width select-styles'>
                                        <InputLabel id="fuelType">
                                            Гориво 
                                        </InputLabel>
                                        <Select  
                                            MenuProps={{
                                                anchorOrigin: {
                                                vertical: "bottom",
                                                horizontal: "right"
                                                },
                                                transformOrigin: {
                                                vertical: "top",
                                                horizontal: "right"
                                                },
                                                getContentAnchorEl: null
                                            }} 
                                            labelId="fuelType"
                                            required   
                                            inputProps={{
                                                name: 'fuelType', 
                                            }} 
                                            value={fuelType} 
                                            onChange={this.onChange}
                                            labelWidth={70}  >    
                                            <MenuItem value='1'>Бензин</MenuItem>  
                                            <MenuItem value='2'>Дизел</MenuItem>  
                                            <MenuItem value='3'>Метан</MenuItem>  
                                            <MenuItem value='4'>Газ</MenuItem>  
                                            <MenuItem value='5'>Електрически</MenuItem>  
                                        </Select>
                                        {errors.fuelType&&<FormHelperText>{errors.fuelType}</FormHelperText>}
                                    </FormControl>   
                                    {editMode&&<>
                                        <TextField inputProps={{'className':'data-hj-allow'}} className="full-width mb-20" 
                                            InputLabelProps={{shrink: true}} 
                                            disabled 
                                            margin="normal" 
                                            variant="outlined"  
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <i className="flaticon2-chronometer"></i>  
                                                    </InputAdornment>
                                                ),
                                            }}    
                                            label="Пробег"
                                            name="runTime" 
                                            value={runTime}
                                            error ={errors.runTime?true:false} 
                                            helperText={errors.runTime} 
                                            onChange={this.onChange} 
                                        />   
                                        <TextField inputProps={{'className':'data-hj-allow'}} className="full-width mb-20" 
                                            InputLabelProps={{shrink: true}} 
                                            disabled 
                                            margin="normal" 
                                            variant="outlined"  
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <i className="flaticon2-chronometer"></i>  
                                                    </InputAdornment>
                                                ),
                                            }}    
                                            label="Първоначален пробег"
                                            name="initialRunTime" 
                                            value={initialRunTime}
                                            error ={errors.initialRunTime?true:false} 
                                            helperText={errors.initialRunTime} 
                                            onChange={this.onChange} 
                                        />   
                                    </>} 
                                </Grid> 
                            </Grid> 
                            <Grid container spacing={2} >   
                              
                                {editMode &&<Grid container item md={4} xs={12} >    
                                    <FormControlLabel
                                        control={
                                        <Checkbox
                                            name='active'
                                            checked={active===0}
                                            onChange={this.onChange}
                                            value="active"
                                            color="primary"
                                        />
                                        }
                                        label="Скрий"
                                    />     
                                </Grid>}
                               <Grid container item md={4} xs={12} >    
                                    {status!==1&& <FormControlLabel
                                        control={
                                        <Checkbox
                                            name='status'
                                            checked={status==2}
                                            onChange={this.onChange}
                                            value="status"
                                            color="primary"
                                        />
                                        }
                                        label="Неактивен"
                                    />}   
                                </Grid>
                               <Grid container item md={4} xs={12} >    
                                    {editMode&&<div className="full-width mb-20">
                                        <p className={"text-label " + (!status ? 'free':'')}>
                                            {!status ?'Свободен':
                                            status==1 ?'Зает':'Неактивен'
                                            }
                                        </p>
                                    </div>
                                    }
                                </Grid>
                                
                            </Grid>   
                            <Grid container spacing={2} >   
                                <Grid container item md={4} xs={12} >    
                                    {roleId!==19&&<button type="submit" className="btn btn-info full-width">
                                        <i className="flaticon2-add-square"></i>
                                        {editMode?'ЗАПАЗИ':'Създай'}
                                    </button>}  
                                </Grid> 
                            </Grid>   
                        </form>
                    </Grid>  
                </Card> 
            </React.Fragment>
         );
    }
}

const mapStateToProps = (state, props) => {   
     
    if (props.match.params._id ) {  
        return{
            cars: state.cars.items.find(el => el.id==props.match.params._id), 
            fixiCars:state.cars.fixiCars,
            companies:state.companies.list
        }  
    }else{
        return{
            cars: null,
            fixiCars:state.cars.fixiCars,
            companies:state.companies.list
        }
    }   
 }
 
export default connect(mapStateToProps, {fetchCars, editCar,createCar, fetchFixiCars, getCompaniesList })(EditCar);
 