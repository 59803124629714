import React, { Component } from 'react';

import {connect} from 'react-redux'
import { withRouter } from "react-router-dom";

import Grid from '@material-ui/core/Grid';
 
import "moment/locale/bg";
import { CircularProgress } from '@material-ui/core'; 

import MUIDataTable from "mui-datatables"; 
import Card from "@material-ui/core/Card";   
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';


import {columns, options} from "../../../helpers/viewCarsSale";  
import CustomFilter from "./CustomFilter";  
import {storeColumnsFilters, getStoredColumnsFilters} from "../../../helpers/storeColumns";  
import CustomToolbar from "../../../helpers/CustomToolbar";
import {fetchSaleCars , importSalesCars,importSalesCarsNisssan, suncCSV, sendFileNav, fetchSaleCarsByStatus} from '../../../_actions/carsSaleActions'
import {getBrandsAllowImport} from '../../../_actions/brandsActions'

const loadingComponent = (
    <div style={{position: 'absolute', zIndex: 110, top: 0, left: 0, width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', background: 'rgba(255,255,255,0.8)'}}>
    <CircularProgress size={24} />
</div>
);

class CarsSalesList extends Component {
    constructor(props) {
        super(props);
        this.searchTextRef = React.createRef();
    }
    state={ 
        permissions: JSON.parse(localStorage.getItem('permissions')),
        roleId: JSON.parse(localStorage.getItem('roleId')),
        brandId:1,
        activeTab:1,
        isLoading:false,
        options:{},
        columns:[],
        filtersStatus:false,
        appliedFilters:false,
        clearFilters:false,
        searchValue:"", 
        openCustomSearch:false
    }
    componentDidMount (){ 
        this.setState({
            options: storeColumnsFilters('carsSaleTable', options),
            columns: getStoredColumnsFilters('carsSaleTable', columns),
            isLoading:true
        })
        // this.props.fetchSaleCars() 
        this.props.fetchSaleCars(1).then(data => {  
            this.setState({isLoading:false}) 
        }) 


        this.props.getBrandsAllowImport();
    } 

    onDownload = (buildHead, buildBody, columns, data) => {
        return "\uFEFF" + buildHead(columns) + buildBody(data); 
    } 
    
    onRowClick=(rowData , rowMeta, e) => {  
        this.props.history.push("/dashboard/sale_cars/"+rowData[0]);
    }

    changeVisibleCars = (type)=>{
        this.setState({isLoading:true})
        if (type!==0) {
            this.props.fetchSaleCars(type).then(data => {  
                this.setState({isLoading:false}) 
            }) 
        }else{
            this.props.fetchSaleCars().then(data => {  
                    this.setState({isLoading:false}) 
            }) 
        }

        this.setState({activeTab:type})
    }

    changeVisibleCarsByStatus = (type)=>{
        this.setState({isLoading:true})
        if (type!==0) {
            this.props.fetchSaleCarsByStatus(type).then(data => {  
                this.setState({isLoading:false}) 
            }) 
        }else{
            this.props.fetchSaleCars().then(data => {  
                this.setState({isLoading:false}) 
            }) 
        }

        this.setState({activeTab:type})
    }

    onChange = (event)=>{ 
        const {name, value}= event.target; 
        this.setState({[name]:value}) 
    }

    addFile =(event)=>{
       const {brandId} = this.state; 
        if (brandId==='stock'|| brandId==='priceList'|| brandId==='promoPriceList' || brandId==='variation') {
            this.props.importSalesCarsNisssan(brandId, event.target.files[0]).then(data => {
                if(data.status==="success"){
                    this.props.fetchSaleCars().then(data => {  
                        this.setState({isLoading:false}) 
                    })  
                }
            })
        }else{
            this.props.importSalesCars(brandId, event.target.files[0]).then(data => {
                if(data.status==="success"){
                    this.props.fetchSaleCars().then(data => {  
                        this.setState({isLoading:false}) 
                    })  
                }
            })
        } 
    }

    addFileNav = (event)=>{
        this.props.sendFileNav(event.target.files[0]).then(data => {
            if(data.status==="success"){
                this.props.fetchSaleCars().then(data => {  
                    this.setState({isLoading:false}) 
                })  
            }
        })
    }

    suncCSV = () => {  
        this.props.suncCSV().then(data => {  
            if(data.status==="success"){ 
                this.props.fetchSaleCars().then(data => {  
                    this.setState({isLoading:false}) 
                })  
            }  
        }) 
    } 

    openFilters = (conditon) =>{ 
        if (conditon) {
            document.getElementsByTagName("body")[0].setAttribute("style", "overflow-y: hidden")
        }else{
            document.getElementsByTagName("body")[0].removeAttribute("style") 
        }
        this.setState({filtersStatus:conditon})
    }

    appliedFilters = (conditon) =>{
        if (conditon) {
            this.setState({appliedFilters:conditon})
        }else{
            this.setState({
                appliedFilters:conditon,
                clearFilters:false
            })
        }
 
    }

    clearFilters = ()=>{
        this.setState({clearFilters:true})
    }
  
    onCustomSearch = (value) =>{ 
        this.setState({filtersStatus:false})
        this.openFilters(false)
    }
 
 
   
    onCustomSearchDesktop = (event) => {
        event.preventDefault();
        this.setState({searchValue:this.searchTextRef.current.value})
    }

    render() {  
        const {activeTab, isLoading, brandId, permissions={}, roleId, searchValue} = this.state;  
        const {carsSale={}, allowImport} = this.props; 
        const {options, columns, filtersStatus, appliedFilters, clearFilters} = this.state;    
 
        return (  
            <React.Fragment>  
              
                 <CustomFilter 
                    clearFilters={clearFilters}
                    appliedFilters={this.appliedFilters}
                    openFilters={this.openFilters}   
                    filtersStatus={filtersStatus}
                    onCustomSearch={this.onCustomSearch}
                >
                </CustomFilter>
                <Grid container spacing={3} className='hide-on-mobile'>
                    <Grid container item lg={2} md={6} xs={12} alignItems="center">  
                        {roleId!==19&&permissions.salesCarsSync&&<button  
                            onClick={this.suncCSV}
                            className="btn btn btn-focus"> 
                            Синхронизирай CSV
                        </button>}
                    </Grid>    
                    <Grid container item lg={4} md={6} xs={12} alignItems="center">   
                    </Grid>    
                    <Grid container item lg={2} md={6} xs={12} alignItems="center">  
                        {roleId!==19&&<div className='btn top-button full-width'style={{
                            'position':'relative',
                            'marginTop': '7px'
                        }}> 
                            <input onChange={(e)=> {this.addFileNav(e); e.target.value=null }}   name="file" id="addFileNav" type="file" className="btn btn-warning full-width" style={{
                                'opacity':0,'margin':0,'position':'absolute' ,'top': '0','left': '0','width': '100%','height': '100%'
                            }}/>
                            <i className="far fa-file-alt"></i>
                                качване от нав
                        </div>}
                    </Grid>    
                    {(roleId!==19&&(permissions.salesCarsImport||permissions.salesCarsNisanImport))&&<><Grid container item lg={2} md={2} xs={12} alignItems="center"> 
                        <FormControl variant="outlined" margin="normal" className='full-width select-styles'>
                            <InputLabel id="fuelType">
                                Марка 
                            </InputLabel>
                            <Select  
                                MenuProps={{
                                    anchorOrigin: {
                                    vertical: "bottom",
                                    horizontal: "right"
                                    },
                                    transformOrigin: {
                                    vertical: "top",
                                    horizontal: "right"
                                    },
                                    getContentAnchorEl: null
                                }} 
                                labelId="brandId"
                                required   
                                inputProps={{
                                    name: 'brandId', 
                                }}  
                                value={brandId} 
                                onChange={this.onChange}
                                labelWidth={70}>  
                                {permissions.salesCarsImport&&allowImport.map(brand=>{
                                    return <MenuItem key={brand.id} value={brand.id}>{brand.name}</MenuItem>  
                                })}
                                {permissions.salesCarsNisanImport&&<MenuItem value='stock'>Нисан - наличности</MenuItem> }         
                                {permissions.salesCarsNisanImport&&<MenuItem value='priceList'>Нисан - Цени</MenuItem>  }      
                                {permissions.salesCarsNisanImport&&<MenuItem value='promoPriceList'>Нисан - Промо цени</MenuItem>  }      
                                {permissions.salesCarsNisanImport&&<MenuItem value='variation'>Нисан - Вариации</MenuItem>}
                            </Select> 
                        </FormControl>   
                    </Grid>   
                    <Grid container item lg={2} md={4} xs={12} alignItems="center">  
                        <div className='btn btn-warning full-width'style={{
                            'position':'relative',
                            'marginTop': '7px'
                        }}> 
                            <input onChange={(e)=> {this.addFile(e); e.target.value=null }}   name="file" id="addFile" type="file" className="btn btn-warning full-width" style={{
                                'opacity':0,'margin':0,'position':'absolute' ,'top': '0','left': '0','width': '100%','height': '100%'
                            }}/>
                            <i className="far fa-file-alt"></i>
                                Качи
                        </div>
                    </Grid> 
                    </>}
                </Grid> 
                <Grid container className="mb-20" spacing={3}>     
                    <Grid container item lg={4} xs={12} alignItems="center"> 
                    </Grid>    
                    <Grid container item lg={2} md={4} xs={6} alignItems="center"> 
                        <button onClick={()=>this.changeVisibleCars(0)} className={"btn full-width " + (activeTab ===0 ? 'btn-focus':'') }>
                            Виж всички
                        </button>
                    </Grid>    
                    <Grid container item lg={2} md={4} xs={6} alignItems="center"> 
                        <button onClick={()=>this.changeVisibleCarsByStatus(7)} className={"btn full-width " + (activeTab ===7 ? 'btn-focus':'') }>
                            Виж продадени
                        </button>
                    </Grid>    
                    <Grid container item lg={2} md={4} xs={6} alignItems="center"> 
                        <button onClick={()=>this.changeVisibleCars(1)} className={"btn full-width " + (activeTab ===1 ? 'btn-focus':'') }>
                            Виж налични 
                        </button>
                    </Grid>    
                    <Grid container item lg={2} md={4} xs={6} alignItems="center"> 
                        <button onClick={()=>this.changeVisibleCars(2)} className={"btn full-width " + (activeTab ===2 ? 'btn-focus':'') }>
                            Виж неналични 
                        </button>
                    </Grid>    
                </Grid>  
                <Card style={{'position':'relative'}}  className='static scroll-x'>
                    {isLoading && loadingComponent}
                    {window.outerWidth>767&&<div className="custom-search">
                        <form noValidate onSubmit={this.onCustomSearchDesktop}>
                            <input
                                placeholder='Въведи критерии'
                                type="text" 
                                ref={this.searchTextRef}  
                            />
                            <button className="btn btn-info" type='submit'> 
                                {/* <i className="fas fa-search mr-10"></i>  */}
                                търси
                            </button> 
                        </form>
                    </div>} 
                    <MUIDataTable className="col-width"
                        title={<>
                        <h6 className="dataTable-title">Aвтомобили за продажба:</h6> 
                       
                        {appliedFilters&&<button onClick={this.clearFilters} className="clearFilters-btn">
                            <span className="text">Изчисти всички филтри</span> 
                            <i className="fas fa-times-circle"></i>
                        </button>}
                        </>}
                        data={carsSale}
                        columns={columns} 
                        options={{
                            ...options, 
                            onRowClick: this.onRowClick, 
                            onDownload:this.onDownload,
                            customToolbar: () => {
                                return (
                                    <CustomToolbar 
                                        openFilters={this.openFilters}
                                        openSearch={this.openSearch}
                                    />
                                );
                            },  
                            // search:window.outerWidth>767 ? true:false,
                            search:false,
                            searchText: searchValue,
                            // customSearch: (searchQuery, currentRow, columns) => {  
                            //     let isFound = false;
                            //     currentRow.forEach(col => { 
                            //         if (col.toString().toLowerCase().indexOf(searchQuery.toLowerCase()) >= 0) { 
                            //             isFound = true;
                            //         }
                            //     });
                            //     return isFound;
                            // }
                        }}   
                    />
                </Card> 
            </React.Fragment> 
         );
    }
}
 
const mapStateToProps = state => {    
   return({
       carsSale:state.carsSale.items,
       allowImport:state.brands.allowImport
    })
}

export default withRouter(connect(mapStateToProps, {suncCSV, fetchSaleCars, sendFileNav, importSalesCarsNisssan, getBrandsAllowImport,importSalesCars,fetchSaleCarsByStatus })(CarsSalesList));
// export default ViewUsers;