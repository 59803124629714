import { apiUrl} from "../helpers/apiUrl";
import { setMessage, removeMessage, setErrorMessage, removeErrorMessage } from "./msgActions"; 


export const fetchBrands = () => dispatch => { 
    return fetch(apiUrl+'brands', {
        method: 'GET', 
        headers:{ 
            'Content-Type': 'application/json', 
            'Accept': 'application/json',
            'Authorization':'Bearer '+("; "+document.cookie).split("; accessToken=").pop().split(";").shift()
        },
        body: JSON.stringify()
    }).then(result => {
        dispatch(setErrorMessage(result.status));
        dispatch(removeErrorMessage()); 
        return result.json()
    })
    .then(response => {    
        const {status, data} = response; 
        if (status === "success") {
            dispatch({
                type:'FETCH_BRANDS',
                payload:data
            })
        } 
        return response
    }); 
}
export const fetchBrandsList = () => dispatch => {  
    return fetch(apiUrl+'brands/autocomplete', {
        method: 'GET', 
        headers:{ 
            'Content-Type': 'application/json', 
            'Accept': 'application/json',
            'Authorization':'Bearer '+("; "+document.cookie).split("; accessToken=").pop().split(";").shift()
        },
        body: JSON.stringify()
    }).then(result => {
        dispatch(setErrorMessage(result.status));
        dispatch(removeErrorMessage()); 
        return result.json()
    })
    .then(response => {    
        const {status, data} = response; 
        if (status === "success") {

            dispatch({
                type:'FETCH_BRANDS_LIST',
                payload:data
            })
        } 
        return response
    }); 
}

export const fetchSingleBrand = (id) => dispatch => { 
    return fetch(apiUrl+'brands/'+id, {
        method: 'GET', 
        headers:{ 
            'Content-Type': 'application/json', 
            'Accept': 'application/json',
            'Authorization':'Bearer '+("; "+document.cookie).split("; accessToken=").pop().split(";").shift()
        },
        body: JSON.stringify()
    }).then(result => {
        dispatch(setErrorMessage(result.status));
        dispatch(removeErrorMessage()); 
        return result.json()
    })
    .then(response => {      
        const {status,data} = response;
        if (status === "success") {
            dispatch({
                type:'FETCH_SINGLE_BRAND',
                payload:data
            })
        } 
        return response
    }); 
}
 
export const createBrand = (brand) => dispatch => {  
    
    return fetch(apiUrl+'brands', { 
        method: 'POST', 
        headers:{ 
            'Content-Type': 'application/json', 
            'Accept': 'application/json',
            'Authorization':'Bearer '+("; "+document.cookie).split("; accessToken=").pop().split(";").shift()
        },
        body: JSON.stringify(brand)
     }).then(result => {
        dispatch(setErrorMessage(result.status));
        dispatch(removeErrorMessage()); 
        return result.json()
     })
    .then(response => {    
        const {status, data, message} = response; 
        if (status === "success") {
            dispatch(setMessage(message));
            dispatch(removeMessage());
            dispatch({
                type:'CREATE_BRAND',
                payload:data
            })
        } 
        return response
    }); 
 
}

export const editBrand = (brand) => dispatch => {   
    const {id}=brand;
    return fetch(apiUrl+'brands/'+id, {
        method: 'PUT', 
        headers:{ 
            'Content-Type': 'application/json', 
            'Accept': 'application/json',
            'Authorization':'Bearer '+("; "+document.cookie).split("; accessToken=").pop().split(";").shift()
        },
        body: JSON.stringify(brand)
    }).then(result => {
        dispatch(setErrorMessage(result.status));
        dispatch(removeErrorMessage()); 
        return result.json()
    })
    .then(response => {    
        const {status, data, message} = response; 
        if (status === "success") { 
            dispatch(setMessage(message));
            dispatch(removeMessage());
            dispatch({
                type:'EDIT_BRAND',
                payload:brand
            })
        } 
        return response
    });   
}

export const getBrandsAllowImport = (data) => dispatch => { 
    return fetch(apiUrl+'brands/allow-import', {
        method: 'GET', 
        headers:{ 
            'Content-Type': 'application/json', 
            'Accept': 'application/json',
            'Authorization':'Bearer '+("; "+document.cookie).split("; accessToken=").pop().split(";").shift()
        },
        body: JSON.stringify()
    }).then(result => {
        dispatch(setErrorMessage(result.status));
        dispatch(removeErrorMessage()); 
        return result.json()
    })
    .then(response => {      
        const {status,data} = response;
        if (status === "success") {
            dispatch({
                type:'BRANDS_ALLOW_IMPORT',
                payload:data
            })
        } 
        return response
    }); 
}