export const allFIeldsForSaleCars ={
    id:"",
    type:"",
    status:"",
    brand:"",
    model:"",
    inventoryNumber:"",
    vin:"",
    engine:"",
    engineType:"",
    externalColorDescription:"",
    commercialGrade:"",
    keyCode:"",
    kw:"",
    listPrice:"",
    fuelType:"",
    transmission:"", 
    modelId:"",
    brandId:"",
    dlvrd:"",
    ordRequest:"",
    mtyOne:"",
    mtyTwo:"",
    notDlvrd:"",
    equipmentCode:"",
    monthDelivery:"",
    order:"",
    externalColor:"",
    vehicleStayInStock:"",
    idEmotive:"",
    factoryGrade:"",
    comeInSofia:"",
    internalColor:"",
    location:"",
    body:"",
    bodyType:"",
    vehicle_optionalEquipment:"",
    originalStatus:"",
    mixedName:"",
    statusName:"",
    typeName:"",
    technical_salesCarId:"",
    drivingWheels:"",
    hp:"",
    displacement:"",
    cylinders:"",
    seats:"",
    lenght:"",
    width:"",
    height:"",
    liveWeight:"",
    wheelBase:"",
    maxPower:"",
    maxPowerRpm:"",
    maxTorgue:"",
    maxTorqueRpm:"",
    maxTorqueNm:"",
    acceleration:"",
    fuelConsumption:"",
    tankCapacity:"",
    emission:"",
    euStandard:"",
    luggageCapacity:"",
    numberValves:"",
    maxSpeed:"",
    customer_salesCarId:"",
    client:"",
    email:"",
    address:"",
    phone:"",
    populatedLocation:"",
    customer_note:"",
    logistic_typeName:"",
    logistic_salesCarId:"",
    priceType:"",
    contractNumber:"",
    invoiceNumberToClient:"",
    invoiceDateToClient:"",
    depositForCarOrder:"",
    dateOfOrderOfTheCar:"",
    numberOfOrderOfTheCar:"",
    deliveredToTheCustomer:"",
    regNumber:"",
    roarAssistantCard:"",
    insuranceCompany:"",
    customerPayment:"",
    dealer:"",
    invoiceMagEur:"",
    invoiceMagDate:"",
    invoiceMagNumber:"",
    daysAfterInvoice:"",
    deliveryPriceEur:"",
    vat:"",
    deliveryPriceWithVat:"",
    marginByListPriceEur:"",
    marginByRetailPriceEur:"",
    marginByRetailPriceBgn:"",
    marginAfterMaxDiscountBgn:"",
    marginAfterMaxDiscount:"",
    invoicePriceToDealerWithVatBgn:"",
    dealerCommissionWithVatBgn:"",
    dealerBonusWithVatBgn:"",
    dealerBonusEur:"",
    promotionBgn:"",
    paidAdvice:"",
    creditNoteMagDate:"",
    stayInStockSoldCars:"",
    mileage:"",
    logistic_type:"",
    administration_salesCarId:"",
    responsibleSalesman:"",
    allInclusiveCard:"",
    dateSosRequires:"",
    registrationTypeNote:"",
    salesPriceContractWithVat:"",
    maxDiscount:"",
    maxDiscountWithVat:"",
    maxPriceAfterDiscountWithVat:"",
    administration_note:"",
    pricePerPriceListWithVat:"",
    guarantee:"",
    editedAt:"",
    guaranteeType:"",
    price_salesCarId:"",
    priceB2c:"",
    bottomPrice:"",
    pricePartner:"",
    priceB2b:"",
    promotion:"",
    giftsForClient:"",
    discountBgn:"",
    discountWithVatBgn:"",
    personalBonusForTrader:"",
    price_optionalEquipment:"",
    standardEquipment:"",
}

export const filtersForSaleCarsMobile = { 
    brand:"",
    model:"",
    externalColor:"",
    fuelType:""
}


export const allFIeldsForSaleCarsForOffer = {
    id:"",
    type:"",
    status:"",
    brand:"",
    model:"",
    inventoryNumber:"",
    vin:"",
    engine:"",
    engineType:"",
    externalColorDescription:"",
    commercialGrade:"",
    // keyCode:"",
    kw:"",
    listPrice:"",
    fuelType:"",
    transmission:"",

    
    modelId:"",
    brandId:"",
    dlvrd:"",
    ordRequest:"",
    mtyOne:"",
    mtyTwo:"",
    notDlvrd:"",
    equipmentCode:"",
    monthDelivery:"",
    order:"",
    externalColor:"",
    vehicleStayInStock:"",
    idEmotive:"",
    factoryGrade:"",
    comeInSofia:"",
    internalColor:"",
    location:"",
    body:"",
    bodyType:"",
    // vehicle_optionalEquipment:"",
    originalStatus:"",
    mixedName:"",
    // statusName:"",
    // typeName:"",
    // technical_salesCarId:"",
    drivingWheels:"",
    hp:"",
    displacement:"",
    cylinders:"",
    seats:"",
    lenght:"",
    width:"",
    height:"",
    liveWeight:"",
    wheelBase:"",
    maxPower:"",
    maxPowerRpm:"",
    maxTorgue:"",
    maxTorqueRpm:"",
    maxTorqueNm:"",
    acceleration:"",
    fuelConsumption:"",
    tankCapacity:"",
    emission:"",
    euStandard:"",
    luggageCapacity:"",
    maxSpeed:"",
    // customer_salesCarId:"",
    // client:"",
    // email:"",
    // address:"",
    // phone:"",
    // populatedLocation:"",
    // customer_note:"",
    // logistic_typeName:"",
    // logistic_salesCarId:"",
    // priceType:"",
    // contractNumber:"",
    // invoiceNumberToClient:"",
    // invoiceDateToClient:"",
    // depositForCarOrder:"",
    // dateOfOrderOfTheCar:"",
    // numberOfOrderOfTheCar:"",
    // deliveredToTheCustomer:"",
    // regNumber:"",
    // roarAssistantCard:"",
    // insuranceCompany:"",
    // customerPayment:"",
    // dealer:"",
    // invoiceMagEur:"",
    // invoiceMagDate:"",
    // invoiceMagNumber:"",
    // daysAfterInvoice:"",
    // deliveryPriceEur:"",
    // vat:"",
    // deliveryPriceWithVat:"",
    // marginByListPriceEur:"",
    // marginByRetailPriceEur:"",
    // marginByRetailPriceBgn:"",
    // marginAfterMaxDiscountBgn:"",
    // marginAfterMaxDiscount:"",
    // invoicePriceToDealerWithVatBgn:"",
    // dealerCommissionWithVatBgn:"",
    // dealerBonusWithVatBgn:"",
    // dealerBonusEur:"",
    // promotionBgn:"",
    // paidAdvice:"",
    // creditNoteMagDate:"",
    // stayInStockSoldCars:"",
    // logistic_type:"",
    // administration_salesCarId:"",
    // responsibleSalesman:"",
    // allInclusiveCard:"",
    // dateSosRequires:"",
    // registrationTypeNote:"",
    // salesPriceContractWithVat:"",
    // maxDiscount:"",
    // maxDiscountWithVat:"",
    // maxPriceAfterDiscountWithVat:"",
    // administration_note:"",
    // pricePerPriceListWithVat:"",
    // guarantee:"",
    // editedAt:"",
    // guaranteeType:"",
    // price_salesCarId:"",
    priceB2c:"",
    bottomPrice:"",
    pricePartner:"",
    priceB2b:"",
    promotion:"",
    giftsForClient:"",
    discountBgn:"",
    discountWithVatBgn:"",
    personalBonusForTrader:"",
    // price_optionalEquipment:"",
    // standardEquipment:"",
}