import React, { Component } from 'react';
import {connect} from 'react-redux'
import {setMessage, removeMessage} from '../../_actions/msgActions'
import {withRouter} from 'react-router-dom';
import {deleteTestDrive} from '../../_actions/driveListActions';

class ConfirmDeleteBox extends Component {
    state = {}

    componentDidMount(){
 
    } 

    

    onClick = () =>{ 
        const {id} = this.props.data 
        
        this.props.deleteTestDrive(id).then(data=>{  
            
            if(data===204){
                this.props.setMessage('Успешно изтрит запис')
                this.props.removeMessage()
                this.props.history.push('/dashboard/drive-list')
            }
        })
    }
    closeBox =()=>{
        this.props.handleCloseBoxDelete();
    }
    render() { 
        const {showDeleteBox} = this.props.data
        
        return (  
            
            <div className={"alert-box-wrapper  active "+( showDeleteBox ? 'show':'') }> 
                <div className="alert-box pt"> 
                    <div className="close-box" onClick={this.closeBox}>x</div>
                    <h3>Сигурни ли сте че искате да изтриете ?</h3>
                    <br/>
                   <ul className="btn-wrapper">
                       <li>     
                           <button type="button" className="btn btn-danger full-width" onClick={this.onClick}>
                                Изтрий
                            </button>
                        </li>
                       <li>
                            <button type="button" className="btn btn-info full-width" onClick={this.closeBox}>
                                Не
                            </button>
                       </li>
                   </ul>
               
                   
                </div>
            </div>
        );
    }
}

 
 export default withRouter(connect(null, { deleteTestDrive, setMessage, removeMessage })(ConfirmDeleteBox)); 