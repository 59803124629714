import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { withRouter } from "react-router-dom";

import {connect} from 'react-redux'

import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import InputAdornment from '@material-ui/core/InputAdornment'; 
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl'; 
import FormHelperText from '@material-ui/core/FormHelperText';  
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import MomentUtils from "@date-io/moment";
import moment from "moment";
import {MuiPickersUtilsProvider, KeyboardDatePicker} from '@material-ui/pickers';

import {fetchContactAutoComplete} from '../../../_actions/contactsActions'
 


class ClientInfo extends Component {
    state = { 
        data:{
            id:'',
            egn:'',
            name:'',
            email:'',
            phone:'',
            title:'г-н',
            idCardNumber:'',
            idCardDate:null,
            idCardPublishedBy:'',
            drivingLicense:'',
            drivingLicenseDate:'',
            contactCompany:'',
            createdBy:'',
            fromClient:'',
            useContactCompany:0,
            contacts:[{'relatedContactId':'','relationType':'','relatedContact':''}]
        },
        drivingLicenseType:'',
        editMode:false, 
        clientName:'', 
        errors:[]
     }

     componentDidMount(){  
        this.getPassedData()
        if (this.props.errors) { 
            this.setState({errors:this.props.errors })   
        }
    } 
    
    componentWillUnmount(){
        if (this.state.data.name || this.state.data.phone ||  this.state.data.email) {
            this.props.handleData('contact', this.state.data)  
   
        }
    }

     componentDidUpdate(previousProps, previousState){  
        if(previousProps.offer !== this.props.offer){   
            this.getPassedData()
        }   
        if(previousProps.errors !== this.props.errors){   
            this.setState({errors:this.props.errors })   
        }   
    }
    
    getPassedData = ()=>{
        const {offer}=this.props;   
        if (offer.contact) {
            const {id,name,title,email,phone,egn,clientCompany,idCardNumber,idCardDate,idCardPublishedBy,drivingLicense,drivingLicenseType,drivingLicenseDate,createdBy,contacts, contactCompany, useContactCompany} = this.props.offer.contact
            
            let trader =''
            if (!this.state.editMode) {
                if (Boolean(createdBy)) {
                    trader=createdBy
                }else{
                    trader=localStorage.getItem('name')
                } 
            }
         
            if (contacts.length<1) {
                this.setState ({
                    data:{
                        ...this.state.data,
                        id,name,title,email,phone,egn,clientCompany,idCardNumber,idCardDate,idCardPublishedBy,drivingLicense,drivingLicenseType,contactCompany,useContactCompany,drivingLicenseDate,createdBy:trader,contacts:[{'relatedContactId':'','relationType':'','relatedContact':''}]
                    } 
                })    
            }else{
                this.setState ({
                    data:{
                        ...this.state.data,
                        id,name,email,title,phone,egn,clientCompany,idCardNumber,idCardDate,idCardPublishedBy,drivingLicense,drivingLicenseType,drivingLicenseDate,contactCompany,useContactCompany,createdBy:trader,contacts
                    } 
                })   
            }  
        } 
    }
     
    getClientAutocomplete=(name)=>{ 
        this.props.getClientAutocomplete(name, this.state.type)
    }
   
    switchStep =(stepNumber)=>{ 
       
        Promise.resolve(this.props.handleData('contact', this.state.data)).then(()=>{
            this.props.switchStep(stepNumber)   
        }) 
    }

 

    handleRemove = (i) => {
        const list = [...this.state.data.contacts];
        list.splice(i, 1);
        this.setState({
            data:{
                ...this.state.data,
                contacts:list
            } 
        })
    }

    onChangeDinamic = (e, index) => { 
        const { name, value } = e.target; 
        const list = [...this.state.data.contacts];
        list[index][name] = value; 
        this.setState({
            data:{
                ...this.state.data,
                contacts:list
            } 
        }) 
    };
    
    onChangeDinamicRelatedId = (index, event, value, reason) =>{     
        if(value){  
            const list = [...this.state.data.contacts];
            list[index].relatedContactId = value.id
            this.setState({
                data:{
                    ...this.state.data,
                    contacts:list
                } 
            })  
        } 
    }
   
    onChangeDinamicRelated =(index, event, value, reason) =>{    
        if (value.length>3) { 
            this.props.fetchContactAutoComplete(value);
        }

        if(event || value){ 
            const list = [...this.state.data.contacts];
            list[index].relatedContact = value; 
            this.setState({
                data:{
                    ...this.state.data,
                    contacts:list
                } 
            })  
        } 
    }
    
    handleAdd = ()=>{
        this.setState({ 
            data:{
                ...this.state.data,
                contacts:[
                    ...this.state.data.contacts, 
                    {'relatedContactId':'','relationType':'','relatedContact':''}
                ] 
            }
           
        })
    }

    onChange =(event)=>{
        const {name, value} = event.target;
        
        this.setState({ 
            data:{
                ...this.state.data,
                [name]:value  
            },
            errors:{
                ...this.state.errors,
                [name]:''
            }
        },()=>{this.props.handleData('contact', this.state.data)}) 
    }

  

    changeContactData = (event, value, reason) => {
        if (value) {   
            this.setState({
                data:{ 
                    ...value,
                    createdBy:localStorage.getItem('name'),
                    contacts:[
                        ...value.contacts, 
                        {'relatedContactId':'','relationType':'','relatedContact':''}
                    ],
                    
                }
            })     
        }
    }

    changeContactName = (event, value, reason) => { 
        if (value.length>3) { 
            this.props.fetchContactAutoComplete(value) 
        }  
    }

    onChangeDate = (input, date, value) => {   
        this.setState({
            data:{
                ...this.state.data, 
                [input]:value 
            }  
        })  
    }
    handleCheckbox = (event) =>{
        const{name, checked } = event.target;  
        this.setState({ 
            data:{
                ...this.state.data,
                [name]:checked?1:0 
            }
        }) 
    }
    render() {   
 
        const {id,egn, name, email,title, phone, createdBy,idCardNumber,idCardDate,idCardPublishedBy,drivingLicense,drivingLicenseDate,contactCompany, contacts, useContactCompany}= this.state.data; 
        const {errors}=this.state; 
        const {contactsAutoComplete}=this.props;  
        return ( 
            <>
            <Grid container spacing={3}> 
                <Grid item md={4} xs={12}>  
                    <Autocomplete 
                        options={contactsAutoComplete} 
                        // loading={loading}
                        onChange={this.changeContactData} 
                        onInputChange={this.changeContactName} 
                        // getOptionLabel={option => option.name}
                        getOptionLabel={option => { 
                            return (
                              option.name +'; егн: '+ option.egn +'; тел: '+  option.phone  
                            ) 
                        }} 
                        
                        renderInput={params => (
                            <TextField  {...params} 
                            className="full-width mb-20"
                            InputLabelProps={{shrink: true}} 
                            margin="normal"
                            variant="outlined"
                            label="Търси контакт:"  
                            />
                        )} 
                    />
                </Grid>
            </Grid>
            <Grid container spacing={3}> 
                <Grid item md={4} xs={12}>  
                    <TextField inputProps={{'className':'data-hj-allow'}} className="full-width mb-20" 
                        InputLabelProps={{shrink: true}} 
                        margin="normal" 
                        variant="outlined"  
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <i className="flaticon2-user"></i>  
                                </InputAdornment>
                            ),
                            type:"password",
                            autoComplete: 'new-password'
                        }}
                        label="ЕГН:"
                        name="egn" 
                        value={egn}
                        error ={errors.egn?true:false} 
                        helperText={errors.egn}
                        onChange={this.onChange}  
                    />   
                    <TextField inputProps={{'className':'data-hj-allow'}} className="full-width mb-20" 
                        InputLabelProps={{shrink: true}} 
                        margin="normal" 
                        variant="outlined"  
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <i className="flaticon2-user"></i>  
                                </InputAdornment>
                            ),
                        }}
                        required 
                        label="Име:"
                        name="name"
                        value={name}
                        error ={errors.name?true:false} 
                        helperText={errors.name}
                        onChange={this.onChange}  
                    />   
                    
                    <TextField inputProps={{'className':'data-hj-allow'}}  className="full-width mb-20" 
                        InputLabelProps={{shrink: true}} 
                        margin="normal" 
                        variant="outlined"  
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <i className="flaticon2-phone"></i>  
                                </InputAdornment>
                            ),
                        }}
                        required 
                        label="Телефон:"
                        name="phone"   
                        value={phone}
                        error ={errors.phone?true:false} 
                        helperText={errors.phone}
                        onChange={this.onChange} 
                    />  
                    <TextField inputProps={{'className':'data-hj-allow'}}  className="full-width mb-20" 
                        InputLabelProps={{shrink: true}} 
                        required 
                        margin="normal" 
                        variant="outlined"  
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <i className="flaticon2-envelope"></i>  
                                </InputAdornment>
                            ),
                        }}
                        label="E-mail:"
                        name="email"  
                        value={email}
                        error ={errors.email?true:false} 
                        helperText={errors.email}
                        onChange={this.onChange} 
                    />  
                </Grid> 
                <Grid item md={4} xs={12}>  
                    <TextField inputProps={{'className':'data-hj-allow'}}  className="full-width mb-20" 
                        InputLabelProps={{shrink: true}} 
                        margin="normal" 
                        variant="outlined"  
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <i className="flaticon2-browser"></i>  
                                </InputAdornment>
                            ),
                        }}
                        label="ЛК/номер:"
                        name="idCardNumber" 
                        value={idCardNumber}
                        error ={errors.idCardNumber?true:false} 
                        helperText={errors.idCardNumber}
                        onChange={this.onChange} 
                    />   
                    <TextField inputProps={{'className':'data-hj-allow'}}  className="full-width mb-20" 
                        InputLabelProps={{shrink: true}}
                        margin="normal" 
                        variant="outlined"  
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <i className="flaticon2-browser"></i>  
                                </InputAdornment>
                            ),
                        }}
                        label="Издадена от:"
                        name="idCardPublishedBy" 
                        value={idCardPublishedBy}
                        error ={errors.idCardPublishedBy?true:false}  
                        helperText={errors.idCardPublishedBy} 
                        onChange={this.onChange} 
                    />  
                    <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}   >
                        <KeyboardDatePicker inputProps={{'className':'data-hj-allow'}} 
                            autoOk={true}
                            showTodayButton={true} 
                            InputLabelProps={{shrink: true}} 
                            className="full-width date mb-20"  
                            inputVariant="outlined" 
                            margin="normal"
                            label="ЛК/дата на издаване:" 
                            format="DD.MM.YYYY"
                            value=""
                            inputValue={idCardDate} 
                            onChange={(e,d)=>this.onChangeDate('idCardDate', e ,d)}
                            rifmFormatter={this.dateFormatter} 
                            error ={errors.idCardDate?true:false}  
                            helperText={errors.idCardDate} 
                        />
                    </MuiPickersUtilsProvider>
                    <TextField inputProps={{'className':'data-hj-allow'}} className="full-width mb-20" 
                        InputLabelProps={{shrink: true}}
                        margin="normal" 
                        variant="outlined"  
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <i className="flaticon2-user"></i>  
                                </InputAdornment>
                            ),
                        }}
                        label="Търговец"
                        name="createdBy"
                        value={createdBy}
                        error={errors.createdBy?true:false} 
                        helperText={errors.createdBy} 
                        onChange={this.onChange}  
                    /> 
                </Grid>         
                <Grid item md={4} xs={12}>  
                    <TextField inputProps={{'className':'data-hj-allow'}}  className="full-width mb-20"
                        InputLabelProps={{shrink: true}}
                        margin="normal"
                        variant="outlined"
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <i className="flaticon2-browser"></i>
                                </InputAdornment>
                            ),
                        }}
                        label="Шофьорска книжка номер"
                        name="drivingLicense"
                        value={drivingLicense}
                        error ={errors.drivingLicense?true:false}
                        helperText={errors.drivingLicense}
                        onChange={this.onChange}
                    /> 
                    <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}   >
                        <KeyboardDatePicker inputProps={{'className':'data-hj-allow'}} 
                            autoOk={true}
                            showTodayButton={true} 
                            InputLabelProps={{shrink: true}} 
                            className="full-width date mb-20"  
                            inputVariant="outlined" 
                            margin="normal"
                            label="Книжка/дата на издаване:" 
                            format="DD.MM.YYYY"
                            value="" 
                            inputValue={drivingLicenseDate} 
                            onChange={(e,d)=>this.onChangeDate('drivingLicenseDate', e ,d)}
                            rifmFormatter={this.dateFormatter} 
                            error ={errors.drivingLicenseDate?true:false}  
                            helperText={errors.drivingLicenseDate} 
                        />
                    </MuiPickersUtilsProvider>
                    <FormControl variant="outlined" error={errors.title?true:false} margin="normal" className='mb-20 full-width select-styles'>
                        <InputLabel id="fuelType">
                            Oбръщение *
                        </InputLabel>
                        <Select  
                            MenuProps={{
                                anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "right"
                                },
                                transformOrigin: {
                                vertical: "top",
                                horizontal: "right"
                                },
                                getContentAnchorEl: null
                            }} 
                            labelId="title"
                            required={true}  
                            inputProps={{
                                name: 'title', 
                            }}  
                            value={title} 
                            onChange={this.onChange}
                            labelWidth={100}  >    
                            <MenuItem value='г-н'>г-н</MenuItem>  
                            <MenuItem value='г-жо'>г-жо</MenuItem>   
                        </Select>
                        {errors.title&&<FormHelperText>{errors.title}</FormHelperText>}
                    </FormControl>
                    <TextField 
                        inputProps={{'className':'data-hj-allow'}}            
                        className="full-width mb-20" 
                        InputLabelProps={{shrink: true}} 
                        margin="normal" 
                        variant="outlined"  
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <i className="fas fa-city"></i>  
                                </InputAdornment>
                            ),
                        }}
                        label="Представител на Компания:"
                        name="contactCompany" 
                        value={contactCompany}
                        error ={errors.contactCompany?true:false} 
                        helperText={errors.contactCompany}
                        onChange={this.onChange} 
                    /> 
                   <FormControlLabel control={
                    <Checkbox
                        checked={useContactCompany}
                        onChange={this.handleCheckbox}
                        name="useContactCompany"
                        color="primary"
                    />
                    }
                    label='Използвай "Представител на Компания" при издаване на Оферта.'
                    /> 
                </Grid> 
            </Grid> 
            <Grid container spacing={2}>   
                <Grid item md={4} xs={12} >  
                {contacts.length===0&&<button style={{'margin':'25px 0 30px'}} onClick={this.handleAdd} type="button" className='btn btn-primary full-width'>
                    Добави свързан контакт
                </button>} 
                </Grid> 
            </Grid> 
            {contacts.map((item, i) =>{
                return(  
                    <Grid container spacing={2} key={i}>   
                        <Grid item md={4} xs={12} >  
                            <Autocomplete 
                                options={contactsAutoComplete} 
                                inputValue={item.relatedContact} 
                                onChange={(event, value, reason)=>this.onChangeDinamicRelatedId(i,event, value, reason)} 
                                onInputChange={(event, value, reason)=>this.onChangeDinamicRelated(i,event, value, reason)}
                                getOptionLabel={option => option.name}
                                renderInput={params => (
                                    <TextField {...params}
                                    className="full-width mb-20"
                                    InputLabelProps={{shrink: true}} 
                                    margin="normal"
                                    variant="outlined"
                                    label="Свързан със:"
                                
                                    />
                                )} 
                            />  
                        </Grid> 
                        <Grid item md={4} xs={12} >  
                            <FormControl variant="outlined" error={errors.relationType?true:false} margin="normal" className='mb-20 full-width select-styles'>
                                <InputLabel id="relationType" shrink>
                                    Вид взаймоотношения: 
                                </InputLabel>
                                <Select  
                                    MenuProps={{
                                        anchorOrigin: {
                                        vertical: "bottom",
                                        horizontal: "right"
                                        },
                                        transformOrigin: {
                                        vertical: "top",
                                        horizontal: "right"
                                        },
                                        getContentAnchorEl: null
                                    }} 
                                    labelId="relationType"
                                    notched
                                    inputProps={{
                                        name: 'relationType', 
                                    }} 
                                    
                                    value={item.relationType} 
                                    onChange={e=>this.onChangeDinamic(e, i)}
                                    labelWidth={180}  >    
                                    <MenuItem value='1'>Съпруг/Съпруга</MenuItem>  
                                    <MenuItem value='2'>Брат/Сестра</MenuItem>  
                                    <MenuItem value='3'>Роднина</MenuItem>  
                                    <MenuItem value='4'>Колега</MenuItem>  
                                    <MenuItem value='5'>Приятел</MenuItem>  
                                </Select>
                            </FormControl>  
                        </Grid> 
                        <Grid item md={4} xs={12} >   
                                    <br/>
                            <div className="btn-box">
                                {/* {contacts.length !== 1 && */}
                                <div className='delete' onClick={()=>this.handleRemove(i)}>
                                    <i className="flaticon2-delete"></i>  
                                </div>
                                {/* } */}
                                {contacts.length - 1 === i && 
                                <div className='add' onClick={this.handleAdd}>
                                    +
                                </div>}
                            </div>
                        </Grid> 
                    </Grid> 
                )})}          
             <Grid container spacing={2} >   
                <Grid item md={4} xs={12} >     
                    <div className="btn btn-info full-width" onClick={()=>this.switchStep(2)}>
                        <i className="flaticon2-add-square"></i>Напред
                    </div> 
                </Grid> 
            </Grid> 
            </>
         );
    }
}

const mapStateToProps = (state, props) => {  
    return{  
        contactsAutoComplete:state.contacts.contactsAutoComplete,  
    } 
}

export default withRouter(connect(mapStateToProps, {fetchContactAutoComplete })(ClientInfo));   