import { apiUrl} from "../helpers/apiUrl";
import { setMessage, removeMessage, setErrorMessage, removeErrorMessage } from "./msgActions"; 

 
export const fetchSingleModel = (id) => dispatch => { 
    return fetch(apiUrl+'models/'+id, {
        method: 'GET', 
        headers:{ 
            'Content-Type': 'application/json', 
            'Accept': 'application/json',
            'Authorization':'Bearer '+("; "+document.cookie).split("; accessToken=").pop().split(";").shift()
        },
        body: JSON.stringify()
    }).then(result => {
        dispatch(setErrorMessage(result.status));
        dispatch(removeErrorMessage()); 
        return result.json()
    })
    .then(response => {      
        const {status,data} = response;
        if (status === "success") {
            dispatch({
                type:'FETCH_SINGLE_MODEL',
                payload:data
            })
        } 
        return response
    }); 
}
 
export const createModel = (model) => dispatch => {  
    
    return fetch(apiUrl+'models', { 
        method: 'POST', 
        headers:{ 
            'Content-Type': 'application/json', 
            'Accept': 'application/json',
            'Authorization':'Bearer '+("; "+document.cookie).split("; accessToken=").pop().split(";").shift()
        },
        body: JSON.stringify(model)
     }).then(result => {
        dispatch(setErrorMessage(result.status));
        dispatch(removeErrorMessage()); 
        return result.json()
     })
    .then(response => {    
        const {status, message} = response; 
        if (status === "success") {
            dispatch(setMessage(message));
            dispatch(removeMessage()); 
        } 
        return response
    }); 
 
}

export const editModel = (model) => dispatch => {   
    const {id}=model;
    return fetch(apiUrl+'models/'+id, {
        method: 'PUT', 
        headers:{ 
            'Content-Type': 'application/json', 
            'Accept': 'application/json',
            'Authorization':'Bearer '+("; "+document.cookie).split("; accessToken=").pop().split(";").shift()
        },
        body: JSON.stringify(model)
    }).then(result => {
        dispatch(setErrorMessage(result.status));
        dispatch(removeErrorMessage()); 
        return result.json()
    })
    .then(response => {    
        const {status, message} = response; 
        if (status === "success") { 
            dispatch(setMessage(message));
            dispatch(removeMessage()); 
        } 
        return response
    });   
}

export const setModelPhoto = (id,val) => dispatch => {    
    let formData = new FormData(); 
    formData.append('file', val );  

    return fetch(apiUrl+'images/model/'+id, {
        method: 'POST', 
        headers:{  
            'Authorization':'Bearer '+("; "+document.cookie).split("; accessToken=").pop().split(";").shift()
        },
        body: formData
    }).then(result => {
        dispatch(setErrorMessage(result.status));
        dispatch(removeErrorMessage()); 
        return result.json()
    })
    .then(response => {     
        return response
    });   
}
export const removeModelPhoto = (modelId, imageId) => dispatch => {    
 

    return fetch(apiUrl+'images/model/'+modelId+"/"+imageId, {
        method: 'DELETE', 
        headers:{  
            'Content-Type': 'application/json', 
            'Accept': 'application/json',
            'Authorization':'Bearer '+("; "+document.cookie).split("; accessToken=").pop().split(";").shift()
        },
        body: JSON.stringify()
    }).then(result => {
        dispatch(setErrorMessage(result.status));
        dispatch(removeErrorMessage()); 
        return result.json()
    })
    .then(response => {     
        return response
    });   
}