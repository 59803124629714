import React, { Component } from 'react';
import {connect} from 'react-redux'
import Grid from '@material-ui/core/Grid'; 
import TextField from '@material-ui/core/TextField'; 
 
import {salesCarsNames} from '../../../helpers/formsPermissions'
 
class Customer extends Component {
    
    state={
        data:this.props.data ? this.props.data :{}, 
    }  

    onChange = (event, index) =>{ 
        const {name,value} = event.target;  
        this.setState({
            data:{
                ...this.state.data,
                [name]:value
            }
        })   
    }
   
    componentDidUpdate(previousProps, previousState){ 
        if(previousProps.data !== this.props.data){
            this.setState({
                data:this.props.data
            })
        }
    } 

 

    render() {  
        const {data} = this.state; 
        const {formUserPermissions={},errors={}}=this.props; 
        const {customer={}} = errors
        return (  
            
            <>    
                <Grid container spacing={3}> 
                    {Object.keys(data).map((key, i) => {  
                        if (formUserPermissions['customer.'+key]) {
                            return(
                                <Grid item md={3} xs={12} key={i}>  
                                    <TextField inputProps={{'className':'data-hj-allow'}} className="full-width mb-20" 
                                        InputLabelProps={{shrink: true}} 
                                        disabled 
                                        margin="normal" 
                                        variant="outlined"   
                                        label={salesCarsNames['customer.'+key]}
                                        name={key}
                                        value={data[key]}
                                        error ={customer[key]?true:false} 
                                        helperText={customer[key]} 
                                        onChange={(e)=>this.onChange(e, i)}  
                                    />
                                </Grid>   
                            ) 
                        }
                    })} 
                </Grid> 
                {Object.keys(data).length<1&&<Grid container spacing={3}> 
                    <Grid item md={12} xs={12}> 
                    <br/><br/>
                        <h3 style={{'textAlign':'center'}}>Няма полета за показване</h3>
                    </Grid> 
                </Grid>}      
            </>
         );
    }
}

const mapStateToProps = (state, props) => {  
    return{ 
        formUserPermissions:state.roles.formUserPermissions,  
    } 
}
export default connect(mapStateToProps, { })(Customer);
 
 

 