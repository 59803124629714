import React, { Component } from 'react';

import { withRouter } from "react-router-dom";
import Grid from '@material-ui/core/Grid';

import {connect} from 'react-redux'

import MUIDataTable from "mui-datatables"; 
import Card from "@material-ui/core/Card";  

import {columns, options} from "../../../helpers/viewCompanies";   
import {storeColumnsFilters, getStoredColumnsFilters} from "../../../helpers/storeColumns";  

import {fetchCompanies} from '../../../_actions/companiesActions'

class ListCompanies extends Component { 
    state={
        permissions: JSON.parse(localStorage.getItem('permissions')),
        options:{},
        columns:[], 
        roleId: JSON.parse(localStorage.getItem('roleId')),
     }

    componentDidMount (){
        this.setState({
            options: storeColumnsFilters('companiesTable', options),
            columns: getStoredColumnsFilters('companiesTable', columns)
        }) 
        this.props.fetchCompanies() 
    } 

    onDownload = (buildHead, buildBody, columns, data) => {
        return "\uFEFF" + buildHead(columns) + buildBody(data); 
    } 

    onRowClick=(rowData , rowMeta, e) => {  
        const {permissions= {}} = this.state; 
        if (permissions.companiesUpdate) {
            this.props.history.push("/dashboard/companies/"+rowData[0]);
        }
    }

    render() { 
        const {companies} = this.props; 
        const {options, columns, roleId} = this.state;  

        const filterCompanies = companies.filter(item=> item.id!==6)
 
        return ( 
            <React.Fragment>  
                <Grid container className="mb-20">
                    <Grid container item xs={6} sm={6} alignItems="center"> 
                        {/* <Link className="btn btn-info" to="/dashboard/create-company"><i className="flaticon-add-circular-button"/>
                            Добави компания
                        </Link>   */}
                    </Grid>    
                </Grid>    
                <Card>  
                    <MUIDataTable
                        title={"Компании:"}
                        data={(roleId===1 || roleId===2)?companies: filterCompanies}
                        columns={columns} 
                        options={{...options, onRowClick: this.onRowClick, onDownload:this.onDownload}}  
                    /> 
                </Card> 
            </React.Fragment> 
         );
    }
}
const mapStateToProps = state => {   
    return({companies:state.companies.items})
}
 
 export default withRouter(connect(mapStateToProps, { fetchCompanies })(ListCompanies));
