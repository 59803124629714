import React from 'react'; 

export const options = {
    sortOrder: {
        name: 'id',
        direction: 'desc'
    },
    confirmFilters: true,  
    customFilterDialogFooter: (currentFilterList, applyNewFilters) => { 
        return (
            <div style={{ marginTop: '40px' }}>
                <button className="btn btn-info small" onClick={()=>applyNewFilters()}>Приложи филтрите</button>
            </div>
        );
    },
    print: false,
    download: true,
    downloadOptions: {
        filename: 'OFFERS.csv',
        separator: ',',
        filterOptions: {
          useDisplayedColumnsOnly: true,
        } 
    },
    viewColumns: true,
    filter:false, 
    selectableRows: "none",     
    setRowProps: (row) => { 
        if (row[6]==="Отхвърлена") {
            return {  
                style: {background: '#ffe1e1'}
            }
        }
    }, 
    textLabels: {
        body: {
            noMatch: "Няма намерени резултати",
            toolTip: "Сортирай",
            columnHeaderTooltip: column => `Сортирай по ${column.label}`
        },
        pagination: {
            next: "Следваща страница",
            previous: "Предишна страница",
            rowsPerPage: "Брой записи на страница:",
            displayRows: "от",
        },
        toolbar: {
            search: "Търси",
            downloadCsv: "Download CSV",
            print: "Принтирай",
            viewColumns: "Колони",
            filterTable: "Филтри",
        },
        filter: {
            all: "Всички",
            title: "Филтри",
            reset: "Откажи",
        },
        viewColumns: {
            title: "Покажи Колони",
            titleAria: "Покажи/Скрии Колони",
        },
        selectedRows: {
            text: "запис/и са селектирани за изтриване",
            delete: "Изтриване",
            deleteAria: "Изтриите избраните записи",
        },
    }
}; 

export const columns = [ 
    {
        label: "ID:",
        name: "id",
        options: {
            filter: false,
            sort: true, 
           }
    },
    {
        label: "Име на клиент:",
        name: "contact",
        options: {
            filter: false,
            sort: true,
           }
    },
    {
        label: "Марка:",
        name: "brand",
        options: {
            filter: false,
            sort: true,
           }
    },  
    {
        label: "Модел:",
        name: "model",
        options: {
            filter: false,
            sort: true,
           }
    },  
    {
        label: "Дата:",
        name: "createdDate",
        options: {
            filter: false,
            sort: true,
        }
    },  
    {
        label: "Цена след отстъпка:",
        name: "priceAfterDiscount",
        options: {
            filter: true,
            sort: true,
        }
    }, 
    {
        label: "Статус на офертата:",
        name: "resolutionsStatus",
        options: {
            filter: true,
            sort: true, 
            customBodyRender: (value, tableMeta, updateValue) => { 
            
                if (value===0) {
                    return 'Отхвърлена' 
                }
                else if (value===1) { 
                    return 'Одобрена' 
                }
                else if (value===2) { 
                    return 'Изчакваща' 
                }
            }
        }
    },
    {
        label: "Име на търговец:",
        name: "user",
        options: {
            filter: true,
            sort: true,
        }
    },
    {
        label: "Бележка на търговеца:",
        name: "note",
        options: {
            filter: false,
            sort: false,
        }
    }
];
export const adminColums = [ 
    {
        label: "Цена по ценова листа:",
        name: "listPrice",
        options: {
            filter: true,
            sort: true,
        }
    },
    {
        label: "Промоционална Цена:",
        name: "promotionPrice",
        options: {
            filter: true,
            sort: true,
        }
    },
    {
        label: "Доставна цена с ДДС:",
        name: "deliveryPriceWithVat",
        options: {
            filter: true,
            sort: true,
        }
    },
] 