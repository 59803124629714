const initialState = {
    items:[], 
    singleRole:{}
}

export default function (state = initialState, action){  
    const {type, payload} = action;  
    switch(type){
        case 'FETCH_ROLES':  
            return{
                ...state,
                items:payload 
            } 
        case 'CREATE_ROLE':
            return { 
                ...state,
                // items: [...state.items, payload]
            } 
         
        case 'FETCH_SINGLE_ROLE':  
        return{
            ...state,
            singleRole:payload 
        }  
        case 'FETCH_FORM_USER_PERMISSIONS':  
            const perms = payload.reduce(
                (obj, item) => Object.assign(obj, {[item.name]:{ value: true, id:item.id }}), 
            {}); 
            return{
                ...state,
                formUserPermissions:perms 
            } 
        default:
            return state;
    }
}