const initialState = {
    items:[],
    intact:[],
    intactforOffer:[],
    singleSaleCar:{},
    saleCarsAutoComplete:[],
    saleCarsForOffer:[],
    formUserPermissions:{},
    singleSaleCarDocuments:[]
}

export default function (state = initialState, action){
    const {type, payload} = action;
    switch(type){
        case 'FETCH_SALE_CARS': 
            return{
                ...state,
                items:payload
            }
            
        case 'FETCH_SALE_CARS_ORIGINAL': 
            return{
                ...state,
                intact:payload
            }
        case 'FETCH_SALE_CARS_FOR_OFFER':
            return{
                ...state,
                saleCarsForOffer:payload
            }
        case 'FETCH_SALE_CARS_FOR_OFFER_ORIGINAL':
            return{
                ...state,
                intactforOffer:payload
            }
        case 'FETCH_SINGLE_SALE_CARS':
            return{
                ...state,
                singleSaleCar:payload
            }
        case 'FETCH_SINGLE_SALE_CARS_DOCUMENTS':
            return{
                ...state,
                singleSaleCarDocuments:payload
            }
        case 'EDIT_SINGLE_SALE_CARS':
            const{obj, data}= payload
            if (obj==='none') {
                return{
                    ...state,
                    singleSaleCar:{
                        ...state.singleSaleCar,
                        ...data,
                    }
                }
            }else{
                return{
                    ...state,
                    singleSaleCar:{
                        ...state.singleSaleCar,
                        [obj]:{
                            ...state.singleSaleCar[obj],
                            ...data
                        }
                    }
                }
            }
        case 'FETCH_SALE_CARS_AUTOCOMPLETE':
            return{
                ...state,
                saleCarsAutoComplete:payload
            } 
        default:
            return state;
    }
}
