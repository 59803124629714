import { apiUrl} from "../helpers/apiUrl";
import { setErrorMessage, removeErrorMessage } from "./msgActions"; 


export const fetchHistory = () => dispatch => { 
   
    return fetch(apiUrl+'log-activity', {
        method: 'GET', 
        headers:{ 
            'Content-Type': 'application/json', 
            'Authorization':'Bearer '+("; "+document.cookie).split("; accessToken=").pop().split(";").shift()
        },
        body: JSON.stringify()
    }).then(result => {
        dispatch(setErrorMessage(result.status));
        dispatch(removeErrorMessage()); 
        return result.json()
    })
    .then(response => {    
        
        const {status,data} = response;
        if (status === "success") {
            dispatch({
                type:'FETCH_HISTORY',
                payload:data
            })
        } 
        return response
    });
}

export const fetchHistorySingle = (id) => dispatch => { 
   
    return fetch(apiUrl+'log-activity/'+id, {
        method: 'GET', 
        headers:{ 
            'Content-Type': 'application/json', 
            'Authorization':'Bearer '+("; "+document.cookie).split("; accessToken=").pop().split(";").shift()
        },
        body: JSON.stringify()
    }).then(result => {
        dispatch(setErrorMessage(result.status));
        dispatch(removeErrorMessage()); 
        return result.json()
    })
    .then(response => {     
        const {status,data} = response;
        if (status === "success") {
            dispatch({
                type:'FETCH_HISTORY_SINGLE',
                payload:data
            })
        } 
        return response
    });
}
