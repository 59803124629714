const initialState = {
    msgText:'', 
}

export default function (state = initialState, action){  
    const {type, payload} = action;  
    switch(type){ 
        case 'SET_MSG':  
            return{   
                ...state,
                msgText:payload
            }  
        case 'REMOVE_MSG':  
            return{   
                ...state,
                msgText:payload
            }  
        case 'SET_ERROR_MSG':   
            return{   
                ...state,
                msgText:payload
            }  
        case 'REMOVE_ERROR_MSG':  
            return{   
                ...state,
                msgText:payload
            }  
        default:
            return state;
    }
}