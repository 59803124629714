import React from 'react';
export const fieldList = { 
    engine:"Двигател",
    engine_type:"Тип двигател",
    max_power:"Максимална мощност kW (к.с.) об / мин",
    max_torque:"Максимален въртящ момент, Nm при об / мин",
    cylinders:"Брой и подреждане на цилиндрите",
    eco_class:"Екологичен клас",
    transmission:"Скоростна кутия",
    front_suspension:"Предно окачване",
    rear_suspension:"Задно окачване",
    steering_system:"Кормилна уредба",
    braking_system:"спирачна система",
    handbrake_system:"Ръчна спирачна система",
    front_wheel_brakes:"Спирачки на предните колела",
    passengers:"Брой пътници",
    cargo_doors:"Врати на товарното отделение",
    length:"Дължина, мм",
    width:"Ширина, мм",
    height:"Височина, мм",
    wheelbase:"Междуосие, мм",
    loading_threshold:"Праг на товарене, мм",
    cargo_dimension:"Размери на товарното отделение дължина/ширина/височина, мм",
    cargo_volume:"Обем, м3",
    gross_weight:"Бруто тегло, кг",
    drive:"Задвижване",
    turning_radius:"Радиус на завиване",
    max_inclination:"Макс. наклон при шофиране, %",
    clearance:"Пътен просвет (под задната ос при бруто тегло на автомобила), мм",
    min_turning_radius:"Мин. радиус на завиване на външната следа на предните колела",
    max_speed:"Максимална скорост, км/ч'",
    consumption_sixty:"Разход на гориво с постоянна скорост от 60 км/ч",
    consumption_eighty:"Разход на гориво с постоянна скорост от 80 км/ч'",
    tank_volume:"Обем на резервоара, л.",
    tires:"Гуми",
    wheels :'Джанти'
}

let state = {}
for (const key in fieldList) {
    state[key]='' 
}

export const fieldListState = state
 
export const options = {
    sortOrder: {
        name: 'id',
        direction: 'desc'
    },
    confirmFilters: true,  
    customFilterDialogFooter: (currentFilterList, applyNewFilters) => { 
        return (
            <div style={{ marginTop: '40px' }}>
                <button className="btn btn-info small" onClick={()=>applyNewFilters()}>Приложи филтрите</button>
            </div>
        );
    },
    print: false,
    download: true,
    downloadOptions: {
        filename: 'OFFERS.csv',
        separator: ',',
        filterOptions: {
          useDisplayedColumnsOnly: true,
        } 
    },
    viewColumns: true,
    filter:false, 
    selectableRows: "none",     
    setRowProps: (row) => { 
        if (row[6]==="Отхвърлена") {
            return {  
                style: {background: '#ffe1e1'}
            }
        }
    }, 
    textLabels: {
        body: {
            noMatch: "Няма намерени резултати",
            toolTip: "Сортирай",
            columnHeaderTooltip: column => `Сортирай по ${column.label}`
        },
        pagination: {
            next: "Следваща страница",
            previous: "Предишна страница",
            rowsPerPage: "Брой записи на страница:",
            displayRows: "от",
        },
        toolbar: {
            search: "Търси",
            downloadCsv: "Download CSV",
            print: "Принтирай",
            viewColumns: "Колони",
            filterTable: "Филтри",
        },
        filter: {
            all: "Всички",
            title: "Филтри",
            reset: "Откажи",
        },
        viewColumns: {
            title: "Покажи Колони",
            titleAria: "Покажи/Скрии Колони",
        },
        selectedRows: {
            text: "запис/и са селектирани за изтриване",
            delete: "Изтриване",
            deleteAria: "Изтриите избраните записи",
        },
    }
}; 

export const columns = [ 
    {
        label: "ID:",
        name: "id",
        options: {
            filter: false,
            sort: true, 
           }
    },
    
    {
        label: "Марка:",
        name: "brandName",
        options: {
            filter: false,
            sort: true,
           }
    },   
    {
        label: "База / factory_grade:",
        name: "factory_grade",
        options: {
            filter: false,
            sort: true,
        }
    },  
 
];
 