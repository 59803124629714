import React, { Component } from 'react';
import {connect} from 'react-redux'
import Grid from '@material-ui/core/Grid'; 
import TextField from '@material-ui/core/TextField'; 

import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from '@material-ui/core/InputLabel';
 
import MomentUtils from "@date-io/moment";
import moment from "moment";
import {MuiPickersUtilsProvider, KeyboardDatePicker} from '@material-ui/pickers';

import {updateSingleSaleCarsState} from '../../../_actions/carsSaleActions'
import {salesCarsNames, logisticPosition} from '../../../helpers/formsPermissions'
 
class Logistic extends Component {
    
    state={
        data:this.props.data ? this.props.data :{}, 
    }  

    onChange = (event, index) =>{ 
        const {name,value} = event.target;  
        this.setState({
            data:{
                ...this.state.data,
                [name]:value
            }
        })   
    }
   
    componentWillUnmount(){
        this.props.updateSingleSaleCarsState('logistic', this.state.data)
    }

    componentDidUpdate(previousProps, previousState){ 
        if(previousProps.data !== this.props.data){
            this.setState({
                data:this.props.data
            })
        }

        if(previousProps.pushToState !== this.props.pushToState){
            if (this.props.pushToState) { 
                this.props.updateSingleSaleCarsState('logistic', this.state.data)
            } 
        }
    } 

    onChangeDate = (input, date, value) => {   
        this.setState({
            data:{
                ...this.state.data, 
                [input]:value 
            }  
        })  
    }
   

    render() {  
        const {data} = this.state; 
        const {formUserPermissions={},errors={}}=this.props; 
        const {logistic={}} = errors
      
        return (  
            
            <>    
                <Grid container spacing={3}> 
                    {Object.keys(data).map((key, i) => {  
                        if (formUserPermissions['logistic.'+key]) {
                            if (key==='insuranceCompany' || key==='priceType') { 
                                let disabledStatus = false;
                                let labelwidth=0;
                                let menuItems = []

                                if (key==='insuranceCompany') {
                                    labelwidth=200
                                    menuItems = [
                                        {name:'ЗАД "Алианц България" АД', value:'ЗАД "Алианц България" АД'},
                                        {name:'ЗАД "Армеец" АД', value:'ЗАД "Армеец" АД'},
                                        {name:'ЗАД “Асет Инс” АД', value:'ЗАД “Асет Инс” АД'}
                                    ]
                                }
                                if (key==='priceType') {
                                    disabledStatus=true
                                    labelwidth=300
                                    menuItems = [
                                        {name:'Банка', value:'1'},
                                        {name:'Лизинг', value:'2'}, 
                                    ]
                                } 
                                return( 
                                    <Grid item md={3} xs={12} key={i} style={{'order':logisticPosition['logistic.'+key]}}>  
                                         <FormControl variant="outlined" error={logistic[key]?true:false} margin="normal" className='mb-20 full-width select-styles'>
                                            <InputLabel id={key}>
                                                {salesCarsNames['logistic.'+key]}
                                            </InputLabel>
                                            <Select  
                                                disabled = {disabledStatus}
                                                MenuProps={{anchorOrigin: {vertical: "bottom",horizontal:"right"},transformOrigin: { vertical: "top",horizontal: "right"},getContentAnchorEl: null}} 
                                                labelId={key}
                                                required   
                                                inputProps={{ name: key}}  
                                                value={data[key]} 
                                                onChange={(e)=>this.onChange(e, i)}  
                                                labelWidth={labelwidth}  
                                                >
                                                {menuItems.map(item=><MenuItem key={item.value} value={item.value}>{item.name}</MenuItem>)}
                                            
                                            </Select>
                                            {logistic[key]&&<FormHelperText>{logistic[key]}</FormHelperText>}
                                        </FormControl>   
                                    </Grid>    
                                )  
                            }else if(key==='invoiceDateToClient'||key==='dateOfOrderOfTheCar'||key==='creditNoteMagDate'){    
                                return(
                                    <Grid item md={3} xs={12} key={i} style={{'order':logisticPosition['logistic.'+key]}}>  
                                        <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}   >
                                            <KeyboardDatePicker inputProps={{'className':'data-hj-allow'}} 
                                                autoOk={true}
                                                showTodayButton={true} 
                                                InputLabelProps={{shrink: true}} 
                                                className="full-width date mb-20"  
                                                inputVariant="outlined" 
                                                margin="normal"
                                                label={salesCarsNames['logistic.'+key]} 
                                                format="DD.MM.YYYY" 
                                                value=''
                                                inputValue={data[key]} 
                                                onChange={(e,d)=>this.onChangeDate(key, e ,d)}
                                                rifmFormatter={this.dateFormatter} 
                                                error ={logistic[key]?true:false}  
                                                helperText={logistic[key]} 
                                            />
                                        </MuiPickersUtilsProvider>
                                    </Grid> 
                                )
                            }else{
                                
                                return(<>
                            
                                    <Grid item md={3} xs={12} key={i} style={{'order':logisticPosition['logistic.'+key]}}>  
                                        <TextField inputProps={{'className':'data-hj-allow'}} className="full-width mb-20" 
                                            InputLabelProps={{shrink: true}}  
                                            margin="normal" 
                                            variant="outlined"   
                                            label={salesCarsNames['logistic.'+key]}
                                            name={key}
                                            value={data[key]}
                                            error ={logistic[key]?true:false} 
                                            helperText={logistic[key]} 
                                            onChange={(e)=>this.onChange(e, i)}  
                                        />
                                    </Grid>   
                                    {logisticPosition['logistic.'+key]===14 ?<Grid style={{'order':15}} item md={3} xs={12} key={i}></Grid> :''}
                                </>) 
                            }
                           
                        } 
                    })} 
                </Grid>  
            </>
         );
    }
}

const mapStateToProps = (state, props) => {  
    return{ 
        formUserPermissions:state.roles.formUserPermissions,  
    } 
}
export default connect(mapStateToProps, { updateSingleSaleCarsState})(Logistic);
 
 

 