import React,{Component} from 'react'; 
import Grid from '@material-ui/core/Grid'; 

import {Link} from 'react-router-dom';
import {connect} from 'react-redux'
import {logout} from '../../_actions/authActions' 
import {expensesUrl, testDriveUrl, aftersalesUrl} from '../../helpers/apiUrl'

class TopBar extends Component {
  
    onClick = () =>{
        this.props.logout();
    }
    render() {  
        return (  
            <React.Fragment>
                <Grid container direction="row" justify="space-between" alignItems="center" className="TopBar">  
                    <Grid item md={8} xs={12} className='top-nav-wrapper'>
                        <a className="btn top-button mr-10" href={expensesUrl}><i className="fas fa-dollar-sign"></i> Финансов модул</a>
                        <a className="btn top-button active mr-10" href={testDriveUrl}><i className="fas fa-car"></i> Продажби(CRM)</a>
                        <a className="btn top-button" href={aftersalesUrl}><i className="fas fa-chart-line"></i> AfterSales</a>
                    </Grid>
                    <Grid container item md={4} xs={12} justify="flex-end">
                        <div style={{'display': 'flex','alignItems': 'center','marginRight': '10px'}}>Здравейте,  {localStorage.getItem('name')} !</div>
                       
                        <button className="btn-label-brand" onClick = {this.onClick}> 
                            <span>Изход</span>
                            <i className='flaticon-logout'></i>
                        </button>
                    </Grid>
                </Grid>
            </React.Fragment>
        ); 
    }
}
export default connect(null, {logout})(TopBar); 