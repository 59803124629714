import { apiUrl } from "../helpers/apiUrl";
import { setMessage, removeMessage, setErrorMessage, removeErrorMessage } from "./msgActions"; 

 
export const fetchDriveList = () => dispatch => { 
   
    return fetch(apiUrl+'test-drives', {
        method: 'GET', 
        headers:{ 
            'Content-Type': 'application/json', 
            'Authorization':'Bearer '+("; "+document.cookie).split("; accessToken=").pop().split(";").shift()
        },
        body: JSON.stringify()
    }).then(result => {
        dispatch(setErrorMessage(result.status));
        dispatch(removeErrorMessage()); 
        return result.json()
    })
    .then(response => {    
        const {status,data} = response;
        if (status === "success") {
            dispatch({
                type:'FETCH_DRIVELIST',
                payload:data
            })
        } 
        return response
    }); 
}

export const fetchSingleTestDrive = (id) => dispatch => { 
    return fetch(apiUrl+'test-drives/'+id, {
        method: 'GET', 
        headers:{ 
            'Content-Type': 'application/json', 
            'Accept': 'application/json',
            'Authorization':'Bearer '+("; "+document.cookie).split("; accessToken=").pop().split(";").shift()
        },
        body: JSON.stringify()
    }).then(result => {
        dispatch(setErrorMessage(result.status));
        dispatch(removeErrorMessage()); 
     
        return result.json()
    })
    .then(response => {     
        const {status,data} = response;
        if (status === "success") {
            dispatch({
                type:'FETCH_SINGLE_DRIVELIST',
                payload:data
            })
        }   
        return response
    }); 
}

export const suncFixi = () => dispatch => { 
    return fetch(apiUrl+'fixi/sync/cars', {
        method: 'GET', 
        headers:{ 
            'Content-Type': 'application/json', 
            'Authorization':'Bearer '+("; "+document.cookie).split("; accessToken=").pop().split(";").shift()
        },
        body: JSON.stringify()
    }).then(result => {
        dispatch(setErrorMessage(result.status));
        dispatch(removeErrorMessage()); 
        return result.json()
    })
     .then(response => {   
        return response
    }); 
}

 
export const createTestDrive = (testDrive) => dispatch => { 
    return fetch(apiUrl+'test-drives', {
        method: 'POST', 
        headers:{ 
            'Content-Type': 'application/json', 
            'Authorization':'Bearer '+("; "+document.cookie).split("; accessToken=").pop().split(";").shift()
        },
        body: JSON.stringify(testDrive)
    }).then(result => {
        dispatch(setErrorMessage(result.status));
        dispatch(removeErrorMessage()); 
        return result.json()
    })
    .then(response => {    
        const {status,data,message} = response;
        if (status === "success") {
            dispatch(setMessage(message));
            dispatch(removeMessage());
            dispatch({
                type:'CREATE_DRIVELIST',
                payload:testDrive
            })  
            if (testDrive.publishingType!==5 && testDrive.publishingType!==4 && testDrive.publishingType!==3) { 
                fetch(apiUrl+'test-drives/file/'+data.url, {
                    method: 'GET', 
                    headers:{ 
                        'Content-Type': 'application/json',
                        'Accept': 'application/json',
                        'Authorization':'Bearer '+("; "+document.cookie).split("; accessToken=").pop().split(";").shift()
                    },
                    body: JSON.stringify() 
                })
                .then(resp => resp.blob())
                .then(blob => { 
                    if (window.navigator && window.navigator.msSaveOrOpenBlob) { // for IE
                        window.navigator.msSaveOrOpenBlob(blob, 'бланка.docx');
                    } else { 
                        const url = window.URL.createObjectURL(blob);
                        const a = document.createElement('a');
                        a.style.display = 'none';
                        a.href = url;
                        a.download = 'бланка.docx';
                        document.body.appendChild(a);
                        a.click();
                        window.URL.revokeObjectURL(url); 
                    }
                }) 
            } 
        } 
        return response 
    })
 
 
}
export const editTestDrive = (testDrive) => dispatch => {  
    const {id}=testDrive;
    return fetch(apiUrl+'test-drives/'+id, {
        method: 'PUT', 
        headers:{ 
            'Content-Type': 'application/json', 
            'Accept': 'application/json',
            'Authorization':'Bearer '+("; "+document.cookie).split("; accessToken=").pop().split(";").shift()
        },
        body: JSON.stringify(testDrive)
    }).then(result => {
        dispatch(setErrorMessage(result.status));
        dispatch(removeErrorMessage()); 
        return result.json()
    })
    .then(response => {    
        const {status,message} = response;
        if (status === "success") {
            dispatch(setMessage(message));
            dispatch(removeMessage());
            dispatch({
                type:'EDIT_DRIVELIST',
                payload:testDrive
            })
        } 
        return response
    });   
}
 
export const returnCar = id => dispatch => {   
    return fetch(apiUrl+'test-drives/return/'+id, {
        method: 'GET', 
        headers:{ 
            'Content-Type': 'application/json', 
            'Accept': 'application/json',
            'Authorization':'Bearer '+("; "+document.cookie).split("; accessToken=").pop().split(";").shift()
        },
        body: JSON.stringify()
    }).then(result => {
        dispatch(setErrorMessage(result.status));
        dispatch(removeErrorMessage()); 
        return result.json()
    })
    .then(response => {    
        const {status,data} = response; 
        if (status === "success") { 
            dispatch({
                type:'RETURN_TESTDRIVE',
                payload:data
            })
        } 
        return response
    });   
}

export const confirmReturnCar = id => dispatch => {   
    return fetch(apiUrl+'test-drives/return/'+id, {
        method: 'PUT', 
        headers:{ 
            'Content-Type': 'application/json', 
            'Accept': 'application/json',
            'Authorization':'Bearer '+("; "+document.cookie).split("; accessToken=").pop().split(";").shift()
        },
        body: JSON.stringify()
    }).then(result => {
        dispatch(setErrorMessage(result.status));
        dispatch(removeErrorMessage()); 
        return result.json()
    })
    .then(response => {     
        const {status,data} = response; 
        if (status === "success") { 
            return data
        }  
    });   
}

export const deleteTestDrive = id => dispatch => {   
     return fetch(apiUrl+'test-drives/'+id, {
        method: 'DELETE', 
        headers:{ 
            'Content-Type': 'application/json', 
            'Accept': 'application/json',
            'Authorization':'Bearer '+("; "+document.cookie).split("; accessToken=").pop().split(";").shift()
        },
        body: JSON.stringify()
     })
     .then(result =>{
        return result.status
     }) 
}

export const getBookedDates = id => dispatch => {   
    return fetch(apiUrl+'cars/test-drives/'+id, {
        method: 'GET', 
        headers:{ 
            'Content-Type': 'application/json', 
            'Accept': 'application/json',
            'Authorization':'Bearer '+("; "+document.cookie).split("; accessToken=").pop().split(";").shift()
        },
        body: JSON.stringify()
    }).then(result => {
        dispatch(setErrorMessage(result.status));
        dispatch(removeErrorMessage()); 
        return result.json()
    })
    .then(response => {     
        const {status,data} = response;   
        if (status === "success") {
            dispatch({
                type:'RETURN_TESTDRIVE_DATES',
                payload:data
            })
        }else{
            dispatch({
                type:'RETURN_TESTDRIVE_DATES',
                payload:''
            })
        }
    });   
}

export const printDrive = id => dispatch => {   
    return fetch(apiUrl+'test-drives/download/'+id, {
        method: 'GET', 
        headers:{ 
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization':'Bearer '+("; "+document.cookie).split("; accessToken=").pop().split(";").shift()
        },
        body: JSON.stringify()
    }).then(result => {
        dispatch(setErrorMessage(result.status));
        dispatch(removeErrorMessage()); 
        return result.json()
    })
    .then(response => {     
        const {status,data} = response; 
        if (status === "success") { 

            return fetch(apiUrl+'test-drives/file/'+data.url, {
                method: 'GET', 
                headers:{ 
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization':'Bearer '+("; "+document.cookie).split("; accessToken=").pop().split(";").shift()
                },
                body: JSON.stringify()
             })
        }  
    })
    .then(resp => resp.blob())
    .then(blob => { 
        if (window.navigator && window.navigator.msSaveOrOpenBlob) { // for IE
            window.navigator.msSaveOrOpenBlob(blob, 'бланка.docx');
        } else { 
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.style.display = 'none';
            a.href = url;
            a.download = 'бланка.docx';
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url); 
        }
    })
}

