import React, { Component } from 'react';
import {Link} from 'react-router-dom';
import {connect} from 'react-redux'

import Grid from '@material-ui/core/Grid';

import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import Card from "@material-ui/core/Card";
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';

import InputLabel from '@material-ui/core/InputLabel'; 
import FormControl from '@material-ui/core/FormControl';

import Chip from '@material-ui/core/Chip';

import {fetchSingleCompany, createCompany, editCompany} from '../../../_actions/companiesActions'
import {fetchBrandsList} from '../../../_actions/brandsActions'

class EditCompany extends Component {
    state = {
        data:{
            id:'',
            name:'',
            eik:'',
            mol:'',
            country:'',
            city:'', 
            phone:'',
            address:'',
            vatNumber:'',
            webPage:"",
            iban:'',
            base64:'',
            image:'',
            banks:[],
            agentName:'',
            notaryName:'',
            districtNotary:'',
            attorneyNumber:'',
            notaryRegNumber:'', 
            notaryDate:'',
            brands:[],
            hasNotary:false
        },
        editMode:false,
        errors:[],
        brandsList:[],
        roleId: JSON.parse(localStorage.getItem('roleId')), 
      }

    componentDidMount(){
        this.props.fetchBrandsList()

        if (this.props.match.params._id) {
            this.props.fetchSingleCompany(this.props.match.params._id)
            this.setState({editMode:true})
        }
    }

    componentDidUpdate(previousProps, previousState){
        if(previousProps.company !== this.props.company){
            const {id, name,eik,mol, image, country,city, phone,address,vatNumber,webPage,banks,notaryName,districtNotary,attorneyNumber,notaryRegNumber,notaryDate,brands,hasNotary, agentName} = this.props.company
           
                this.setState ({
                    data:{
                        ...this.state.data,
                        id, name,eik,mol,image, country,city, phone,address,vatNumber,webPage,banks,notaryName,districtNotary,attorneyNumber,notaryRegNumber,notaryDate,brands,hasNotary, agentName
                    }
                })
        }
        if(previousProps.brandsList !== this.props.brandsList){
            const {brandsList} = this.props
            this.setState ({
                brandsList
            })
        }
    }

    onChange = (event) => {
        const {name,value} = event.target;
        this.setState({
            data:{
                ...this.state.data,
                [name]:value
            }
        })
    }

    setAttorney = () => {
        this.setState({
            data:{
                ...this.state.data,
                hasNotary:!this.state.data.hasNotary
            }
        })
    }

    handleChangeBrands = (event)=>{
        this.setState({
            data:{
                ...this.state.data,
               brands:event.target.value
            }
        })
    }

    handleAddBank = ()=>{
        this.setState({
            data:{
                ...this.state.data,
                banks:[
                    ...this.state.data.banks,
                    {bank:"",bic:"",iban:"", currency:"BGN"},
                ] 
            } 
        }) 
    }

    handleRemoveBank = (i) => {
        const list = [...this.state.data.banks];
        list.splice(i, 1);
        this.setState({
            data:{
                ...this.state.data,
                banks:list 
            } 
        })
    }

    onChangeDinamicBank = (e, index) => {
        const { name, value } = e.target;
        const list = [...this.state.data.banks];
        list[index][name] = value; 
        this.setState({
            data:{
                ...this.state.data,
                banks:list 
            } 
        })
    };


    handlePhotoChange = (event) => { 
        const {name}= event.target;
        let reader = new FileReader();
        let file = event.target.files[0]; 
       
        reader.readAsDataURL( file);
        reader.onload = () => { 
            this.setState({
                data:{
                    ...this.state.data,
                    [name]: reader.result
                }
            })   
        };
    }

    removePhoto = (name)=>{
        this.setState({
            data:{
                ...this.state.data,
                [name]: ''
            }
        })  
    }

    onSubmit = (event) =>{
        event.preventDefault();
        const {editMode} = this.state;
        if (editMode) {
            this.props.editCompany(this.state.data).then(data => {
                if(data.status==="success"){
                    this.setState({errors:[]})
                    this.props.history.push('/dashboard/companies')
                }
                else if (data.errors) {
                    this.setState({errors:data.errors})
                }
            })
        }else{
            this.props.createCompany(this.state.data).then(data => {
                if(data.status==="success"){
                    this.setState({
                        data:{
                            ...this.state.data,
                            id:'',image:'',base64:'', name:'', eik:'', mol:'', country:'', city:'', phone:'', address:'',vatNumber:'',webPage:'',iban:'',bank:'',bic:'',bicEur:'',ibanEur:'',notaryName:'',districtNotary:'',attorneyNumber:'',notaryRegNumber:'', notaryDate:'',brands:[],agentName:'',hasNotary:false
                        },
                        errors:[]
                    })
                }
                else if (data.errors) {
                    this.setState({errors:data.errors})
                }
            })
        }
    }

    render() {
        const {errors, editMode, brandsList, roleId} = this.state
        const {image, base64, banks, name, eik, mol, country, city, phone, address, vatNumber, webPage,notaryName, districtNotary, attorneyNumber, notaryRegNumber, notaryDate, hasNotary, brands, agentName} = this.state.data
    
 
        const pageTitle = editMode ? 'Редактиране на компания:' : 'Създаване на компания:';

        return (
        <>
        <Card >
            <Grid container className="section-title-wrapper padding">
                <Grid container item xs={8} alignItems="center">
                    <h3 className="section-title">
                        <i className="flaticon2-gear"></i>{pageTitle}
                    </h3>
                </Grid>
                <Grid container item xs={4} alignItems="center" justify="flex-end">
                <Link
                    className="btn btn-secondary"
                    to="/dashboard/companies"
                    ><i className="flaticon2-circular-arrow"/>
                        <span>Назад</span>
                    </Link>
                </Grid>
            </Grid>
            <Grid container className="padding">
                <form  className='form' noValidate onSubmit={this.onSubmit}>
                    <Grid container spacing={2}>
                        <Grid item md={3} xs={12}>
                            <TextField inputProps={{'className':'data-hj-allow'}}  className="full-width mb-20"
                                InputLabelProps={{shrink: true}}
                                required
                                margin="normal"
                                variant="outlined"
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <i className="flaticon2-user"></i>
                                        </InputAdornment>
                                    ),
                                }}
                                label="Име:"
                                name="name"
                                value={name}
                                error ={errors.name?true:false}
                                helperText={errors.name}
                                onChange={this.onChange}
                            />
                            
                            <TextField inputProps={{'className':'data-hj-allow'}}  className="full-width mb-20"
                                InputLabelProps={{shrink: true}}
                                required
                                margin="normal"
                                variant="outlined"
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <i className="fas fa-map-marker-alt"></i>
                                        </InputAdornment>
                                    ),
                                }}
                                label="Държава:"
                                name="country"
                                value={country}
                                error ={errors.country?true:false}
                                helperText={errors.country}
                                onChange={this.onChange}
                            /> 
                             <TextField inputProps={{'className':'data-hj-allow'}}  className="full-width mb-20"
                                InputLabelProps={{shrink: true}}
                                required
                                margin="normal"
                                variant="outlined"
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <i className="fas fa-map-marker-alt"></i>
                                        </InputAdornment>
                                    ),
                                }}
                                label="Град:"
                                name="city"
                                value={city}
                                error ={errors.city?true:false}
                                helperText={errors.city}
                                onChange={this.onChange}
                            /> 
                            <TextField inputProps={{'className':'data-hj-allow'}}  className="full-width mb-20"
                                InputLabelProps={{shrink: true}}
                                required
                                margin="normal"
                                variant="outlined"
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <i className="fas fa-map-marker-alt"></i>
                                        </InputAdornment>
                                    ),
                                }}
                                label="Адрес:"
                                name="address"
                                value={address}
                                error ={errors.address?true:false}
                                helperText={errors.address}
                                onChange={this.onChange}
                            />
                            <TextField inputProps={{'className':'data-hj-allow'}}  className="full-width mb-20"
                                InputLabelProps={{shrink: true}}
                                required
                                margin="normal"
                                variant="outlined"
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <i className="fab fa-chrome"></i> 
                                        </InputAdornment>
                                    ),
                                }}
                                label="Интернет страница на компанията:"
                                name="webPage"
                                value={webPage}
                                error ={errors.webPage?true:false}
                                helperText={errors.webPage}
                                onChange={this.onChange}
                            />
                        </Grid>
                        <Grid item md={3} xs={12}> 
                            <TextField inputProps={{'className':'data-hj-allow'}}  className="full-width mb-20"
                                InputLabelProps={{shrink: true}}
                                required
                                margin="normal"
                                variant="outlined"
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <i className="flaticon2-user"></i>
                                        </InputAdornment>
                                    ),
                                }}
                                label="МОЛ:"
                                name="mol"
                                value={mol}
                                error ={errors.mol?true:false}
                                helperText={errors.mol}
                                onChange={this.onChange}
                            />
                            <TextField inputProps={{'className':'data-hj-allow'}}  className="full-width mb-20"
                                InputLabelProps={{shrink: true}}
                                required
                                margin="normal"
                                variant="outlined"
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <i className="fas fa-map-marker-alt"></i>
                                        </InputAdornment>
                                    ),
                                }}
                                label="Tелефон:"
                                name="phone"
                                value={phone}
                                error ={errors.phone?true:false}
                                helperText={errors.phone}
                                onChange={this.onChange}
                            /> 
                            <TextField inputProps={{'className':'data-hj-allow'}}  className="full-width mb-20"
                                InputLabelProps={{shrink: true}}
                                required
                                margin="normal"
                                variant="outlined"
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <i className="fas fa-landmark"></i>
                                        </InputAdornment>
                                    ),
                                }}
                                label="ЕИК:"
                                name="eik"
                                value={eik}
                                error ={errors.eik?true:false}
                                helperText={errors.eik}
                                onChange={this.onChange}
                            />
                            <TextField inputProps={{'className':'data-hj-allow'}}  className="full-width mb-20"
                                InputLabelProps={{shrink: true}}
                                required
                                margin="normal"
                                variant="outlined"
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <i className="fas fa-landmark"></i>
                                        </InputAdornment>
                                    ),
                                }}
                                label="ДДС номер:"
                                name="vatNumber"
                                value={vatNumber}
                                error ={errors.vatNumber?true:false}
                                helperText={errors.vatNumber}
                                onChange={this.onChange}
                            />
                        </Grid> 
                        <Grid item md={1} xs={12} >
                        </Grid>
                        <Grid item md={5} xs={12} >
                            {image&&<> 
                                <h3 className="section-title">
                                    Снимка за оферта:
                                </h3>
                                <br/> 
                                <div className="image-wrapper">
                                    <div onClick={() => this.removePhoto('image')}  className="delete"><i className="flaticon2-delete"></i></div>
                                    <img className='full-width' src={image} alt=""/>
                                </div>
                                {!image &&<div className="image-holder">
                                    <p>Качете снимка</p>
                                    
                                    <input 
                                        accept="image/*" 
                                        type="file"
                                        id="image" 
                                        name="image" 
                                        onChange = {this.handlePhotoChange}
                                        />
                                </div>} 
                                </>  }    
                            {image.length<1&&<> 
                                <h3 className="section-title">
                                    Снимка за оферта:
                                </h3>
                                <br/> 
                                {base64&&<div className="image-wrapper">
                                    <div onClick={() => this.removePhoto('base64')}  className="delete"><i className="flaticon2-delete"></i></div>
                                    <img className='full-width' src={base64} alt=""/>
                                </div>}
                                {!base64 &&<div className="image-holder">
                                    <p>Качете снимка</p>
                                    <i> 1300px на 250px и не по-големи от 1 mb</i>
                                    <input 
                                        accept="image/*" 
                                        type="file"
                                        id="image" 
                                        name="base64" 
                                        onChange = {this.handlePhotoChange}
                                        />
                                </div>} 
                            </> }       
                        </Grid>
                    </Grid>
                    <Grid container alignItems="center" className='padding'>
                        <h3 className="section-title">
                            <i className="fas fa-money-check-alt"></i>Банки:
                        </h3>
                    </Grid>           
                    {banks.length==0&&<Grid container spacing={2}> 
                        <Grid container item md={3} xs={12} >
                        <button type="button" onClick={this.handleAddBank} className="btn full-width btn-info">
                            добави Банка
                        </button>
                        </Grid>
                    </Grid>}
                    {banks.map((item, i) => {
                        return(<div key={i}>
                            <Grid container spacing={2}> 
                                <Grid container item md={3} xs={12} >
                                    <TextField inputProps={{'className':'data-hj-allow'}} className="full-width mb-20"
                                        InputLabelProps={{shrink: true}}
                                        required
                                        margin="normal"
                                        variant="outlined"
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <i className="fas fa-piggy-bank"></i>
                                                </InputAdornment>
                                            ),
                                        }}
                                        label="Банка:"
                                        name="bank"
                                        value={item.bank} 
                                        onChange={e=>this.onChangeDinamicBank(e, i)}  
                                    />
                                </Grid>
                             
                                <Grid container item md={3} xs={12} >
                                    <TextField inputProps={{'className':'data-hj-allow'}} className="full-width mb-20"
                                        InputLabelProps={{shrink: true}}
                                        required
                                        margin="normal"
                                        variant="outlined"
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <i className="fas fa-money-check-alt"></i>
                                                </InputAdornment>
                                            ),
                                        }}
                                        label="IBAN на компанията:"
                                        name="iban"
                                        value={item.iban} 
                                        onChange={e=>this.onChangeDinamicBank(e, i)}  
                                />  
                                </Grid> 
                                <Grid container item md={2} xs={12} >
                                    <TextField inputProps={{'className':'data-hj-allow'}} className="full-width mb-20"
                                        InputLabelProps={{shrink: true}}
                                        required
                                        margin="normal"
                                        variant="outlined"
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <i className="fas fa-file-alt"></i>
                                                </InputAdornment>
                                            ),
                                        }}
                                        label="BIC:"
                                        name="bic"
                                        value={item.bic}
                                        onChange={e=>this.onChangeDinamicBank(e, i)}  
                                    />  
                                </Grid>  
                                <Grid container item md={2} xs={12} >
                                <FormControl variant="outlined" margin="normal" className='mb-20 full-width select-styles'>
                                    <InputLabel id="fuelType">
                                        Валута 
                                    </InputLabel>
                                    <Select  
                                        MenuProps={{
                                            anchorOrigin: {
                                            vertical: "bottom",
                                            horizontal: "right"
                                            },
                                            transformOrigin: {
                                            vertical: "top",
                                            horizontal: "right"
                                            },
                                            getContentAnchorEl: null
                                        }} 
                                        labelId="currency"
                                        required   
                                        inputProps={{
                                            name: 'currency', 
                                        }} 
                                        value={item.currency} 
                                        onChange={e=>this.onChangeDinamicBank(e, i)}  
                                        labelWidth={70}  >    
                                        <MenuItem value='BGN'>BGN</MenuItem>  
                                        <MenuItem value='EUR'>EUR</MenuItem>   
                                    </Select>
                                    
                                 </FormControl>  
                                </Grid>  
                                <Grid container item md={2} xs={12} >
                                    <div className="btn-box" style={{'margin':'30px 0 0 20px'}}>
                                        {/* {banks.length !== 1 && */}
                                        <div className='delete' onClick={()=>this.handleRemoveBank(i)}>
                                            <i className="flaticon2-delete"></i>  
                                        </div> 
                                        {/* } */}
                                        {banks.length - 1 === i && 
                                        <div className='add' onClick={this.handleAddBank}>
                                            +
                                        </div>}
                                    </div> 
                                </Grid> 
                        
                            </Grid>
                            </div> 
                        )}
                    )}
                    <Grid container spacing={2} style={{'marginBottom':'30px'}}> 
                        <Grid container item md={3} xs={12} >
                            <div className={"btn full-width "+(hasNotary ? 'btn-focus':'' )}
                                onClick={this.setAttorney}>
                                <i className="fas fa-people-carry"></i> {hasNotary ?'Скрии ':'Добави пълномощник'}
                            </div> 
                        </Grid>
                    </Grid>
                    {hasNotary&&<>
                    <Grid container alignItems="center" className='padding'>
                        <h3 className="section-title">
                            <i className="fas fa-gavel"></i>Данни за пълномощното
                        </h3>
                    </Grid>
                    <Grid container spacing={2}>

                        <Grid container item md={4} xs={12} >
                            <TextField inputProps={{'className':'data-hj-allow'}} className="full-width mb-20"
                                InputLabelProps={{shrink: true}}
                                required
                                margin="normal"
                                variant="outlined"
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <i className="flaticon2-user"></i>
                                        </InputAdornment>
                                    ),
                                }}
                                label="Пълномощник Име:"
                                name="agentName"
                                value={agentName}
                                error ={errors.agentName?true:false}
                                helperText={errors.agentName}
                                onChange={this.onChange}
                            />
                            <TextField inputProps={{'className':'data-hj-allow'}} className="full-width mb-20"
                                InputLabelProps={{shrink: true}}
                                required
                                margin="normal"
                                variant="outlined"
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <i className="far fa-calendar-alt"></i>
                                        </InputAdornment>
                                    ),
                                }}
                                label="Пълномощно номер:"
                                name="notaryRegNumber"
                                value={notaryRegNumber}
                                error ={errors.notaryRegNumber?true:false}
                                helperText={errors.notaryRegNumber}
                                onChange={this.onChange}
                            />
                        </Grid>
                        <Grid container item md={4} xs={12} >

                            <TextField inputProps={{'className':'data-hj-allow'}} className="full-width mb-20"
                                InputLabelProps={{shrink: true}}
                                required
                                margin="normal"
                                variant="outlined"
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <i className="flaticon2-user"></i>
                                        </InputAdornment>
                                    ),
                                }}
                                label="Име нотариус:"
                                name="notaryName"
                                value={notaryName}
                                error ={errors.notaryName?true:false}
                                helperText={errors.notaryName}
                                onChange={this.onChange}
                            />
                             <TextField inputProps={{'className':'data-hj-allow'}} className="full-width mb-20"
                                InputLabelProps={{shrink: true}}
                                required
                                margin="normal"
                                variant="outlined"
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <i className="far fa-calendar-alt"></i>
                                        </InputAdornment>
                                    ),
                                }}
                                label="Дата пълномощно:"
                                name="notaryDate"
                                value={notaryDate}
                                error ={errors.notaryDate?true:false}
                                helperText={errors.notaryDate}
                                onChange={this.onChange}
                            />
                        </Grid>
                        <Grid container item md={4} xs={12} >
                            <TextField inputProps={{'className':'data-hj-allow'}} className="full-width mb-20"
                                InputLabelProps={{shrink: true}}
                                required
                                margin="normal"
                                variant="outlined"
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <i className="fas fa-gavel"></i>
                                        </InputAdornment>
                                    ),
                                }}
                                label="Рег. номер нотариус:"
                                name="attorneyNumber"
                                value={attorneyNumber}
                                error ={errors.attorneyNumber?true:false}
                                helperText={errors.attorneyNumber}
                                onChange={this.onChange}
                            />
                            <TextField inputProps={{'className':'data-hj-allow'}} className="full-width mb-20"
                                InputLabelProps={{shrink: true}}
                                required
                                margin="normal"
                                variant="outlined"
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <i className="fas fa-globe-americas"></i>
                                        </InputAdornment>
                                    ),
                                }}
                                label="Район действие нотариус:"
                                name="districtNotary"
                                value={districtNotary}
                                error ={errors.districtNotary?true:false}
                                helperText={errors.districtNotary}
                                onChange={this.onChange}
                            />
                        </Grid>
                    </Grid></>}
                    <Grid container alignItems="center" className='padding'>
                        <h3 className="section-title">
                            <i className="fas fa-car"></i>Свързани брандове:
                        </h3>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid container item xs={12} >
                            <Select
                                className='full-width'
                                labelId="demo-mutiple-chip-label"
                                id="demo-mutiple-chip"
                                multiple
                                value={brands}
                                onChange={this.handleChangeBrands}
                                input={<Input id="select-multiple-chip" />}
                                renderValue={(selected) => (

                                    <div className="chips">
                                    {selected.map((item) =>{   
                                        return  ( 
                                            brandsList.length>0&&<Chip key={item} label={brandsList.find(x => x.id === item).name} className="chip"/>
                                   
                                        )})}
                                    </div>
                                )}
                                >
                                {brandsList.map((item) => {
                                    return (
                                        <MenuItem key={item.id} value={item.id} className="chip-options">
                                            {item.name}
                                        </MenuItem>
                                    )
                                })}
                            </Select>
                        </Grid>
                    </Grid>
                    <br/>
                    <br/>
                    <Grid container item md={4} xs={12} >
                        {roleId!==19&&<button type="submit" className="btn btn-info full-width">
                            <i className="flaticon2-add-square"></i>Запази
                        </button>}
                    </Grid>
            </form>
            </Grid>
        </Card >
        </>
        );
    }
}
const mapStateToProps = (state, props) => {
    return{
        company:state.companies.singleCompany,
        brandsList:state.brands.brandsList
    }
}
export default connect(mapStateToProps, {fetchSingleCompany, createCompany, editCompany, fetchBrandsList })(EditCompany);
