let today = new Date();
let dd = String(today.getDate()).padStart(2, '0');
let mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
let yyyy = today.getFullYear();

const initialState = {
    items:[],
    singleOrder:{
        hasLeasing:false, 
        hasAdditionalClient:false, 
        hasBuyBack:false, 
        status:1,
        orderDate:dd+'.'+mm+'.'+yyyy,
        proformaRecipient:'person',
        companyId: localStorage.getItem('companyId'),
        company: localStorage.getItem('companyName'),
        contactName:""
    },
    leasingCompanies:[],
    orderInProgress:{}
}

export default function (state = initialState, action){  
    const {type, payload} = action; 
    switch(type){
        case 'FETCH_ORDERS':  
            return{
                ...state,
                items:payload 
            } 
        case 'FETCH_SINGLE_ORDER':  
        // const {bic,bicEur,bank, iban,ibanEur} = payload.offer.company 
            return{
                ...state,
                singleOrder:{
                    ...payload, 
                    proformaRecipient:'person'
                    // bic,bicEur,bank, iban,ibanEur
                } 
            } 
        case 'FETCH_ORDERS_LEASING_COMPANIES':  
            return{
                ...state,
                leasingCompanies:payload 
            }  
        case 'CREATE_ORDER':
            return { 
                ...state,
                items: [...state.items, payload]
            } 
        case 'EDIT_ORDER': 
            const{obj, data}= payload 
            if (obj==='none') {
                return{ 
                    ...state,
                    singleOrder:{  
                        ...state.singleOrder,
                        ...data,
                    } 
                } 
            }else{ 
                return{  
                    ...state,
                    singleOrder:{ 
                        ...state.singleOrder,
                        [obj]:data 
                    }
                }
            }
        // case 'ORDER_IN_PROGRESS':  
        //     const{obj, data}= payload
        //     if (obj==='none') {
        //         return{ 
        //             ...state,
        //             orderInProgress:{
        //                 hasLeasing:false,
        //                 hasAdditionalClient:false,
        //                 ...state.orderInProgress,
        //                 ...data,
        //             } 
        //         } 
        //     }else{
        //         return{ 
        //             ...state,
        //             orderInProgress:{
        //                 hasLeasing:false,
        //                 hasAdditionalClient:false, 
        //                 ...state.orderInProgress,
        //                 [obj]:data 
        //             }
        //         }
        //     }
        case 'CLEAR_ORDER':  
            return{ 
                ...state,
                singleOrder:{
                    hasLeasing:false,
                    hasAdditionalClient:false, 
                    hasBuyBack:false, 
                    status:1,
                    orderDate:dd+'.'+mm+'.'+yyyy,
                    proformaRecipient:'person',
                    companyId: localStorage.getItem('companyId'),
                    company: localStorage.getItem('companyName'),
                    contactName:""
                }
            } 
        default:
            return state;
    }
}