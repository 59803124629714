import React from "react";
import { Link } from "react-router-dom";

export const options = {
    sortOrder: {
        name: "id",
        direction: "desc",
    },
    confirmFilters: true,
    customFilterDialogFooter: (currentFilterList, applyNewFilters) => {
        return (
            <div style={{ marginTop: "40px" }}>
                <button className='btn btn-info small' onClick={() => applyNewFilters()}>
                    Приложи филтрите
                </button>
            </div>
        );
    },
    print: false,
    download: true,
    downloadOptions: {
        filename: "Contacts.csv",
        separator: ",",
        filterOptions: {
            useDisplayedColumnsOnly: true,
        },
    },
    viewColumns: true,
    filter: false,
    selectableRows: "none",
    textLabels: {
        body: {
            noMatch: "Няма намерени резултати",
            toolTip: "Сортирай",
            columnHeaderTooltip: (column) => `Сортирай по ${column.label}`,
        },
        pagination: {
            next: "Следваща страница",
            previous: "Предишна страница",
            rowsPerPage: "Брой записи на страница:",
            displayRows: "от",
        },
        toolbar: {
            search: "Търси",
            downloadCsv: "Download CSV",
            print: "Принтирай",
            viewColumns: "Колони",
            filterTable: "Филтри",
        },
        filter: {
            all: "Всички",
            title: "Филтри",
            reset: "Откажи",
        },
        viewColumns: {
            title: "Покажи Колони",
            titleAria: "Покажи/Скрии Колони",
        },
        selectedRows: {
            text: "запис/и са селектирани за изтриване",
            delete: "Изтриване",
            deleteAria: "Изтриите избраните записи",
        },
    },
};

export const columns = [
    {
        label: "ID:",
        name: "id",
        options: {
            filter: false,
            sort: true,
        },
    },
    {
        label: "Име:",
        name: "name",
        options: {
            filter: false,
            sort: true,
        },
    },
    {
        label: "Е-mail:",
        name: "email",
        options: {
            filter: false,
            sort: true,
        },
    },
    {
        label: "Телефон:",
        name: "phone",
        options: {
            filter: false,
            sort: true,
        },
    },
    {
        label: "Компания:",
        name: "clientCompany",
        options: {
            filter: false,
            sort: true,
        },
    },
    {
        label: "Tърговец:",
        name: "createdBy",
        options: {
            filter: false,
            sort: true,
        },
    },
    {
        label: "Модел:",
        name: "model",
        options: {
            filter: false,
            sort: true,
        },
    },
    {
        label: "Канал:",
        name: "chanel",
        options: {
            filter: false,
            sort: true,
            customBodyRender: (value, tableMeta, updateValue) => {
                if (value) {
                    let chanelList = [];
                    for (let index = 0; index < value.length; index++) {
                        if (value[index] == 1) {
                            chanelList.push("Социални мрежи");
                        } else if (value[index] == 2) {
                            chanelList.push("Google");
                        } else if (value[index] == 3) {
                            chanelList.push("Радио");
                        } else if (value[index] == 4) {
                            chanelList.push("Print");
                        } else if (value[index] == 5) {
                            chanelList.push("Бордове");
                        } else if (value[index] == 6) {
                            chanelList.push("От приятел");
                        }
                    }
                    return chanelList.map((item, i) => {
                        return (
                            <span style={{ display: "inline-block", padding: "2px 5px" }}>
                                {item},
                            </span>
                        );
                    });
                }
            },
        },
    },
    {
        label: "TestDrive:",
        name: "testDrives",
        options: {
            filter: false,
            sort: false,
            customBodyRender: (value, tableMeta, updateValue) => {
                let id = tableMeta.rowData[0];
                if (value > 0) {
                    return (
                        <Link to={"/dashboard/drive-list?contactId=" + id} className='small btn'>
                            {value}
                        </Link>
                    );
                } else {
                    return value;
                }
            },
        },
    },
    {
        label: "Оферти:",
        name: "offers",
        options: {
            filter: false,
            sort: false,
            customBodyRender: (value, tableMeta, updateValue) => {
                let id = tableMeta.rowData[0];
                if (value > 0) {
                    return (
                        <Link to={"/dashboard/offers?contactId=" + id} className='small btn'>
                            {value}
                        </Link>
                    );
                } else {
                    return value;
                }
            },
        },
    },
    {
        label: "Поръчки:",
        name: "orders",
        options: {
            filter: false,
            sort: false,
            customBodyRender: (value, tableMeta, updateValue) => {
                let id = tableMeta.rowData[0];
                if (value > 0) {
                    return (
                        <Link to={"/dashboard/orders?contactId=" + id} className='small btn'>
                            {value}
                        </Link>
                    );
                } else {
                    return value;
                }
            },
        },
    },
    {
        label: "Редактирай контакт:",
        name: "аdd",
        options: {
            filter: false,
            sort: false,
            customBodyRender: (value, tableMeta, updateValue) => {
                let id = tableMeta.rowData[0];
                const permissions = JSON.parse(localStorage.getItem("permissions"));
                if (permissions.contactsUpdate) {
                    return (
                        <Link to={"/dashboard/contacts/" + id} className='small btn btn-info'>
                            <i className='flaticon-edit'></i>Редактирай
                        </Link>
                    );
                } else {
                    return "нямате достъп";
                }
            },
        },
    },
];
