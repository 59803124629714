import React, { Component } from 'react';
import {connect} from 'react-redux'
import { withRouter } from "react-router-dom";

import Grid from '@material-ui/core/Grid';

import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Autocomplete from '@material-ui/lab/Autocomplete'; 

import InputAdornment from '@material-ui/core/InputAdornment';
import MomentUtils from "@date-io/moment";
import moment from "moment";
import {MuiPickersUtilsProvider, KeyboardDatePicker} from '@material-ui/pickers'; 
import CircularProgress from '@material-ui/core/CircularProgress'; 

import ClientForm from '../clients/forms/Client_Form'
import CompanyForm from '../clients/forms/Company_Form' 
import BuyBack from './BuyBack' 

 
import {fetchSingleClient,fetchClientsAutoComplete} from '../../../_actions/clientsActions'
import {fetchLeasingCompanies, updateOrder} from '../../../_actions/ordersActions'
 
 
class UserAndLising extends Component { 
    state={ 
        editMode:false, 
        hasLeasing:false,
        hasAdditionalClient:false,
        hasBuyBack:false, 
        open:false,
        loadingText:"Моля въведете минимум 3 символа!"
    }


    componentDidMount(){ 

        if (this.props.match.params._id) { 
            this.setState({editMode:true}) 
           
        }
        if (this.props.singleOrder.additionalClient) {
            this.setState({
                type:this.props.singleOrder.additionalClient.type
            })
        }
    } 

    onChange = (event) =>{ 
        const {name,value} = event.target;
        this.props.updateOrder('leasingUser', {...this.props.singleOrder.leasingUser,[name]:value}) 
    }

    onChangeDate = (input, date, value) => {   
        this.props.updateOrder('leasingUser', {...this.props.singleOrder.leasingUser,[input]:value}) 
    }

    componentDidUpdate(previousProps, previousState){
        const{editMode} =this.state;  
        if (editMode) {
            if(previousProps.singleOrder !== this.props.singleOrder){   
                const{additionalClient} =this.props.singleOrder 
                this.setState({
                    type:additionalClient.type
                })
            }    
            if(previousProps.leasingCompanies !== this.props.leasingCompanies){   
                const{leasingCompany} =this.props.singleOrder
         
                let leasingUserInfo = this.props.leasingCompanies.filter(item => item.name==leasingCompany)   
                const {id,companyName,type} = leasingUserInfo[0] || {} 
                this.props.updateOrder('none', {
                    leasingCompanyId:id,
                    leasingCompany:companyName,
                    leasingCompanType:type
                })  
                this.props.updateOrder('leasingUser',{
                    ...leasingUserInfo[0]
                }) 
            }    
        } 
    }
     
    
     
    handleData=(data)=>{    
        this.props.updateOrder('additionalClient', {...data, singleAditionalClientId:this.props.singleOrder.additionalClient.singleAditionalClientId})  

        const {navId=""}=data
        if (this.state.type===1) {
            if (navId.length===0 ) { 
                this.props.updateOrder('none', {additionalClientId:data.id}) 
                this.props.updateOrder('additionalClient', {...data, singleAditionalClientId:data.id})  
            }else{
                this.props.updateOrder('none', {additionalClientId:''}) 
            }  
        }else if (this.state.type===2) {
            this.props.updateOrder('none', {additionalClientId:data.id}) 
        } 
    } 

    getClientAutocomplete=(name)=>{ 
        this.props.fetchClientsAutoComplete(name, this.state.type)
    }

   
    handleCheckbox = (event) =>{
        const{name, checked } = event.target;  
        const {singleOrder} = this.props;
        if (name=='hasAdditionalClient') {
            this.setType(1)
        }
        if (name==='hasLeasing' &&!checked) { 
            this.props.updateOrder('leasingUser',{}) 
        }
        this.props.updateOrder('none', {
            ...singleOrder,
            [name]:checked 
        })
    }

    changeLizingData = (event, value, reason) => { 

        if (value) {    
     
            const {id,companyName,type,egn,email,idCardDate,idCardNumber,idCardPublishedBy,name,phone, companyAddress,companyCity,companyEik,companyMol,correspondenceAddress}=value || {}
            const {companyContact,companyContactEgn,companyContactCity,companyContactIdCardNumber,companyContactIdCardPublishedBy,companyContactIdCardDate,proxyNumber,proxyAuthor,attorneyNumber}=value || {}
            this.props.updateOrder('none', {
                leasingCompanyId:id,
                leasingCompany:companyName,
                leasingCompanType:type,

            })  
            this.props.updateOrder('leasingUser',{
                id,companyName,type,egn,email,idCardDate,idCardNumber,idCardPublishedBy,name,phone,companyAddress,companyCity,companyEik,companyMol,correspondenceAddress,
                companyContact,companyContactEgn,companyContactCity,companyContactIdCardNumber,companyContactIdCardPublishedBy,companyContactIdCardDate,proxyNumber,proxyAuthor,attorneyNumber
            }) 
            
        }
    }

    changeLizingName = (event, value, reason) => {
        if(event || value){            
            this.props.updateOrder('none', {
                leasingCompany:value,   
            }) 
        } 
    }
 
   
    setType =(num)=>{ 
        const {singleOrder} = this.props

        const {singleAditionalClientId='', companyClientId='', navId=""}= this.props.singleOrder.additionalClient || {}
        this.setState({
            type:num
        },()=>{
            this.props.updateOrder('additionalClient', {
                ...singleOrder.additionalClient,
                type:num
            })
            if (num===1 ) {
                if (navId.length===0 ) {
                    this.props.updateOrder('none', {additionalClientId:singleAditionalClientId}) 
                }else{
                    this.props.updateOrder('none', {additionalClientId:""}) 
                }
            }
            if (num===2) {
                this.props.updateOrder('none', {additionalClientId:companyClientId}) 
            }
        })

       
        this.setState({
            type:num
        },()=>{this.props.updateOrder('additionalClient', {
            ...singleOrder.additionalClient,
            type:num
        })})
    }

    changeClientData = (event, value, reason) => {
        if (value) {    
            this.handleData(value);
        }
    }

    changeCLientName = (event, value, reason) => { 
        if (value.length>3) { 
            this.setState({loadingText:'Зареждане списък...'})
            this.props.fetchClientsAutoComplete(value, this.state.type) 
        }  
    }

    render() {  

        const {errors={}, leasingCompanies, singleOrder, clients}=this.props;    
      
        const{leasingCompanType=1,hasLeasing=false, hasAdditionalClient=false, leasingCompany, hasBuyBack=false, leasingUser={}}=singleOrder;   
        const {editMode,type}=this.state;  
        const {leasingUserErrors={}}=errors;   
        const {open, loadingText}=this.state;  
        const loading = open && clients.length === 0;

        const {companyContact,companyContactEgn,companyContactCity,companyContactIdCardNumber,companyContactIdCardPublishedBy,companyContactIdCardDate,proxyNumber,proxyAuthor,attorneyNumber} = leasingUser 
        return ( 
            <> 
                <Grid container spacing={3} > 
                    <Grid item md={4} xs={12}> 
                        <FormControlLabel
                            control={
                            <Checkbox
                                checked={hasLeasing}
                                onChange={this.handleCheckbox}
                                name="hasLeasing"
                                color="primary"
                            />
                            }
                            label="Добави Лизинг"
                        />  
                       {(hasLeasing&&leasingCompanies.length>0)&&<Autocomplete  
                            inputValue={leasingCompany}
                            options={leasingCompanies}   
                            onChange={this.changeLizingData} 
                            onInputChange={this.changeLizingName} 
                            getOptionLabel={option => option.name}
                            renderInput={params => (
                                <TextField {...params}
                                className="full-width mb-20"
                                InputLabelProps={{shrink: true}} 
                                margin="normal"
                                variant="outlined"
                                label="Лизингова компания:" 
                                error ={errors.leasingCompanyId?true:false} 
                                helperText={errors.leasingCompanyId}  
                                />
                            )} 
                        />
                        }
                    </Grid>
                </Grid>
                {(hasLeasing&&leasingCompanType===2)&&<Grid container spacing={3} > 
                    <Grid item md={4} xs={12}> 
                        <TextField inputProps={{'className':'data-hj-allow'}} className="full-width mb-20" 
                            InputLabelProps={{shrink: true}} 
                            required 
                            margin="normal" 
                            variant="outlined"  
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <i className="flaticon2-user"></i>  
                                    </InputAdornment>
                                ),
                            }}
                            label="Лице за контакт:"
                            name="companyContact"
                            value={companyContact}
                            onChange={this.onChange}
                            error ={leasingUserErrors.companyContact?true:false}  
                            helperText={leasingUserErrors.companyContact}  
                        />     
                        <TextField inputProps={{'className':'data-hj-allow'}}  className="full-width mb-20" 
                            InputLabelProps={{shrink: true}} 
                            required 
                            margin="normal" 
                            variant="outlined"  
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <i className="flaticon-rotate"></i>  
                                    </InputAdornment>
                                ),
                            }}label="ЕГН:"
                            name="companyContactEgn"  
                            value={companyContactEgn}
                            error ={leasingUserErrors.companyContactEgn?true:false}  
                            helperText={leasingUserErrors.companyContactEgn}  
                            onChange={this.onChange} 
                        />  
                        <TextField inputProps={{'className':'data-hj-allow'}}  className="full-width mb-20" 
                            InputLabelProps={{shrink: true}} 
                            required 
                            margin="normal" 
                            variant="outlined"  
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <i className="flaticon-home-1"></i>  
                                    </InputAdornment>
                                ),
                            }}
                            label="С постоянен адрес Град"
                            name="companyContactCity"  
                            value={companyContactCity}
                            error ={leasingUserErrors.companyContactCity?true:false}  
                            helperText={leasingUserErrors.companyContactCity} 
                            onChange={this.onChange} 
                        />  
                    </Grid>   

                    <Grid item md={4} xs={12}>    
                        <TextField inputProps={{'className':'data-hj-allow'}}  className="full-width mb-20" 
                            InputLabelProps={{shrink: true}} 
                            required 
                            margin="normal" 
                            variant="outlined"  
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <i className="flaticon2-browser"></i>  
                                    </InputAdornment>
                                ),
                            }}
                            label="ЛК/номер:"
                            name="companyContactIdCardNumber" 
                            value={companyContactIdCardNumber}
                            error ={leasingUserErrors.companyContactIdCardNumber?true:false} 
                            helperText={leasingUserErrors.companyContactIdCardNumber} 
                            onChange={this.onChange} 
                        />  
                        <TextField inputProps={{'className':'data-hj-allow'}}  className="full-width mb-20" 
                            InputLabelProps={{shrink: true}} 
                            required 
                            margin="normal" 
                            variant="outlined"  
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <i className="flaticon2-browser"></i>  
                                    </InputAdornment>
                                ),
                            }}
                            label="Издадена от:"
                            name="companyContactIdCardPublishedBy" 
                            value={companyContactIdCardPublishedBy}
                            error ={leasingUserErrors.companyContactIdCardPublishedBy?true:false}  
                            helperText={leasingUserErrors.companyContactIdCardPublishedBy} 
                            onChange={this.onChange} 
                        />  
                          <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}   >
                            <KeyboardDatePicker inputProps={{'className':'data-hj-allow'}} 
                                autoOk={true}
                                showTodayButton={true} 
                                InputLabelProps={{shrink: true}} 
                                className="full-width date mb-20"  
                                inputVariant="outlined" 
                                margin="normal"
                                label="ЛК/дата на издаване:" 
                                format="DD.MM.YYYY"
                                value=''
                                inputValue={companyContactIdCardDate} 
                                onChange={(e,d)=>this.onChangeDate('companyContactIdCardDate', e ,d)}
                                // rifmFormatter={this.dateFormatter} 
                                error ={leasingUserErrors.companyContactIdCardDate?true:false}  
                                helperText={leasingUserErrors.companyContactIdCardDate} 
                            />
                        </MuiPickersUtilsProvider>
                       
                    </Grid>   
                    <Grid item md={4} xs={12}>   
                        <TextField inputProps={{'className':'data-hj-allow'}} className="full-width mb-20" 
                            InputLabelProps={{shrink: true}}  
                            margin="normal" 
                            variant="outlined"  
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <i className="flaticon2-list-2"></i>  
                                    </InputAdornment>
                                ),
                            }}
                            label="РЕГ. № на пълномощно"
                            name="proxyNumber"  
                            value={proxyNumber}
                            error ={leasingUserErrors.proxyNumber?true:false}  
                            helperText={leasingUserErrors.proxyNumber} 
                            onChange={this.onChange} 
                        /> 
                        <TextField inputProps={{'className':'data-hj-allow'}} className="full-width mb-20" 
                            InputLabelProps={{shrink: true}}  
                            margin="normal" 
                            variant="outlined"  
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <i className="flaticon2-list-2"></i>  
                                    </InputAdornment>
                                ),
                            }}
                            label="Издадено от:"
                            name="proxyAuthor"  
                            value={proxyAuthor}
                            error ={leasingUserErrors.proxyAuthor?true:false}  
                            helperText={leasingUserErrors.proxyAuthor} 
                            onChange={this.onChange} 
                        /> 
                        <TextField inputProps={{'className':'data-hj-allow'}} className="full-width mb-20" 
                            InputLabelProps={{shrink: true}}  
                            margin="normal" 
                            variant="outlined"  
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <i className="flaticon2-list-2"></i>  
                                    </InputAdornment>
                                ),
                            }}
                            label="Нотариус с рег. №:"
                            name="attorneyNumber"  
                            value={attorneyNumber}
                            error ={leasingUserErrors.attorneyNumber?true:false}  
                            helperText={leasingUserErrors.attorneyNumber} 
                            onChange={this.onChange} 
                        />  
                    </Grid> 
                </Grid>}


                <Grid container spacing={3} > 
                    <Grid item md={4} xs={12}> 
                        <FormControlLabel
                            control={
                            <Checkbox
                                checked={hasAdditionalClient}
                                onChange={this.handleCheckbox}
                                name="hasAdditionalClient"
                                color="primary"
                            />
                            }
                            label="Добави Ползвател"
                        /> 
                    </Grid>
                </Grid>
                {hasAdditionalClient&&
                <Grid container spacing={3} > 
                    <Grid item md={4} xs={12}> 
                        <Autocomplete 
                            open={open}
                            onOpen={() => {
                                this.setState({open:true});
                            }}
                            onClose={() => {
                                this.setState({open:false});
                            }}
                            loadingText={loadingText}
                            loading={loading}
                            options={clients}   
                            onChange={this.changeClientData} 
                            onInputChange={this.changeCLientName} 
                            getOptionLabel={option => {
                                return option.name ?  
                                    option.name+" - "+(option.type===1 ? "ФЗ":"ЮЛ") :
                                    option.companyName+" - "+(+option.type===1 ? "ФЗ":"ЮЛ")
                            }}
                            renderInput={params => (
                                <TextField {...params}
                                className="full-width mb-20"
                                InputLabelProps={{shrink: true}} 
                                margin="normal"
                                variant="outlined"
                                label="Търси клиент:" 
                                InputProps={{
                                    ...params.InputProps,
                                    endAdornment: (
                                        <>
                                        {loading ? (
                                        <CircularProgress color="inherit" size={20} />
                                        ) : null}
                                        {params.InputProps.endAdornment}
                                        </>
                                    )
                                }}
                                />
                            )} 
                        />
                    </Grid>
                </Grid>
                }
               {hasAdditionalClient&&<> <div className="header-section-inner mb-20"> 
                    <div className={"btn mr-10 "+(type==1 ? 'btn-focus':'' )}
                        onClick={()=>this.setType(1)}>
                        <i className="flaticon2-user"></i>  Физическо лице
                    </div>
                    <div className={"btn "+(type==2 ? 'btn-focus':'' )}
                         onClick={()=>this.setType(2)}>
                        <i className="far fa-building"></i> Юридическо лице
                    </div> 
                </div>
                {type==1&&<ClientForm  
                    handleData ={this.handleData}
                    getClientAutocomplete={this.getClientAutocomplete}
                    clients={clients}
                    type={type}
                    errors={errors.additionalClient}
                    editMode={editMode}
                    userData={singleOrder.additionalClient} 
                />
                }
                {type==2&&<CompanyForm
                    handleData ={this.handleData}
                    getClientAutocomplete={this.getClientAutocomplete}
                    type={type}
                    clients={clients}
                    errors={errors.additionalClient}
                    editMode={editMode}
                    userData={singleOrder.additionalClient}
                />
                }   
                </>}   
           
                <Grid container spacing={3} > 
                    <Grid item md={4} xs={12}> 
                        <FormControlLabel
                            control={
                            <Checkbox
                                checked={hasBuyBack}
                                onChange={this.handleCheckbox}
                                name="hasBuyBack"
                                color="primary"
                            />
                            }
                            label="Обратно Изкупуване"
                        /> 
                    </Grid>
                </Grid>
               {hasBuyBack&&<BuyBack
                    userData={singleOrder.buyBack}
                    errors={errors.buyBack}
                />
                }    
           
            </>
            
         );
    }
}

const mapStateToProps = (state, props) => {   
    return{
        client:state.clients.singleClient,
        clients:state.clients.clientsAutoComplete,
        leasingCompanies:state.orders.leasingCompanies,
        singleOrder:state.orders.singleOrder
    }
}
export default withRouter(connect(mapStateToProps, {fetchSingleClient,fetchClientsAutoComplete, updateOrder })(UserAndLising));  

 