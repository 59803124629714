import React, { Component } from 'react';
import {connect} from 'react-redux'

import Grid from '@material-ui/core/Grid'; 

import {fetchRelatedContactsCompanies} from '../../../_actions/contactsActions'

class AttachedClients extends Component {
    state = {} 
    componentDidMount(){   
        if (this.props.id) {
            this.props.fetchRelatedContactsCompanies(this.props.id);
        }
    }
  
    render() { 
        const {relatedContactCompanies} = this.props;
        const relatedClients=relatedContactCompanies.filter(item=>item.type===1)
        return (  
            <>
                <Grid container className="section-title-wrapper padding"> 
                    <Grid container item xs={8} alignItems="center"> 
                        <h3 className="section-title">
                            Свързани клиенти:
                        </h3>
                    </Grid>  
                </Grid>  
                <Grid container spacing={3}> 
                    <Grid item md={12} xs={12}>  
                        <table className="regular-table">
                                <thead>                         
                                <tr>
                                    <th>ID:</th>
                                    <th>Име на клиента:</th> 
                                    <th>ЕГН:</th>
                                    <th>Телефон:</th>
                                    <th>E-mail:</th>
                              
                                </tr>
                            </thead>   
                            <tbody>
                                {relatedClients.map( item =>{  
                                    return(
                                        <tr>
                                            <td>{item.id}</td>
                                            <td>{item.name}</td> 
                                            <td>{item.egn}</td> 
                                            <td>{item.phone}</td> 
                                            <td>{item.email}</td>  
                                        </tr> 
                                    ) 
                                })} 
                                {(relatedClients.length ===0) &&<tr>
                                    <td colSpan="5"><h3>Няма резултати</h3></td> 
                                </tr>}
                            </tbody> 
                        </table>       
                    </Grid>    
                  
                </Grid>   
            </>
        );
    }
}
const mapStateToProps = (state, props) => {  
    return{ 
        relatedContactCompanies:state.contacts.relatedContactCompanies,  
    } 
}
export default connect(mapStateToProps, {fetchRelatedContactsCompanies })(AttachedClients); 