import React, { Component } from 'react';
import {NavLink, Link} from 'react-router-dom'
import logo from '../../styles/autounion.png';

import {connect} from 'react-redux'
import {logout} from '../../_actions/authActions' 

class SideBar extends Component {
   
    state = {
        mobileMenu:false,
        permissions: JSON.parse(localStorage.getItem('permissions'))? JSON.parse(localStorage.getItem('permissions')) :{},
        settingsMenu:false,
        permissionsMenu:false,
        collapse:false
    }

    onCollapse = () =>this.setState({collapse:!this.state.collapse})

    showMenu = () =>this.setState({mobileMenu:!this.state.mobileMenu})
    toggleSettings = () =>this.setState({settingsMenu:!this.state.settingsMenu})
    togglePermissions = () =>this.setState({permissionsMenu:!this.state.permissionsMenu})
    
    onClick = () =>{
        this.props.logout();
    }
    
    render() {  
        const {mobileMenu, permissions, settingsMenu, permissionsMenu, collapse} = this.state; 
        return (  
            <React.Fragment>
                <div className={"SideBar " + (collapse?'collapse':'')}> 
                    <h2 className="logo"><img src={logo} alt=""/></h2>
                    <div className="mobile-menu" onClick={this.showMenu}>
                        <i className="flaticon2-menu-1"></i>
                    </div>
                    
                    <div className={"main-nav-wrapper "+ (mobileMenu ? 'active':'') }> 
                        <div className="close-nav" onClick={this.showMenu}>
                            <i className="flaticon2-cross"></i>
                        </div> 
                        <div className='expand-collapse' onClick={this.onCollapse}>
                            <i className='flaticon2-soft-icons'></i>
                        </div>
                        <h3 className="title">Навигация:</h3>
                        <ul className="main-nav">
                            {permissions.testDrivesShow&&<li>
                                <NavLink activeClassName='active' to="/dashboard/drive-list" onClick={this.showMenu}>
                                    <i className="flaticon2-telegram-logo"></i>
                                    <span>Testdrive</span>
                                </NavLink>
                            </li>}
                            {permissions.contractsShow&&<li>
                                <NavLink activeClassName='active'  to="/dashboard/agreements" onClick={this.showMenu}>
                                    <i className="fas fa-file-contract"></i> 
                                    <span>Договори</span>
                                </NavLink>
                            </li>}
                            {permissions.ordersShow&&<li>
                                <NavLink activeClassName='active'  to="/dashboard/orders" onClick={this.showMenu}>
                                    <i className="fas fa-file-signature"></i>
                                    <span>Поръчки</span>
                                </NavLink>
                            </li>}
                            {permissions.offersShow&&<li>
                                <NavLink activeClassName='active'  to="/dashboard/offers" onClick={this.showMenu}>
                                    <i className="far fa-folder-open"></i>
                                    <span>Оферти</span>
                                </NavLink>
                            </li>}  
                            {permissions.carsShow&&<li>
                                <NavLink activeClassName='active'  to="/dashboard/cars" onClick={this.showMenu}>
                                    <i className="fas fa-car"></i>
                                    <span>Тестови автомобили</span>
                                </NavLink>
                            </li>}
                            {permissions.salesCarsShow&&<li>
                                <NavLink activeClassName='active'  to="/dashboard/sale_cars" onClick={this.showMenu}>
                                    <i className="fas fa-car"></i>
                                    <span>Автомобили - продажба</span>
                                </NavLink>
                            </li>}
                            {permissions.clientsShow&&<li>
                                <NavLink activeClassName='active'  to="/dashboard/clients" onClick={this.showMenu}>
                                    <i className="flaticon2-list-3"></i>
                                    <span>Клиенти</span>
                                </NavLink>
                            </li>} 
                            {permissions.contactsShow&&<li>
                                <NavLink activeClassName='active'  to="/dashboard/contacts" onClick={this.showMenu}>
                                    <i className="far fa-address-book"></i>
                                    <span>Контакти</span>
                                </NavLink>
                            </li>}
                            {permissions.usersShow&&<li>
                                <NavLink activeClassName='active'  to="/dashboard/users" onClick={this.showMenu}>
                                    <i className="flaticon-users"></i>
                                    <span>Потребители</span>
                                </NavLink>
                            </li>}
                           
                          
                            {permissions.rolesShow&&<li className='drodown-menu'>
                                <div className='element'  onClick={this.togglePermissions}>
                                    <i className="flaticon2-gear"></i>
                                    <span>Права</span>
                                </div>
                         
                                <ul className={permissionsMenu?'active':''}> 
                                    {permissions.brandsShow&&<li>
                                        <NavLink activeClassName='active' to="/dashboard/roles" onClick={this.showMenu}>
                                            <i className="flaticon-users"></i>
                                            <span>Роли</span>
                                        </NavLink>
                                    </li>}
                                    {permissions.companiesShow&&<li>
                                        <NavLink activeClassName='active'  to="/dashboard/formsPermissions" onClick={this.showMenu}>
                                            <i className="fas fa-car"></i>
                                            <span>Sales Cars</span>
                                        </NavLink>
                                    </li>}
                                </ul>
                            </li>}  
                            {(permissions.brandsShow || permissions.companiesShow)&&<li className='drodown-menu'>
                                <div className='element'  onClick={this.toggleSettings}>
                                    <i className="flaticon2-gear"></i>
                                    <span>Настройки</span>
                                </div>
                         
                                <ul className={settingsMenu?'active':''}> 
                                    {permissions.brandsShow&&<li>
                                        <NavLink activeClassName='active'  to="/dashboard/brands" onClick={this.showMenu}>
                                            <i className="fas fa-copyright"></i>
                                            <span>Марки</span>
                                        </NavLink>
                                    </li>}
                                    {permissions.companiesShow&&<li>
                                        <NavLink activeClassName='active'  to="/dashboard/companies" onClick={this.showMenu}>
                                            <i className="fas fa-city"></i>
                                            <span>Компании</span>
                                        </NavLink>
                                    </li>}
                                </ul>
                            </li>}  
                            {permissions.logActivityShow&&<li>
                                <NavLink activeClassName='active'  to="/dashboard/history" onClick={this.showMenu}>
                                    <i className="flaticon2-cube-1"></i>
                                    <span>Лог</span>
                                </NavLink>
                            </li>}
                         
                           
                            <li className="show-on-mobile">
                                <a onClick = {this.onClick}> 
                                    <i className='flaticon-logout'></i>
                                    <span>Изход</span> 
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </React.Fragment>
        ); 
    }
}

export default connect(null, {logout})(SideBar);  