import React, { Component } from 'react';
import {connect} from 'react-redux'
import Grid from '@material-ui/core/Grid'; 
import TextField from '@material-ui/core/TextField'; 
 
import {salesCarsNames} from '../../../helpers/formsPermissions'
import {updateSingleSaleCarsState} from '../../../_actions/carsSaleActions'

class Tehnical extends Component {
    
    state={
        data:this.props.data ? this.props.data :{},
        roleId: JSON.parse(localStorage.getItem('roleId'))
    }  

    onChange = (event, index) =>{ 
        const {name,value} = event.target;  
        this.setState({
            data:{
                ...this.state.data,
                [name]:value
            }
        })   
    }
   
    componentWillUnmount(){
        this.props.updateSingleSaleCarsState('technical', this.state.data)
    }

    componentDidUpdate(previousProps, previousState){ 
        if(previousProps.data !== this.props.data){
            this.setState({
                data:this.props.data
            })
        }

        if(previousProps.pushToState !== this.props.pushToState){
            if (this.props.pushToState) {
                this.props.updateSingleSaleCarsState('technical', this.state.data)
            } 
        }
    } 


   

    render() {  
        const {data, roleId} = this.state; 
        const {formUserPermissions={},errors={}}=this.props; 
        const {technical={}} = errors
        return (  
            
            <>    
                <Grid container spacing={3}> 
                    {Object.keys(data).map((key, i) => {   
                        if (formUserPermissions['technical.'+key]) { 
                            if (key=='luggageCapacity') {
                                return(
                                    <Grid item md={3} xs={12} key={i}>  
                                        <TextField inputProps={{'className':'data-hj-allow'}} className="full-width mb-20" 
                                            InputLabelProps={{shrink: true}} 
                                            disabled
                                            margin="normal" 
                                            variant="outlined"   
                                            label={salesCarsNames['technical.'+key]}
                                            name={key}
                                            value={data[key]}
                                            error ={technical[key]?true:false} 
                                            helperText={technical[key]} 
                                            onChange={(e)=>this.onChange(e, i)}  
                                        />
                                    </Grid>   
                                ) 
                            }else{
                                return(
                                    <Grid item md={3} xs={12} key={i}>  
                                        <TextField inputProps={{'className':'data-hj-allow'}} className="full-width mb-20" 
                                            InputLabelProps={{shrink: true}} 
                                            disabled 
                                            margin="normal" 
                                            variant="outlined"   
                                            label={salesCarsNames['technical.'+key]}
                                            name={key}
                                            value={data[key]}
                                            error ={technical[key]?true:false} 
                                            helperText={technical[key]} 
                                            onChange={(e)=>this.onChange(e, i)}  
                                        />
                                    </Grid>   
                                ) 
                            }
                        } 
                    })} 
                </Grid>  
            </>
         );
    }
}

const mapStateToProps = (state, props) => {  
    return{ 
        formUserPermissions:state.roles.formUserPermissions,  
    } 
}
export default connect(mapStateToProps, {updateSingleSaleCarsState })(Tehnical);
 
 

 