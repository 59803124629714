import {combineReducers} from 'redux';
import authReducer from './authReducer';
import usersReducer from './usersReducer';
import carsReducer from './carsReducer';
import carsSaleReducer from './carsSaleReducer.';
import clientsReducer from './clientsReducer';
import driveListReducer from './driveListReducer';
import companiesReducer from './companiesReducer';
import historyReducer from './historyReducer'
import contactsReducer from './contactsReducer';
import offersReducer from './offersReducer';
import brandsReducer from './brandsReducer';
import modelsReducer from './modelsReducer';
import agreementsReducer from './agreementsReducer';
import ordersReducer from './ordersReducer';
import rolesReducer from './rolesReducer';

import msgActions from './msgReducer';

export default combineReducers({
    auth:authReducer,
    users:usersReducer,
    cars:carsReducer,
    carsSale:carsSaleReducer,
    clients:clientsReducer,
    driveList:driveListReducer,
    message:msgActions,
    companies:companiesReducer,
    history:historyReducer,
    contacts:contactsReducer,
    offers:offersReducer,
    brands:brandsReducer,
    models:modelsReducer,
    agreements:agreementsReducer,
    orders:ordersReducer,
    roles:rolesReducer,
})