import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import {connect} from 'react-redux'

import Grid from '@material-ui/core/Grid';

import {fetchRelatedContactsClients} from '../../../_actions/clientsActions'

class ClientContacts extends Component {
    state = { 
         
     }

    componentDidMount(){    
        if (this.props.match.params._id) {
            this.props.fetchRelatedContactsClients(this.props.match.params._id);
        }
    }

 
    openClientEntry=(id)=>{
        this.props.history.push("/dashboard/contacts/"+id);
    }

    createEntry=()=>{ 
        this.props.history.push("/dashboard/create-contact?client_Id="+this.props.match.params._id);
    }
    
    
    render() { 
        const{relatedContactsClients}=this.props;

        return (  
            <>
                <Grid container className="section-title-wrapper padding"> 
                    <Grid container item xs={8} alignItems="center"> 
                        <h3 className="section-title">
                            Контакти към клиента:
                        </h3>
                    </Grid>  
                </Grid>  
                <Grid container spacing={3}> 
                    <Grid item md={12} xs={12}>  
                        <table className="regular-table">
                                <thead>                         
                                <tr>
                                    <th>ID:</th>
                                    <th>Име:</th>
                                    <th>ЕГН:</th>
                                    <th>Телефон:</th>
                                    <th>E-mail:</th>
                                    <th>Търговец:</th> 
                                    <th>Представител на фирма:</th>
                                    <th>Лк/номер:</th>
                                    <th>Лк/Дата на издаване:</th>
                                    <th>Кинжка номер:</th>
                                </tr>
                            </thead>    
                            <tbody>
                                {relatedContactsClients.map( item =>{
                                    return(
                                        <tr onClick={()=>this.openClientEntry(item.id)} key={item.id}>
                                            <td>{item.id}</td>
                                            <td>{item.name}</td> 
                                            <td>{item.egn}</td> 
                                            <td>{item.phone}</td> 
                                            <td>{item.email}</td>  
                                            <td>{item.createdBy}</td>   
                                            <td>{item.clientCompany}</td>  
                                            <td>{item.idCardNumber}</td>  
                                            <td>{item.idCardDate}</td>  
                                            <td>{item.drivingLicense}</td>  
                                        </tr> 
                                    )
                                })}   
                               {relatedContactsClients.length <1&& 
                                    <tr>
                                        <td colspan="10"><h3>Няма резултати</h3></td> 
                                    </tr>  
                                }
                            </tbody> 
                        </table>       
                    </Grid>    
                  
                </Grid>   
             
                {/* <Grid container spacing={3}> 
                    <Grid container item md={4} xs={12} >    
                        <div className="btn btn-primary full-width" onClick={()=>{this.createEntry()}}>
                            <i className="flaticon2-add-square"></i>Създай контакт за този клиент 
                        </div>   
                    </Grid>
                </Grid>  */}
            </>
        );
    }
}

const mapStateToProps = (state, props) => {   
    return{ 
        relatedContactsClients:state.clients.relatedContactsClients,  
    } 
}
export default withRouter(connect(mapStateToProps, {fetchRelatedContactsClients })(ClientContacts)); 
 