const initialState = {
    items:[]
}

export default function (state = initialState, action){  
    const {type, payload} = action; 
    switch(type){
        case 'FETCH_CARS':  
            return{
                ...state,
                items:payload 
            }
        case 'FETCH_FIXICARS':  
            return{
                ...state,
                fixiCars:payload 
            }
        case 'CREATE_CAR':
            return { 
                ...state,
                items: [...state.items, payload]
            } 
        case 'EDIT_CAR': 
            return{ ...state, 
                items:state.items.map(car => ( car.id === payload.id ? payload : car ))
            }  
        default:
            return state;
    }
}