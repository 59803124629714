export const salesCars = {
    "vehicle.model": { value: false, id: 1 },
    "vehicle.brand": { value: false, id: 2 },
    "vehicle.engineType": { value: false, id: 3 },
    "vehicle.dlvrd": { value: false, id: 4 },
    "vehicle.ordRequest": { value: false, id: 5 },
    "vehicle.mtyOne": { value: false, id: 6 },
    "vehicle.mtyTwo": { value: false, id: 7 },
    "vehicle.vin": { value: false, id: 8 },
    "vehicle.notDlvrd": { value: false, id: 9 },
    "vehicle.equipmentCode": { value: false, id: 10 },
    "vehicle.monthDelivery": { value: false, id: 11 },
    "vehicle.order": { value: false, id: 12 },
    "vehicle.externalColor": { value: false, id: 13 },
    "vehicle.externalColorDescription": { value: false, id: 14 },
    "vehicle.vehicleStayInStock": { value: false, id: 15 },
    "vehicle.idEmotive": { value: false, id: 16 },
    "vehicle.factoryGrade": { value: false, id: 17 },
    "vehicle.comeInSofia": { value: false, id: 18 },
    "vehicle.commercialGrade": { value: false, id: 19 },
    "vehicle.internalColor": { value: false, id: 20 },
    "vehicle.location": { value: false, id: 21 },
    "vehicle.body": { value: false, id: 22 },
    "vehicle.bodyType": { value: false, id: 23 },
    "vehicle.optionalEquipment": { value: false, id: 24 },
    "vehicle.statusName": { value: false, id: 25 },
    "vehicle.typeName": { value: false, id: 26 },
    "vehicle.originalStatus": { value: false, id: 27 },
    "vehicle.mixedName": { value: false, id: 28 },
    "technical.engine": { value: false, id: 29 },
    "technical.drivingWheels": { value: false, id: 30 },
    "technical.hp": { value: false, id: 31 },
    "technical.kw": { value: false, id: 32 },
    "technical.transmission": { value: false, id: 33 },
    "technical.displacement": { value: false, id: 34 },
    "technical.cylinders": { value: false, id: 35 },
    "technical.seats": { value: false, id: 36 },
    "technical.lenght": { value: false, id: 37 },
    "technical.width": { value: false, id: 38 },
    "technical.height": { value: false, id: 39 },
    "technical.liveWeight": { value: false, id: 40 },
    "technical.wheelBase": { value: false, id: 41 },
    "technical.maxPowerRpm": { value: false, id: 42 },
    "technical.maxTorqueRpm": { value: false, id: 43 },
    "technical.maxTorqueNm": { value: false, id: 44 },
    "technical.acceleration": { value: false, id: 45 },
    "technical.fuelConsumption": { value: false, id: 46 },
    "technical.tankCapacity": { value: false, id: 47 },
    "technical.emission": { value: false, id: 48 },
    "technical.euStandard": { value: false, id: 49 },
    "technical.luggageCapacity": { value: false, id: 50 },
    "technical.maxSpeed": { value: false, id: 51 },
    "technical.fuelType": { value: false, id: 52 },
    "technical.maxPower": { value: false, id: 53 },
    "technical.maxTorgue": { value: false, id: 54 },
    "customer.client": { value: false, id: 55 },
    "customer.email": { value: false, id: 56 },
    "customer.address": { value: false, id: 57 },
    "customer.phone": { value: false, id: 58 },
    "customer.populatedLocation": { value: false, id: 59 },
    "customer.note": { value: false, id: 60 },
    "logistic.priceType": { value: false, id: 61 },
    "logistic.contractNumber": { value: false, id: 62 },
    "logistic.invoiceNumberToClient": { value: false, id: 63 },
    "logistic.invoiceDateToClient": { value: false, id: 64 },
    "logistic.depositForCarOrder": { value: false, id: 65 },
    "logistic.dateOfOrderOfTheCar": { value: false, id: 66 },
    "logistic.numberOfOrderOfTheCar": { value: false, id: 67 },
    "logistic.deliveredToTheCustomer": { value: false, id: 68 },
    "logistic.regNumber": { value: false, id: 69 },
    "logistic.roarAssistantCard": { value: false, id: 70 },
    "logistic.insuranceCompany": { value: false, id: 71 },
    "logistic.customerPayment": { value: false, id: 72 },
    "logistic.dealer": { value: false, id: 73 },
    "logistic.invoiceMagEur": { value: false, id: 74 },
    "logistic.invoiceMagDate": { value: false, id: 75 },
    "logistic.invoiceMagNumber": { value: false, id: 76 },
    "logistic.daysAfterInvoice": { value: false, id: 77 },
    "logistic.deliveryPriceEur": { value: false, id: 78 },
    "logistic.vat": { value: false, id: 79 },
    "logistic.deliveryPriceWithVat": { value: false, id: 80 },
    "logistic.marginByListPriceEur": { value: false, id: 81 },
    "logistic.marginByRetailPriceEur": { value: false, id: 82 },
    "logistic.marginByRetailPriceBgn": { value: false, id: 83 },
    "logistic.marginAfterMaxDiscountBgn": { value: false, id: 84 },
    "logistic.marginAfterMaxDiscount": { value: false, id: 85 },
    "logistic.invoicePriceToDealerWithVatBgn": { value: false, id: 86 },
    "logistic.dealerCommissionWithVatBgn": { value: false, id: 87 },
    "logistic.dealerBonusWithVatBgn": { value: false, id: 88 },
    "logistic.dealerBonusEur": { value: false, id: 89 },
    "logistic.promotionBgn": { value: false, id: 90 },
    "logistic.paidAdvice": { value: false, id: 91 },
    "logistic.creditNoteMagDate": { value: false, id: 92 },
    "logistic.stayInStockSoldCars": { value: false, id: 93 },
    "logistic.keyCode": { value: false, id: 94 },
    "logistic.typeName": { value: false, id: 95 },
    "administration.responsibleSalesman": { value: false, id: 96 },
    "administration.allInclusiveCard": { value: false, id: 97 },
    "administration.dateSosRequires": { value: false, id: 98 },
    "administration.registrationTypeNote": { value: false, id: 99 },
    "administration.salesPriceContractWithVat": { value: false, id: 100 },
    "administration.maxDiscount": { value: false, id: 101 },
    "administration.maxDiscountWithVat": { value: false, id: 102 },
    "administration.maxPriceAfterDiscountWithVat": { value: false, id: 103 },
    "administration.note": { value: false, id: 104 },
    "administration.pricePerPriceListWithVat": { value: false, id: 105 },
    "administration.guarantee": { value: false, id: 106 },
    "administration.editedAt": { value: false, id: 107 },
    "administration.guaranteeType": { value: false, id: 108 },
    "price.priceB2c": { value: false, id: 109 },
    "price.bottomPrice": { value: false, id: 110 },
    "price.pricePartner": { value: false, id: 111 },
    "price.priceB2b": { value: false, id: 112 },
    "price.listPrice": { value: false, id: 113 },
    "price.promotion": { value: false, id: 114 },
    "price.giftsForClient": { value: false, id: 115 },
    "price.discountBgn": { value: false, id: 116 },
    "price.discountWithVatBgn": { value: false, id: 117 },
    "price.personalBonusForTrader": { value: false, id: 118 },
    optionalEquipment: { value: false, id: 119 },
    standardEquipment: { value: false, id: 120 },
};

export const salesCarsNames = {
    "vehicle.id": "ID",
    "vehicle.brand": "Марка",
    "vehicle.model": "Модел",
    "vehicle.modelId": "modelId",
    "vehicle.brandId": "brandId",
    "vehicle.engineType": "engineType",
    "vehicle.dlvrd": "DLVRD cur. Year",
    "vehicle.ordRequest": "Ord. request",
    "vehicle.mtyOne": "Factory Code Model / Engine MTY1",
    "vehicle.mtyTwo": "Factory Code Model / Engine MTY2",
    "vehicle.vin": "VIN",
    "vehicle.notDlvrd": "NOT DLVRD",
    "vehicle.equipmentCode": "equipmentCode",
    "vehicle.monthDelivery": "Месец за доставка в Антверпен, Белгия",
    "vehicle.order": "order",
    "vehicle.externalColor": "External color code",
    "vehicle.externalColorDescription": "External color name",
    "vehicle.vehicleStayInStock": "Vehicle stay in SM stock",
    "vehicle.idEmotive": "idEmotive",
    "vehicle.inventoryNumber": "Инв. Номер",
    "vehicle.factoryGrade": "Factory Grade",
    "vehicle.comeInSofia": "Пристигнал в София",
    "vehicle.commercialGrade": "Comercial Grade",
    "vehicle.internalColor": "Internal color",
    "vehicle.location": "Location",
    "vehicle.body": "body",
    "vehicle.bodyType": "bodyType",
    "vehicle.optionalEquipment": "optionalEquipment",
    "vehicle.status": "status",
    "vehicle.type": "type",
    "vehicle.originalStatus": "оriginal status",
    "vehicle.statusName": "Status Name",
    "vehicle.typeName": "Type Name",
    "vehicle.mixedName": "mixedName",
    "vehicle.infocarcode": "infocarcode ",
    "vehicle.groupCode": "groupCode",
    "vehicle.statusFinishOn": "statusFinishOn",
    "technical.salesCarId": "salesCarId",
    "technical.engine": "engine",
    "technical.drivingWheels": "drivingWheels",
    "technical.hp": "Максимална мощност",
    "technical.kw": "Мощност",
    "technical.transmission": "Скоростна кутия",
    "technical.displacement": "Работен обем",
    "technical.cylinders": "цилиндри",
    "technical.seats": "брой седалки",
    "technical.lenght": "дължина",
    "technical.width": "ширина",
    "technical.height": "височина",
    "technical.liveWeight": "живо тегло/полезен товар",
    "technical.wheelBase": "колесна база",
    "technical.maxPower": "Максимална Мощност (Документ)",
    "technical.maxPowerRpm": "Максимална Мощност",
    "technical.maxTorgue": "Максимален въртящ момент (Документ)",
    "technical.numberValves": "Брой клапани",
    "technical.maxTorqueRpm": "макс. въртящ момент",
    "technical.maxTorqueNm": "макс. въртящ момент",
    "technical.acceleration": "ускорение",
    "technical.fuelConsumption": "Комбиниран разход",
    "technical.tankCapacity": "Обем на резервоара (Л.)",
    "technical.emission": "emission/Ниво на вредни емисии CO2 (комбиниран цикъл)",
    "technical.euStandard": "Екологична норма",
    "technical.luggageCapacity": "Обем на багажника, литри",
    "technical.maxSpeed": "максимална скорост",
    "technical.fuelType": "тип гориво",
    "customer.salesCarId": "salesCarId",
    "customer.client": "Клиент",
    "customer.email": "E-mail",
    "customer.address": "Населено място",
    "customer.phone": "Телефон за контакт",
    "customer.populatedLocation": "Адрес",
    "customer.note": "Забележка относно типа на работа",
    "logistic.typeName": "Type Name",
    "logistic.mileage": "Пробег",
    "logistic.salesCarId": "salesCarId",
    "logistic.priceType": "Начин на плащане/ лизингова компания",
    "logistic.contractNumber": "Contract",
    "logistic.invoiceNumberToClient": "Фактура към клиента",
    "logistic.invoiceDateToClient": "Дата на фактура към клиента",
    "logistic.depositForCarOrder": "Капаро за поръчка на автомобил",
    "logistic.dateOfOrderOfTheCar": "Дата на поръчка на автомобила",
    "logistic.numberOfOrderOfTheCar": "Номер на заявка на автомобила",
    "logistic.deliveredToTheCustomer": "Delivered to the customer",
    "logistic.regNumber": "Reg. Number",
    "logistic.roarAssistantCard": "Road assistant card",
    "logistic.insuranceCompany": "Застрахователна компания",
    "logistic.customerPayment": "Customer payment",
    "logistic.dealer": "Dealer",
    "logistic.invoiceMagEur": "Доставна фактура EUR",
    "logistic.invoiceMagDate": "Дата на Доставна фактура",
    "logistic.invoiceMagNumber": "Номер на Доставна фактура",
    "logistic.daysAfterInvoice": "Дни след фактура",
    "logistic.deliveryPriceEur": "deliveryPriceEur",
    "logistic.vat": "ДДС - 20%",
    "logistic.deliveryPriceWithVat": "Доставна цена с ДДС лв",
    "logistic.marginByListPriceEur": "Margin by list price w/o VAT EUR in %",
    "logistic.marginByRetailPriceEur": "Margin by retail price w/o VAT EUR in %",
    "logistic.marginByRetailPriceBgn": "Margin by retail price w/o VAT BGN ",
    "logistic.marginAfterMaxDiscountBgn": "Марж след max отстъпка (без ддс) BGN",
    "logistic.marginAfterMaxDiscount": "Марж след max отстъпка %",
    "logistic.invoicePriceToDealerWithVatBgn": "Фактура стойност към дилъра с ДДС BGN",
    "logistic.dealerCommissionWithVatBgn": "Дилърска комисионна с ДДС BGN",
    "logistic.dealerBonusWithVatBgn": "Dealer bonus w/o VAT BGN",
    "logistic.dealerBonusEur": "Dealer bonus with VAT EUR",
    "logistic.promotionBgn": "Promotion w/o VAT",
    "logistic.paidAdvice": "Paid advance",
    "logistic.creditNoteMagDate": "Дата на отчитане към MAG",
    "logistic.stayInStockSoldCars": "Stay in stock-sold cars days",
    "logistic.keyCode": "Кey code",
    "logistic.type": "type",
    "administration.salesCarId": "salesCarId",
    "administration.responsibleSalesman": "Responsible salesman",
    "administration.allInclusiveCard": "All inclusive card/CAR CORNER",
    "administration.dateSosRequires": "Дата на заявка СОС",
    "administration.registrationTypeNote": "registrationTypeNote",
    "administration.salesPriceContractWithVat": "Продажна цена с ДДС",
    "administration.maxDiscount": "Mаксимална отстъпка",
    "administration.maxDiscountWithVat": "Mаксимално възможна отстъпка с ддс ",
    "administration.maxPriceAfterDiscountWithVat": "Mаксимална цена след отстъпка с ддс ",
    "administration.note": "Забележка",
    "administration.pricePerPriceListWithVat": "pricePerPriceListWithVat",
    "administration.guarantee": "Гаранция",
    "administration.editedAt": "editedAt",
    "administration.guaranteeType": "Тип гаранция",
    "price.salesCarId": "salesCarId",
    "price.priceB2c": "Препоръчителна цена към клиент",
    "price.bottomPrice": "Минимална цена",
    "price.pricePartner": "Цена за дилър",
    "price.priceB2b": "Цена за собствен склад",
    "price.listPrice": "Цена по ценова листа",
    "price.promotion": "Промоционална цена",
    "price.giftsForClient": "Подаръци към клиента с ДДС, доставна цена",
    "price.discountBgn": "Oтстъпка",
    "price.discountWithVatBgn": "Oтстъпка с ДДС",
    "price.personalBonusForTrader": "Персонален бонус за търговеца",
    "price.bonusConditions": "Бонусни Условия",
    optionalEquipment: "optionalEquipment",
    standardEquipment: "standardEquipment",
};

export const logisticPosition = {
    "logistic.priceType": 16,
    "logistic.contractNumber": 17,
    "logistic.invoiceNumberToClient": 18,
    "logistic.invoiceDateToClient": 19,
    "logistic.depositForCarOrder": 20,
    "logistic.dateOfOrderOfTheCar": 21,
    "logistic.numberOfOrderOfTheCar": 22,
    "logistic.deliveredToTheCustomer": 23,
    "logistic.regNumber": 24,
    "logistic.roarAssistantCard": 25,
    "logistic.insuranceCompany": 26,
    "logistic.customerPayment": 27,
    "logistic.dealer": 1,
    "logistic.invoiceMagEur": 2,
    "logistic.invoiceMagDate": 3,
    "logistic.invoiceMagNumber": 4,
    "logistic.daysAfterInvoice": 5,
    "logistic.deliveryPriceEur": 6,
    "logistic.vat": 7,
    "logistic.deliveryPriceWithVat": 8,
    "logistic.marginByListPriceEur": 9,
    "logistic.marginByRetailPriceEur": 10,
    "logistic.marginByRetailPriceBgn": 11,
    "logistic.marginAfterMaxDiscountBgn": 12,
    "logistic.marginAfterMaxDiscount": 13,
    "logistic.invoicePriceToDealerWithVatBgn": 28,
    "logistic.dealerCommissionWithVatBgn": 29,
    "logistic.dealerBonusWithVatBgn": 30,
    "logistic.dealerBonusEur": 31,
    "logistic.promotionBgn": 32,
    "logistic.paidAdvice": 33,
    "logistic.creditNoteMagDate": 34,
    "logistic.stayInStockSoldCars": 35,
    "logistic.keyCode": 14,
};

export const saleCarsObjNames = (i) => {
    let typeText = "";
    let statusText = "";
    const {
        status,
        type,
        brand,
        model,
        inventoryNumber,
        vin,
        engineType,
        externalColorDescription,
        commercialGrade,
        modelId,
        brandId,
        dlvrd,
        ordRequest,
        mtyOne,
        mtyTwo,
        notDlvrd,
        equipmentCode,
        monthDelivery,
        order,
        externalColor,
        vehicleStayInStock,
        idEmotive,
        factoryGrade,
        comeInSofia,
        internalColor,
        location,
        body,
        bodyType,
        optionalEquipment: vehicle_optionalEquipment,
        originalStatus,
        mixedName,
        statusName,
        typeName,
    } = i.vehicle || {};
    const { name: companyName } = i.company || {};
    const {
        engine,
        kw,
        fuelType,
        transmission,
        salesCarId,
        drivingWheels,
        hp,
        displacement,
        cylinders,
        seats,
        lenght,
        width,
        height,
        liveWeight,
        wheelBase,
        maxPower,
        maxPowerRpm,
        maxTorgue,
        maxTorqueRpm,
        maxTorqueNm,
        acceleration,
        fuelConsumption,
        tankCapacity,
        emission,
        euStandard,
        luggageCapacity,
        numberValves,
        maxSpeed,
    } = i.technical || {};
    const {
        keyCode,
        typeName: logistic_typeName,
        salesCarId: logistic_salesCarId,
        priceType,
        contractNumber,
        invoiceNumberToClient,
        invoiceDateToClient,
        depositForCarOrder,
        dateOfOrderOfTheCar,
        numberOfOrderOfTheCar,
        deliveredToTheCustomer,
        regNumber,
        roarAssistantCard,
        insuranceCompany,
        customerPayment,
        dealer,
        invoiceMagEur,
        invoiceMagDate,
        invoiceMagNumber,
        daysAfterInvoice,
        deliveryPriceEur,
        vat,
        deliveryPriceWithVat,
        marginByListPriceEur,
        marginByRetailPriceEur,
        marginByRetailPriceBgn,
        marginAfterMaxDiscountBgn,
        marginAfterMaxDiscount,
        invoicePriceToDealerWithVatBgn,
        dealerCommissionWithVatBgn,
        dealerBonusWithVatBgn,
        dealerBonusEur,
        promotionBgn,
        paidAdvice,
        creditNoteMagDate,
        stayInStockSoldCars,
        type: logistic_type,
        mileage,
    } = i.logistic || {};
    const {
        salesCarId: administration_salesCarId,
        responsibleSalesman,
        allInclusiveCard,
        dateSosRequires,
        registrationTypeNote,
        salesPriceContractWithVat,
        maxDiscount,
        maxDiscountWithVat,
        maxPriceAfterDiscountWithVat,
        note: administration_note,
        pricePerPriceListWithVat,
        guarantee,
        editedAt,
        guaranteeType,
    } = i.administration || {};
    const {
        listPrice,
        salesCarId: price_salesCarId,
        priceB2c,
        bottomPrice,
        pricePartner,
        priceB2b,
        promotion,
        giftsForClient,
        discountBgn,
        discountWithVatBgn,
        personalBonusForTrader,
        optionalEquipment,
        standardEquipment,
    } = i.price || {};
    const {
        salesCarId: customer_salesCarId,
        client,
        email,
        address,
        phone,
        populatedLocation,
        note,
    } = i.customer || {};

    if (status == 1) {
        statusText = "Свободен";
    } else if (status == 2) {
        statusText = "Резервиран";
    } else if (status == 3) {
        statusText = "Капариран";
    } else if (status == 4) {
        statusText = "Промоционален";
    } else if (status == 5) {
        statusText = "Резервиран Промоционален";
    } else if (status == 6) {
        statusText = "Поръчан";
    } else if (status == 7) {
        statusText = "Продаден";
    }

    if (type == 1) {
        typeText = "Наличен";
    } else if (type == 2) {
        typeText = "Не наличен (На път)";
    }

    return {
        id: i.id,
        type: typeText,
        status: statusText,
        brand: brand,
        model: model,
        inventoryNumber: inventoryNumber,
        vin: vin,
        companyName: companyName,
        engine: engine,
        engineType: engineType,
        externalColorDescription: externalColorDescription,
        commercialGrade: commercialGrade,
        keyCode: keyCode,
        kw: kw,
        listPrice: listPrice,
        fuelType: fuelType,
        transmission: transmission,
        modelId: modelId,
        brandId: brandId,
        dlvrd: dlvrd,
        ordRequest: ordRequest,
        mtyOne: mtyOne,
        mtyTwo: mtyTwo,
        notDlvrd: notDlvrd,
        equipmentCode: equipmentCode,
        monthDelivery: monthDelivery,
        order: order,
        externalColor: externalColor,
        vehicleStayInStock: vehicleStayInStock,
        idEmotive: idEmotive,
        factoryGrade: factoryGrade,
        comeInSofia: comeInSofia,
        internalColor: internalColor,
        location: location,
        body: body,
        bodyType: bodyType,
        vehicle_optionalEquipment: vehicle_optionalEquipment,
        originalStatus: originalStatus,
        mixedName: mixedName,
        statusName: statusName,
        typeName: typeName,
        technical_salesCarId: salesCarId,
        drivingWheels: drivingWheels,
        hp: hp,
        displacement: displacement,
        cylinders: cylinders,
        seats: seats,
        lenght: lenght,
        width: width,
        height: height,
        liveWeight: liveWeight,
        wheelBase: wheelBase,
        maxPower: maxPower,
        maxPowerRpm: maxPowerRpm,
        maxTorgue: maxTorgue,
        maxTorqueRpm: maxTorqueRpm,
        maxTorqueNm: maxTorqueNm,
        acceleration: acceleration,
        fuelConsumption: fuelConsumption,
        tankCapacity: tankCapacity,
        emission: emission,
        euStandard: euStandard,
        luggageCapacity: luggageCapacity,
        numberValves: numberValves,
        maxSpeed: maxSpeed,
        customer_salesCarId: salesCarId,
        client: client,
        email: email,
        address: address,
        phone: phone,
        populatedLocation: populatedLocation,
        customer_note: note,
        logistic_typeName: logistic_typeName,
        logistic_salesCarId: logistic_salesCarId,
        priceType: priceType,
        contractNumber: contractNumber,
        invoiceNumberToClient: invoiceNumberToClient,
        invoiceDateToClient: invoiceDateToClient,
        depositForCarOrder: depositForCarOrder,
        dateOfOrderOfTheCar: dateOfOrderOfTheCar,
        numberOfOrderOfTheCar: numberOfOrderOfTheCar,
        deliveredToTheCustomer: deliveredToTheCustomer,
        regNumber: regNumber,
        roarAssistantCard: roarAssistantCard,
        insuranceCompany: insuranceCompany,
        customerPayment: customerPayment,
        dealer: dealer,
        invoiceMagEur: invoiceMagEur,
        invoiceMagDate: invoiceMagDate,
        invoiceMagNumber: invoiceMagNumber,
        daysAfterInvoice: daysAfterInvoice,
        deliveryPriceEur: deliveryPriceEur,
        vat: vat,
        deliveryPriceWithVat: deliveryPriceWithVat,
        marginByListPriceEur: marginByListPriceEur,
        marginByRetailPriceEur: marginByRetailPriceEur,
        marginByRetailPriceBgn: marginByRetailPriceBgn,
        marginAfterMaxDiscountBgn: marginAfterMaxDiscountBgn,
        marginAfterMaxDiscount: marginAfterMaxDiscount,
        invoicePriceToDealerWithVatBgn: invoicePriceToDealerWithVatBgn,
        dealerCommissionWithVatBgn: dealerCommissionWithVatBgn,
        dealerBonusWithVatBgn: dealerBonusWithVatBgn,
        dealerBonusEur: dealerBonusEur,
        promotionBgn: promotionBgn,
        paidAdvice: paidAdvice,
        creditNoteMagDate: creditNoteMagDate,
        stayInStockSoldCars: stayInStockSoldCars,
        logistic_type: logistic_type,
        mileage: mileage,
        administration_salesCarId: administration_salesCarId,
        responsibleSalesman: responsibleSalesman,
        allInclusiveCard: allInclusiveCard,
        dateSosRequires: dateSosRequires,
        registrationTypeNote: registrationTypeNote,
        salesPriceContractWithVat: salesPriceContractWithVat,
        maxDiscount: maxDiscount,
        maxDiscountWithVat: maxDiscountWithVat,
        maxPriceAfterDiscountWithVat: maxPriceAfterDiscountWithVat,
        administration_note: administration_note,
        pricePerPriceListWithVat: pricePerPriceListWithVat,
        guarantee: guarantee,
        editedAt: editedAt,
        guaranteeType: guaranteeType,
        price_salesCarId: price_salesCarId,
        priceB2c: priceB2c,
        bottomPrice: bottomPrice,
        pricePartner: pricePartner,
        priceB2b: priceB2b,
        promotion: promotion,
        giftsForClient: giftsForClient,
        discountBgn: discountBgn,
        discountWithVatBgn: discountWithVatBgn,
        personalBonusForTrader: personalBonusForTrader,
        price_optionalEquipment: optionalEquipment,
        standardEquipment: standardEquipment,
    };
};

export const saleCarsObjNamesForOffer = (i) => {
    let typeText = "";
    let statusText = "";
    if (i.status == 1) {
        statusText = "Свободен";
    } else if (i.status == 2) {
        statusText = "Резервиран";
    } else if (i.status == 3) {
        statusText = "Капариран";
    } else if (i.status == 4) {
        statusText = "Промоционален";
    } else if (i.status == 5) {
        statusText = "Резервиран Промоционален";
    } else if (i.status == 6) {
        statusText = "Поръчан";
    } else if (i.status == 7) {
        statusText = "Продаден";
    }

    if (i.type == 1) {
        typeText = "Наличен";
    } else if (i.type == 2) {
        typeText = "Не наличен (На път)";
    }

    return {
        id: i.id,
        type: typeText,
        status: statusText,
        brand: i.brand,
        model: i.model,
        inventoryNumber: i.inventoryNumber,
        vin: i.vin,
        engine: i.engine,
        engineType: i.engineType,
        externalColorDescription: i.externalColorDescription,
        commercialGrade: i.commercialGrade,
        // keyCode:i.keyCode,
        kw: i.kw,
        listPrice: i.listPrice,
        fuelType: i.fuelType,
        transmission: i.transmission,

        modelId: i.modelId,
        brandId: i.brandId,
        dlvrd: i.dlvrd,
        ordRequest: i.ordRequest,
        mtyOne: i.mtyOne,
        mtyTwo: i.mtyTwo,
        notDlvrd: i.notDlvrd,
        equipmentCode: i.equipmentCode,
        monthDelivery: i.monthDelivery,
        order: i.order,
        externalColor: i.externalColor,
        tayInStock: i.tayInStock,
        idEmotive: i.idEmotive,
        factoryGrade: i.factoryGrade,
        comeInSofia: i.comeInSofia,
        internalColor: i.internalColor,
        location: i.location,
        body: i.body,
        bodyType: i.bodyType,
        // optionalEquipment:i.optionalEquipment,
        originalStatus: i.originalStatus,
        mixedName: i.mixedName,
        // statusName:i.statusName,
        // typeName:i.typeName,
        // salesCarId:i.salesCarId,
        drivingWheels: i.drivingWheels,
        hp: i.hp,
        displacement: i.displacement,
        cylinders: i.cylinders,
        seats: i.seats,
        lenght: i.lenght,
        width: i.width,
        height: i.height,
        liveWeight: i.liveWeight,
        wheelBase: i.wheelBase,
        maxPower: i.maxPower,
        maxPowerRpm: i.maxPowerRpm,
        maxTorgue: i.maxTorgue,
        maxTorqueRpm: i.maxTorqueRpm,
        maxTorqueNm: i.maxTorqueNm,
        acceleration: i.acceleration,
        fuelConsumption: i.fuelConsumption,
        tankCapacity: i.tankCapacity,
        emission: i.emission,
        euStandard: i.euStandard,
        luggageCapacity: i.luggageCapacity,
        maxSpeed: i.maxSpeed,
        // customer_salesCarId:i.customer.salesCarId,
        // client:i.customer.client,
        // email:i.customer.email,
        // address:i.customer.address,
        // phone:i.customer.phone,
        // populatedLocation:i.customer.populatedLocation,
        // customer_note:i.customer.note,
        // logistic_typeName:i.typeName,
        // logistic_salesCarId:i.salesCarId,
        // priceType:i.priceType,
        // contractNumber:i.contractNumber,
        // invoiceNumberToClient:i.invoiceNumberToClient,
        // invoiceDateToClient:i.invoiceDateToClient,
        // depositForCarOrder:i.depositForCarOrder,
        // dateOfOrderOfTheCar:i.dateOfOrderOfTheCar,
        // numberOfOrderOfTheCar:i.numberOfOrderOfTheCar,
        // deliveredToTheCustomer:i.deliveredToTheCustomer,
        // regNumber:i.regNumber,
        // roarAssistantCard:i.roarAssistantCard,
        // insuranceCompany:i.insuranceCompany,
        // customerPayment:i.customerPayment,
        // dealer:i.dealer,
        // invoiceMagEur:i.invoiceMagEur,
        // invoiceMagDate:i.invoiceMagDate,
        // invoiceMagNumber:i.invoiceMagNumber,
        // daysAfterInvoice:i.daysAfterInvoice,
        // deliveryPriceEur:i.deliveryPriceEur,
        // vat:i.vat,
        // deliveryPriceWithVat:i.deliveryPriceWithVat,
        // marginByListPriceEur:i.marginByListPriceEur,
        // marginByRetailPriceEur:i.marginByRetailPriceEur,
        // marginByRetailPriceBgn:i.marginByRetailPriceBgn,
        // marginAfterMaxDiscountBgn:i.marginAfterMaxDiscountBgn,
        // marginAfterMaxDiscount:i.marginAfterMaxDiscount,
        // invoicePriceToDealerWithVatBgn:i.invoicePriceToDealerWithVatBgn,
        // dealerCommissionWithVatBgn:i.dealerCommissionWithVatBgn,
        // dealerBonusWithVatBgn:i.dealerBonusWithVatBgn,
        // dealerBonusEur:i.dealerBonusEur,
        // promotionBgn:i.promotionBgn,
        // paidAdvice:i.paidAdvice,
        // creditNoteMagDate:i.creditNoteMagDate,
        // stayInStockSoldCars:i.stayInStockSoldCars,
        // logistic_type:i.type,
        // administration_salesCarId:i.salesCarId,
        // responsibleSalesman:i.responsibleSalesman,
        // allInclusiveCard:i.allInclusiveCard,
        // dateSosRequires:i.dateSosRequires,
        // registrationTypeNote:i.registrationTypeNote,
        // salesPriceContractWithVat:i.salesPriceContractWithVat,
        // maxDiscount:i.maxDiscount,
        // maxDiscountWithVat:i.maxDiscountWithVat,
        // maxPriceAfterDiscountWithVat:i.maxPriceAfterDiscountWithVat,
        // administration_note:i.note,
        // pricePerPriceListWithVat:i.pricePerPriceListWithVat,
        // guarantee:i.guarantee,
        // editedAt:i.editedAt,
        // guaranteeType:i.guaranteeType,
        // price_salesCarId:i.salesCarId,
        priceB2c: i.priceB2c,
        bottomPrice: i.bottomPrice,
        pricePartner: i.pricePartner,
        priceB2b: i.priceB2b,
        promotion: i.promotion,
        giftsForClient: i.giftsForClient,
        discountBgn: i.discountBgn,
        discountWithVatBgn: i.discountWithVatBgn,
        personalBonusForTrader: i.personalBonusForTrader,
        // price_optionalEquipment:i.optionalEquipment,
        // standardEquipment:i.standardEquipment,
    };
};
