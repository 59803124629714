import { apiUrl} from "../helpers/apiUrl";


export const login = (аuth) => dispatch => {  
      
    return fetch(apiUrl+'login', {
        method: 'POST', 
        headers:{
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        },
        body: JSON.stringify(аuth)
     }).then(result => result.json())
    .then(response => {   
        const {status,data} = response;
        if (status === "success") {
            dispatch({
                type:'LOGIN_SUCCESS',
                payload:data
            })
        }else{
            dispatch({
                type:'LOGIN_FAIL',
                payload:data
            })
        }
        return response
    });
     
}
export const logout = () => dispatch => { 
    return fetch(apiUrl+'logout', {
        method: 'GET', 
        headers:{ 
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization':'Bearer '+("; "+document.cookie).split("; accessToken=").pop().split(";").shift()
        },
        body: JSON.stringify()
     }).then(result => {  
        if(result.status =="204"){
            dispatch({
                type:'LOGOUT',
                payload:''
            })
        }  
    }) 
}
export const forceLogout = () => dispatch => {   
    dispatch({
        type:'LOGOUT',
        payload:''
    })  
}
  