import { apiUrl} from "../helpers/apiUrl";
import { setMessage, removeMessage, setErrorMessage, removeErrorMessage } from "./msgActions"; 


export const fetchAgreements = () => dispatch => { 
    return fetch(apiUrl+'contracts', {
        method: 'GET', 
        headers:{ 
            'Content-Type': 'application/json', 
            'Accept': 'application/json',
            'Authorization':'Bearer '+("; "+document.cookie).split("; accessToken=").pop().split(";").shift()
        },
        body: JSON.stringify()
    }).then(result => {
        dispatch(setErrorMessage(result.status));
        dispatch(removeErrorMessage()); 
        return result.json()
    })
    .then(response => {    
        const {status,data} = response;
        if (status === "success") {
            dispatch({
                type:'FETCH_AGREEMENTS',
                payload:data
            })
        } 
        return response
    });  
}

export const fetchSingleAgreement = (id) => dispatch => { 
    return fetch(apiUrl+'contracts/'+id, {
        method: 'GET', 
        headers:{ 
            'Content-Type': 'application/json', 
            'Accept': 'application/json',
            'Authorization':'Bearer '+("; "+document.cookie).split("; accessToken=").pop().split(";").shift()
        },
        body: JSON.stringify()
    }).then(result => {
        dispatch(setErrorMessage(result.status));
        dispatch(removeErrorMessage()); 
        return result.json()
    })
    .then(response => {      
        const {status,data} = response;
        if (status === "success") {
            dispatch({
                type:'FETCH_SINGLE_AGREEMENT',
                payload:data
            })
        } 
        return response
    }); 
}

export const fetchLeasingCompanies = (name, type) => dispatch => {  
    return fetch(apiUrl+'nav-clients?q='+name+'&type=2', {
        method: 'GET', 
        headers:{ 
            'Content-Type': 'application/json', 
            'Accept': 'application/json',
            'Authorization':'Bearer '+("; "+document.cookie).split("; accessToken=").pop().split(";").shift()
        },
        body: JSON.stringify()
    }).then(result => {
        dispatch(setErrorMessage(result.status));
        dispatch(removeErrorMessage()); 
        return result.json()
    })
    .then(response => {      
        const {status,data} = response;
        if (status === "success") { 
            dispatch({
                type:'FETCH_AGREEMENTS_LEASING_COMPANIES',
                payload:data
            })
        } 
        return response
    }); 
}

export const setAgreementInProgress = (obj, data) => dispatch => {   
    dispatch({
        type:'AGREEMENT_IN_PROGRESS',
        payload:{obj, data}
    }) 
}
export const clearAgreementInProgress = () => dispatch => {   
    dispatch({
        type:'CLEAR_AGREEMENT_IN_PROGRESS',
        payload:{}
    }) 
}

export const createAgreement = (contract) => dispatch => {  
    
    return fetch(apiUrl+'contracts', { 
        method: 'POST', 
        headers:{ 
            'Content-Type': 'application/json', 
            'Accept': 'application/json',
            'Authorization':'Bearer '+("; "+document.cookie).split("; accessToken=").pop().split(";").shift()
        },
        body: JSON.stringify(contract)
     }).then(result => {
        dispatch(setErrorMessage(result.status));
        dispatch(removeErrorMessage()); 
        return result.json()
     })
    .then(response => {    
        const {status, data, message} = response; 
        if (status === "success") {
            dispatch(setMessage(message));
            dispatch(removeMessage());
            dispatch({
                type:'CREATE_AGREEMENT',
                payload:data
            })
        } 
        return response
    }); 
 
}

export const editAgreement = (contract, id) => dispatch => {   
    return fetch(apiUrl+'contracts/'+id, {
        method: 'PUT', 
        headers:{ 
            'Content-Type': 'application/json', 
            'Accept': 'application/json',
            'Authorization':'Bearer '+("; "+document.cookie).split("; accessToken=").pop().split(";").shift()
        },
        body: JSON.stringify(contract)
    }).then(result => {
        dispatch(setErrorMessage(result.status));
        dispatch(removeErrorMessage()); 
        return result.json()
    })
    .then(response => {    
        const {status, message} = response; 
        if (status === "success") { 
            dispatch(setMessage(message));
            dispatch(removeMessage());
            dispatch({
                type:'EDIT_AGREEMENT',
                payload:contract
            })
        } 
        return response
    });   
}

export const setContractDocument = (id,val) => dispatch => {    
    let formData = new FormData(); 
    formData.append('file', val );  

    return fetch(apiUrl+'contracts/upload-contract/'+id, {
        method: 'POST', 
        headers:{  
            'Authorization':'Bearer '+("; "+document.cookie).split("; accessToken=").pop().split(";").shift()
        },
        body: formData
    }).then(result => {
        dispatch(setErrorMessage(result.status));
        dispatch(removeErrorMessage()); 
        return result.json()
    })
    .then(response => {     
        const {status, message} = response; 
        if (status === "success") { 
            dispatch(setMessage(message));
            dispatch(removeMessage()); 
        } 
        return response
    });   
}

export const getContractDocument = (id) => dispatch => {     

    return fetch(apiUrl+'contracts/contract-download/'+id, {
        method: 'GET', 
        headers:{  
            'Authorization':'Bearer '+("; "+document.cookie).split("; accessToken=").pop().split(";").shift()
        }, 
    }).then(result => {
        dispatch(setErrorMessage(result.status));
        dispatch(removeErrorMessage()); 
        return result.json()
    })
    .then(response => {     
        return response
    });   
}