import React from 'react'; 
import{Link} from 'react-router-dom'




export const options = {
    sortOrder: {
        name: 'id',
        direction: 'desc'
    },
    confirmFilters: true,  
    customFilterDialogFooter: (currentFilterList, applyNewFilters) => { 
        return (
            <div style={{ marginTop: '40px' }}>
                <button className="btn btn-info small" onClick={()=>applyNewFilters()}>Приложи филтрите</button>
            </div>
        );
    },
    print: false,
    download: true,
    downloadOptions: {
        filename: 'USERS.csv',
        separator: ',',
        filterOptions: {
          useDisplayedColumnsOnly: true,
        } 
    },
    viewColumns: true,
    filter:false, 
    selectableRows: "none", 
    setRowProps: (row) => { 
        if (row[1]==="Не") {
            return {  
                style: {opacity: '0.5'}
            }
        }
    },   
 
    textLabels: {
        body: {
            noMatch: "Няма намерени резултати",
            toolTip: "Сортирай",
            columnHeaderTooltip: column => `Сортирай по ${column.label}`
        },
        pagination: {
            next: "Следваща страница",
            previous: "Предишна страница",
            rowsPerPage: "Брой записи на страница:",
            displayRows: "от",
        },
        toolbar: {
            search: "Търси",
            downloadCsv: "Download CSV",
            print: "Принтирай",
            viewColumns: "Колони",
            filterTable: "Филтри",
        },
        filter: {
            all: "Всички",
            title: "Филтри",
            reset: "Откажи",
        },
        viewColumns: {
            title: "Покажи Колони",
            titleAria: "Покажи/Скрии Колони",
        },
        selectedRows: {
            text: "запис/и са селектирани за изтриване",
            delete: "Изтриване",
            deleteAria: "Изтриите избраните записи",
        },
    }
}; 

export const columns = [ 
    {
        label: "ID:",
        name: "id",
        options: {
            filter: false,
            sort: true, 
           }
    },    
    {
        label: "Видими:",
        name: "active",
        options: {
            filter: true,
            sort: true,
            customBodyRender: (value, tableMeta, updateValue) => {
                if (value===0) {
                    return 'Не'  
                }else{
                    return 'Да'
                }
            }
        }
    },
    {
        label: "Име:",
        name: "name",
        options: {
            filter: false,
            sort: true,
           }
    },
    {
        label: "Е-mail:",
        name: "email",
        options: {
            filter: false,
            sort: true,
           }
    }, 
    {
        label: "Телефон:",
        name: "phone",
        options: {
            filter: false,
            sort: true,
        }
    },
    {
        label: "Фирма:",
        name: "company",
        options: {
            filter: false,
            sort: true,
        }
    },
    // {
    //     label: "ЕГН:",
    //     name: "egn",
    //     options: {
    //         filter: false,
    //         sort: true,
    //     }
    // },
    {
        label: "Роля:",
        name: "roleId", 
        options: {
            filter: false,
            sort: true,
            customBodyRender: (value, tableMeta, updateValue) => {
                if (value===1) {
                    return 'Администратор'
                }else if(value===2){
                    return 'Управител'
                }else if(value===3){
                    return 'Търговец'
                }else if(value===5){
                    return 'Мениджър дилърска мрежа'
                }else if(value===6){
                    return 'Търговски мениджър' 
                }else if(value===7){
                    return 'Логистика'
                } 
            } 
        }
    },
    {
        label: "TestDrive:",
        name: "testDrives",
        options: {
            filter: false,
            sort: false, 
            customBodyRender: (value, tableMeta, updateValue) => {  
                let id = tableMeta.rowData[0];
                if (value>0) { 
                    return <Link to={'/dashboard/drive-list?userId='+id} className='small btn'>{value}</Link>   
                }else{
                    return value
                }
            } 
        }
    },  
    {
        label: "Оферти:",
        name: "offers",
        options: {
            filter: false,
            sort: false, 
            customBodyRender: (value, tableMeta, updateValue) => {  
                let id = tableMeta.rowData[0];
                if (value>0) {
                    return <Link to={'/dashboard/offers?userId='+id} className='small btn'>{value}</Link>
                }else{
                    return value
                } 
            }
        }
    },  
    {
        label: "Договори:",
        name: "contracts",
        options: {
            filter: false,
            sort: false, 
            customBodyRender: (value, tableMeta, updateValue) => {  
                let id = tableMeta.rowData[0];
                if (value>0) {
                    return <Link to={'/dashboard/agreements?userId='+id} className='small btn'>{value}</Link>
                }else{
                    return value
                }   
            }
        }
    },  
    {
        label: "Поръчки:",
        name: "orders",
        options: {
            filter: false,
            sort: false,  
            customBodyRender: (value, tableMeta, updateValue) => {  
                let id = tableMeta.rowData[0];
                if (value>0) {
                    return <Link to={'/dashboard/orders?userId='+id} className='small btn'>{value}</Link>
                }else{
                    return value
                }   
            }
        }
    },
    {
        label: "Редактирай потребител:",
        name: "аdd",
        options: {
            filter: false,
            sort: false,
            customBodyRender: (value, tableMeta, updateValue) => {  
                let id = tableMeta.rowData[0];
                const permissions = JSON.parse(localStorage.getItem('permissions'));
                if (permissions.usersUpdate) {
                    return <Link to={'/dashboard/users/'+id} className='small btn btn-info'><i className='flaticon-edit'></i>Редактирай</Link>
                }else{
                    return 'нямате достъп'
                }  
            }
        }
    },  
];
