export const validator = (fields) => {   
    const errors ={} 
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
 
    Object.keys(fields).map(item=>{   
        if (item=='email') { 
            if(!re.test(String(fields[item]).toLowerCase())){
                errors[item]='Полето не е в правилен формат'
            } 
        } 
        if (fields[item] ===null || fields[item]==='') {  
            errors[item]='Полето е задължително'
        } 
       
    })    
    return errors  
}