import React, { Component } from 'react';
import ReactDOM from "react-dom";
import {Link} from 'react-router-dom';
import {MuiPickersUtilsProvider, KeyboardDatePicker,DateTimePicker } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import "moment/locale/bg";

import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import InputAdornment from '@material-ui/core/InputAdornment';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';

import Grid from '@material-ui/core/Grid';
import Card from "@material-ui/core/Card";  

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox'; 

import Autocomplete from '@material-ui/lab/Autocomplete';

import {connect} from 'react-redux' 

import {createTestDrive} from '../../../_actions/driveListActions'
import {getBookedDates} from '../../../_actions/driveListActions'
import {fetchCarsFiltered} from '../../../_actions/carsActions'
import {fetchClients, fetchSingleClient} from '../../../_actions/clientsActions'
import {fetchSingleContact, fetchContacts} from '../../../_actions/contactsActions'
import {fetchUsers} from '../../../_actions/userActions'
import {fetchCompanies} from '../../../_actions/companiesActions'

import { createFilterOptions } from '@material-ui/lab/Autocomplete';

const filterOptions = createFilterOptions({
    limit: 50, 
});

class CreateTestDrive extends Component {
    state={
        roleId: JSON.parse(localStorage.getItem('roleId')),
        id:'',
        publishingType:1,
        registerNumber:'',
        pricePerDay:'',
        mileagePerDay:'',
        brand:'',
        model:'',
        testDriveCar:'',
        name:'',
        phone:'',
        egn:'',
        clientCompany:'',
        email:'',
        idCardNumber:'',
        idCardPublishedBy:'', 
        idCardDate:'',
        drivingLicense:'',
        drivingLicenseDate:'',
        runTimeStart:'',
        runTimeEnd:'',
        runTime:'',
        runTimeWork:'',
        eventDatе:'',
        startDatetime:'',
        endDatetime:'',
        reason:'',
        trader:localStorage.getItem('name'), 
        teamId:'', 
        testDrives:'',
        errors:[],  
        correctionStatus:0,
        drivingLicenseType:0,
        companyId:localStorage.getItem('companyId'),
        companyName:localStorage.getItem('companyName'),
    }

    componentDidMount (){  
        this.props.fetchCarsFiltered(); 
        this.props.fetchClients(); 
        this.props.fetchContacts();   
        this.props.fetchUsers();   
        this.props.fetchCompanies();   
        this.getTodayDate();

        const query = new URLSearchParams(this.props.location.search);
        const clientId = query.get('clientId')
        if (clientId) {
            this.props.fetchSingleClient(clientId).then(data => {
                if(data.status==="success"){
                    const {testDrives,name,phone,egn,clientCompany,email,idCardNumber,idCardDate,idCardPublishedBy,drivingLicense,drivingLicenseDate,address
                    }= data.data
                    this.setState({
                        testDrives,name,phone,egn,clientCompany,email,idCardNumber,idCardDate,idCardPublishedBy,drivingLicense,drivingLicenseDate,address
                      
                    })  
                }
            })
        }
        const contactId = query.get('contactId')
        if (contactId) {
            this.props.fetchSingleContact(contactId).then(data => {
                if(data.status==="success"){
                    const {testDrives,name,phone,egn,clientCompany,email,idCardNumber,idCardDate,idCardPublishedBy,drivingLicense,drivingLicenseDate,address
                    }= data.data
                    this.setState({
                        testDrives,name,phone,egn,clientCompany,email,idCardNumber,idCardDate,idCardPublishedBy,drivingLicense,drivingLicenseDate,address
                      
                    })  
                }
            })
        }
    } 
 

    getTodayDate = () =>{
        let today = new Date();
        let dd = String(today.getDate()).padStart(2, '0');
        let mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
        let yyyy = today.getFullYear();

        today = yyyy + '-' + mm + '-' + dd;
        this.setState({
            eventDatе:today,
            startDatetime:today +' 00:00:00',
            endDatetime:today +' 00:00:00',
        })
    }
 

    onChangeUserData = (event, value, reason) =>{    
  
        if (value) {  
            const {testDrives,name,phone,egn,clientCompany,email,idCardNumber,idCardDate,idCardPublishedBy,drivingLicense,drivingLicenseDate, startDatetime,endDatetime,address} = value
            this.setState({
                testDrives,name,phone,egn,clientCompany,email,idCardNumber,idCardDate,idCardPublishedBy,drivingLicense,drivingLicenseDate,address
            })  
        }else{ 
            // this.setState({
            //     testDrives:'',name:'',phone:'',egn:'',clientCompany:'',email:'',idCardNumber:'',idCardDate:'',idCardPublishedBy:'',drivingLicense:'',drivingLicenseDate:''
            // })  
        } 
    }

    // onChangeEgn = (event, value, reason) =>{   
    //     if(event || value){
    //         this.setState({
    //             egn:value
    //         }) 
    //     }
    // }
    onChangeUserName = (event, value, reason) =>{   
        if(event || value){
            this.setState({
                name:value
            }) 
        }
    }

    onChangeUserEmail = (event, value, reason) =>{   
        if(event || value){
            this.setState({
                email:value
            }) 
        }
    }

  
    onChangeEgn = (event) =>{ 
        const {name,value}=event.target; 
        this.setState({
            [name]:value,
        })  
        if (value.length===10) {
            let selectedContact = this.props.contacts.find(item => item.egn==value)
            if (selectedContact) {
                const {name='',phone='',egn='',clientCompany='',email='',address='',idCardNumber='',idCardDate='',idCardPublishedBy='',drivingLicense='',drivingLicenseDate=''} = selectedContact
                this.setState({
                    name,phone,egn,clientCompany,email,address,idCardNumber,idCardDate,idCardPublishedBy,drivingLicense,drivingLicenseDate,
                    errors:{}
                })
            }else{
                this.setState({
                    errors:{
                        'egn':'Няма намерен Контакт'
                    }
                })
            }
          
        }
        else if(value.length===0){
            this.setState({
                name:'',phone:'',egn:'',clientCompany:'',email:'',address:'',idCardNumber:'',idCardDate:'',idCardPublishedBy:'',drivingLicense:'',drivingLicenseDate:'',
                errors:{}
            })
        }  
    }

    onChangeTrader = (event, value, reason) =>{   
        if(event || value){
            this.setState({
                trader:value
            }) 
        }
    }

    onChangeteamId = (event, value, reason) =>{    
        if (value) {
            this.setState({
                teamId:value.id
            }) 
        }  
    }

    onChange = (event) =>{
        const {name, value} = event.target; 
        
        this.setState({
            [name]:value
        })  
    } 

    onChangeDrLS = (event) =>{
        const {name,value,checked} = event.target;
        if (checked) { 
            this.setState({ 
                drivingLicenseType:1 
            }) 
        }else{
            this.setState({ 
                drivingLicenseType:0 
            }) 
        } 
    }

    onChangeDate = (date, name) =>{
        let dd = String(date._d.getDate()).padStart(2, '0');
        let mm = String(date._d.getMonth() + 1).padStart(2, '0'); //January is 0!
        let yyyy = date._d.getFullYear();
        let ii = String(date._d.getMinutes()).padStart(2, '0');
        let hh = String(date._d.getHours()).padStart(2, '0');
        let today = yyyy + '-' + mm + '-' + dd+' '+hh+':'+ii+':00';
        this.setState({[name]:today}) 
  
    } 

    onChangeStatus =(status)=>{
        this.setState({ 
            correctionStatus:status
        }) 
    }

    onSubmit = (event) =>{
        this.setState({disableSubmit:true})
        event.preventDefault();
        this.props.createTestDrive(this.state).then(data => {
            if(data.status==="success"){
                this.setState({errors:[],disableSubmit:false}) 
                this.props.history.push('/dashboard/drive-list')
            } 
            else if (data.errors) {
                this.setState({errors:data.errors,disableSubmit:false})   
            }  
        })
    }
    
    onChangeTestdriveCar = (event, value, reason) =>{    
        if(value){
            
            this.props.getBookedDates(value.id);
            const {cars} = this.props;
            const selectedCar = cars.find(el => el.id==value.id);  
            
            this.setState({
                testDriveCar:value.id,
                runTime:selectedCar.runTime,
                runTimeWork:selectedCar.runTimeWork, 
                runTimeStart:selectedCar.runTime, 
                runTimeEnd:selectedCar.runTime, 
            })   
        } 
    }

    onChangeTestdriveCarId = (event, value, reason) =>{   
        if(event || value){
            this.setState({
                testDriveCarName:value
            }) 
        }
    } 

    onChangeOwnerData = (event, value, reason) =>{    
        if(event || value){
            this.setState({
                leasing_client:value.id,  
                companyEik:value.companyEik
            }) 
        }
    }
   
    render() { 
        const {roleId,drivingLicenseType,testDrives,publishingType,registerNumber,brand,trader, model, testDriveCar,testDriveCarName, name,phone,egn,clientCompany,email,idCardNumber,idCardDate,idCardPublishedBy,drivingLicense,drivingLicenseDate, runTimeWork,runTimeStart, runTimeEnd, eventDatе,runTime,errors,startDatetime,endDatetime, reason,correctionStatus,disableSubmit, pricePerDay, mileagePerDay,address,leasing_client, companyEik, company_bank}= this.state;
        const {cars,contacts, drives_dates, users, clients ,companies} = this.props;  
        const bankList =companies.filter(item=>item.id==this.state.companyId) || {} 
        const {banks} = bankList[0] ||{}
 
        return (  
            <React.Fragment>  
                <Card >
                    <Grid container spacing={3} className="section-title-wrapper padding"> 
                        <Grid container item xs={8} sm={6} alignItems="center"> 
                            <h3 className="section-title">
                                <i className="flaticon2-gear"></i> Издаване автомобил:
                            </h3>
                        </Grid>   
                        <Grid container item xs={4} sm={6} alignItems="center" justify="flex-end">  
                            <Link  
                                className="btn btn-secondary"
                                to="/dashboard/drive-list" 
                            ><i className="flaticon2-circular-arrow"/>
                               <span>  Назад </span> 
                            </Link>   
                        </Grid>  
                    </Grid> 
                    <form  className='form padding' noValidate onSubmit={this.onSubmit}>
                        <Grid container spacing={6}>  
                            <Grid item md={6} sm={12} xs={12}>
                                <Grid container spacing={3}> 
                                    <Grid item md={12} sm={12} xs={12}>  
                                        <FormControl variant="outlined" error={errors.publishingType?true:false} margin="normal" className='full-width select-styles'>
                                            <InputLabel id="publishingType">
                                                Тип издаване 
                                            </InputLabel>
                                            <Select  
                                                MenuProps={{
                                                    anchorOrigin: {
                                                    vertical: "bottom",
                                                    horizontal: "right"
                                                    },
                                                    transformOrigin: {
                                                    vertical: "top",
                                                    horizontal: "right"
                                                    },
                                                    getContentAnchorEl: null
                                                }} 
                                                labelId="publishingType"
                                                required   
                                                inputProps={{
                                                    name: 'publishingType', 
                                                }} 
                                                value={publishingType}
                                                onChange={this.onChange}
                                                labelWidth={120}>    
                                                <MenuItem value={1}>TestDrive</MenuItem>  
                                                <MenuItem value={2}>Заместващ автомобил</MenuItem>  
                                                <MenuItem value={3}>Служебно ползване </MenuItem> 
                                                {roleId!==3&&<MenuItem value={4}>Репатриране</MenuItem>}  
                                                {roleId===1&&<MenuItem value={5}>Корекция</MenuItem>}
                                                {<MenuItem value={6}>Рекламно шофиране</MenuItem>}
                                                {(roleId===1 || roleId===2)&&<MenuItem value={7}>Наем на Автомобил</MenuItem>}
                                            </Select>
                                            {errors.publishingType&&<FormHelperText>{errors.publishingType}</FormHelperText>}
                                        </FormControl>
                                    </Grid> 
                                </Grid> 
                                {publishingType===7&&<>
                                    <Grid container spacing={3} className="section-title-wrapper mb-20">
                                        <Grid container item xs={12} sm={12} alignItems="center">
                                            <h3 className="section-title">
                                                Условия за наем:
                                            </h3>
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={3}> 
                                        <Grid item md={6} xs={12}> 
                                            <TextField inputProps={{'className':'data-hj-allow'}} className="full-width mb-20" 
                                                InputLabelProps={{shrink: true}} 
                                                required 
                                                margin="normal" 
                                                variant="outlined"  
                                                InputProps={{
                                                    startAdornment: (
                                                    <InputAdornment position="start">
                                                        <i className="far fa-money-bill-alt"></i> 
                                                    </InputAdornment>
                                                    ),
                                                }}
                                                label="Наемна вноска на ден за един авт."
                                                name="pricePerDay"
                                                value={pricePerDay}
                                                error ={errors.pricePerDay?true:false} 
                                                helperText={errors.pricePerDay}  
                                                onChange={this.onChange}  
                                            /> 
                                        </Grid>
                                        <Grid item md={6} xs={12}> 
                                            <TextField inputProps={{'className':'data-hj-allow'}} className="full-width mb-20" 
                                                InputLabelProps={{shrink: true}} 
                                                required 
                                                margin="normal" 
                                                variant="outlined"  
                                                InputProps={{
                                                    startAdornment: (
                                                    <InputAdornment position="start">
                                                        <i className="fas fa-road"></i> 
                                                    </InputAdornment>
                                                    ),
                                                }}
                                                label="Договорен пробег на автомобил"
                                                name="mileagePerDay"
                                                value={mileagePerDay}
                                                error ={errors.mileagePerDay?true:false} 
                                                helperText={errors.mileagePerDay}  
                                                onChange={this.onChange}  
                                            /> 
                                        </Grid>
                                    </Grid>
                                </>} 
                                {publishingType=='2'&&<div> 
                                    <Grid container spacing={3} className="section-title-wrapper mb-20">
                                        <Grid container item xs={12} sm={12} alignItems="center">
                                            <h3 className="section-title">
                                                <i className="flaticon2-start-up"></i> Клиентски автомобил:
                                            </h3>
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={3}> 
                                        <Grid item md={6} xs={12}> 
                                            <TextField inputProps={{'className':'data-hj-allow'}} className="full-width mb-20" 
                                                InputLabelProps={{shrink: true}} 
                                                required 
                                                margin="normal" 
                                                variant="outlined"  
                                                InputProps={{
                                                    startAdornment: (
                                                    <InputAdornment position="start">
                                                        <i className="flaticon2-rocket"></i>  
                                                    </InputAdornment>
                                                    ),
                                                }}
                                                label="Марка"
                                                name="brand"
                                                value={brand}
                                                error ={errors.brand?true:false} 
                                                helperText={errors.brand}  
                                                onChange={this.onChange}  
                                            /> 
                                            <TextField inputProps={{'className':'data-hj-allow'}} className="full-width mb-20" 
                                                InputLabelProps={{shrink: true}} 
                                                required 
                                                margin="normal" 
                                                variant="outlined"  
                                                InputProps={{
                                                    startAdornment: ( 
                                                    <InputAdornment position="start">
                                                        <i className="flaticon2-start-up"></i>  
                                                    </InputAdornment>
                                                    ),
                                                }}
                                                label="Модел"
                                                name="model"  
                                                value={model}
                                                error ={errors.model?true:false}
                                                helperText={errors.model}
                                                onChange={this.onChange} 
                                            /> 
                                        </Grid> 
                                        <Grid item md={6} xs={12}>
                                            <TextField inputProps={{'className':'data-hj-allow'}} className="full-width mb-20" 
                                                InputLabelProps={{shrink: true}} 
                                                required 
                                                margin="normal" 
                                                variant="outlined"  
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            <i className="flaticon-notepad"></i>  
                                                        </InputAdornment>
                                                    ),
                                                }}
                                                label="Регистрационнен Номер"
                                                name="registerNumber"   
                                                value={registerNumber}
                                                error ={errors.registerNumber?true:false} 
                                                helperText={errors.registerNumber}
                                                onChange={this.onChange} 
                                            />
                                            <TextField inputProps={{'className':'data-hj-allow'}} className="full-width mb-20" 
                                                InputLabelProps={{shrink: true}} 
                                                disabled 
                                                margin="normal" 
                                                variant="outlined"  
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            <i className="flaticon2-user"></i>  
                                                        </InputAdornment>
                                                    ),
                                                }}
                                                label="Търговец:"
                                                name="trader"
                                                value={trader} 
                                            /> 
                                        </Grid>
                                    </Grid>
                            
                                </div>}
                                
                                <Grid container spacing={3}> 
                                    <Grid item md={12} sm={12} xs={12}>  
                                    <Autocomplete
                                        freeSolo 
                                        inputValue={testDriveCarName} 
                                        options={cars}  
                                        onChange={this.onChangeTestdriveCar}  
                                        onInputChange={this.onChangeTestdriveCarId}  
                                        getOptionLabel={option => { 
                                            return (
                                                option.brand +' / '+ option.model +' / '+  option.registerNumber +' / '+  
                                                (option.status==0  ? 'Свободен' : option.status==1 ? 'Зает'  : 'Неактивен' )
                                            ) 
                                        }} 
                                        renderInput={params => (
                                            <TextField {...params}  
                                            className="full-width mb-20" 
                                            InputLabelProps={{shrink: true}}  
                                            error ={errors.testDriveCar?true:false} 
                                            helperText={errors.testDriveCar}
                                            margin="normal" 
                                            variant="outlined"   
                                            label="Изберете автомобил:"
                                        />  
                                        )}
                                    /> 
                                    {/* <FormControl variant="outlined" error={errors.testDriveCar?true:false} margin="normal" className='full-width select-styles'>
                                        <InputLabel id="testDriveCar">
                                            Изберете автомобил
                                        </InputLabel> 
                                        <Select 
                                            MenuProps={{
                                                anchorOrigin: {
                                                vertical: "bottom",
                                                horizontal: "right"
                                                },
                                                transformOrigin: {
                                                vertical: "top",
                                                horizontal: "right"
                                                },
                                                getContentAnchorEl: null
                                            }} 
                                            labelId="testDriveCar"
                                            required   
                                            inputProps={{
                                                name: 'testDriveCar', 
                                            }} 
                                            value={testDriveCar}
                                            onChange={this.onChange}
                                            labelWidth={180}> 
                                            {cars && cars.map(car=>{
                                                return car.status!==2&&<MenuItem key={car.id} value={car.id}>{car.brand} / {car.model} / {car.registerNumber}/ {car.status}</MenuItem>
                                            })}     
                            
                                        </Select>
                                        {errors.testDriveCar&&<FormHelperText>{errors.testDriveCar}</FormHelperText>}
                                    </FormControl> */}
                                    </Grid> 
                                </Grid>
                            
                                <Grid container spacing={3}> 
                                    {(publishingType==1 || publishingType==2) &&<Grid item md={6} xs={12}> 
                                    <TextField inputProps={{'className':'data-hj-allow'}} className="full-width mb-20" 
                                        InputLabelProps={{shrink: true}} 
                                        disabled 
                                        margin="normal" 
                                        variant="outlined"  
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <i className="flaticon-notepad"></i>  
                                                </InputAdornment>
                                            ),
                                        }}
                                        label="Км. При предоставяне"
                                        name="runTime"   
                                        value={runTime}
                                        onChange={this.onChange} 
                                    />
                                    </Grid>}

                                    {(publishingType==3 || publishingType==4) &&<Grid item md={6} xs={12}> 
                                    <TextField inputProps={{'className':'data-hj-allow'}} className="full-width mb-20" 
                                        InputLabelProps={{shrink: true}} 
                                        disabled 
                                        margin="normal" 
                                        variant="outlined"  
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <i className="flaticon-notepad"></i>  
                                                </InputAdornment>
                                            ),
                                        }}
                                        label="Пробег за месеца"
                                        name="runTimeWork"   
                                        value={runTimeWork}
                                        onChange={this.onChange} 
                                    />
                                    </Grid>} 
                                    {(publishingType!==5 && publishingType!==7) && <Grid item md={6} xs={12}>
                                        <TextField inputProps={{'className':'data-hj-allow'}} className="full-width mb-20" 
                                            InputLabelProps={{shrink: true}} 
                                            disabled 
                                            margin="normal" 
                                            variant="outlined"  
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <i className="flaticon-notepad"></i>  
                                                    </InputAdornment>
                                                ),
                                            }}
                                            label="Дата:"
                                            name="eventDatе"   
                                            value={eventDatе}
                                            onChange={this.onChange} 
                                        />    
                                    </Grid>  
                                    }  
                                </Grid> 
                           
                            </Grid> 
                            <Grid item md={6} sm={12} xs={12}>
                                <Grid container spacing={3} >
                                    <MuiPickersUtilsProvider locale="bg" utils={MomentUtils}> 
                                        <Grid container item xs={12} sm={6} alignItems="center"> 
                                            <DateTimePicker
                                                autoOk
                                                className='full-width mb-20'
                                                ampm={false}  
                                                inputVariant="outlined"
                                                format="YYYY-MM-DD HH:mm:ss"
                                                margin="normal"
                                                value={startDatetime}
                                                onChange={(date)=>this.onChangeDate(date,'startDatetime')}
                                                error ={errors.startDatetime?true:false} 
                                                helperText={errors.startDatetime}
                                                label="Начало"
                                            />
                                        
                                        </Grid>   
                                        <Grid container item xs={12} sm={6} alignItems="center">  
                                            <DateTimePicker
                                                autoOk
                                                className='full-width mb-20'
                                                ampm={false}  
                                                inputVariant="outlined"
                                                format="YYYY-MM-DD HH:mm:ss"
                                                margin="normal"
                                                value={endDatetime}
                                                onChange={(date)=>this.onChangeDate(date,'endDatetime')}
                                                label="Край"
                                                error ={errors.endDatetime?true:false} 
                                                helperText={errors.endDatetime}
                                            />
                                        </Grid>   
                                    </MuiPickersUtilsProvider>
                                </Grid> 
                                <Grid container spacing={3} className="section-title-wrapper"> 
                                    <Grid container item xs={12} sm={4} alignItems="center"> 
                                        <h3 className="section-title">Резeрвации:
                                        </h3>
                                    </Grid>  
                                </Grid> 
                                <Grid container spacing={3} className="mb-20"> 
                                    <Grid container item xs={12} sm={12} alignItems="center" justify="center"> 
                                        {drives_dates.length>0&&<table className="custom-table">
                                            <thead>
                                                <tr>
                                                <th>Издаване:</th>
                                                <th>Приемане:</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {drives_dates.map(date => <tr key={date.startDatetime} ><td>{date.startDatetime}</td><td>{date.endDatetime}</td></tr> )
                                                }
                                            </tbody>
                                        </table> 
                                        }
                                        { drives_dates.length<1&&<div>
                                            <br/>
                                            <h4 className="text-center">Няма записи</h4> 
                                            </div>
                                        }
                                    </Grid>            
                                </Grid>            
                            </Grid>            
                        
                        </Grid>            
                        <Grid container spacing={6}>  
                            {(publishingType==3 || publishingType==4 || publishingType==5) &&<Grid item md={6} sm={12} xs={12}>  
                                 <Grid container item xs={12} alignItems="center" className="padding section-title-wrapper"> 
                                    <h3 className="section-title">
                                        <i className="flaticon2-gear"></i>Допълнителна Информация:
                                    </h3> 
                                </Grid>
                                <Grid container spacing={3}> 
                                    <Grid item md={6} xs={12}> 
                                        <TextField inputProps={{'className':'data-hj-allow'}} className="full-width mb-20" 
                                            InputLabelProps={{shrink: true}}  
                                            margin="normal" 
                                            variant="outlined"  
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <i className="flaticon-notepad"></i>  
                                                    </InputAdornment>
                                                ),
                                            }}
                                            label="Км. При предоставяне"
                                            name="runTimeStart"   
                                            value={runTimeStart}
                                            onChange={this.onChange} 
                                        />   
                                    </Grid>      
                                    <Grid item md={6} xs={12}> 
                                        <TextField inputProps={{'className':'data-hj-allow'}} className="full-width mb-20" 
                                            InputLabelProps={{shrink: true}}  
                                            margin="normal" 
                                            variant="outlined"  
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <i className="flaticon-notepad"></i>  
                                                    </InputAdornment>
                                                ),
                                            }}
                                            label="Км. При Предаване"
                                            name="runTimeEnd"   
                                            value={runTimeEnd}
                                            onChange={this.onChange} 
                                        /> 
                                    </Grid>      
                                </Grid>      
                                {publishingType!==5&&<Autocomplete
                                    freeSolo 
                                    inputValue={trader} 
                                    options={users} 
                                    onChange={this.onChangeteamId} 
                                    onInputChange={this.onChangeTrader}  
                                    getOptionLabel={option => option.name} 
                                    renderInput={params => (
                                        <TextField inputProps={{'className':'data-hj-allow'}} {...params}  
                                        className="full-width mb-20" 
                                        InputLabelProps={{shrink: true}}  
                                        error ={errors.teamId?true:false} 
                                        helperText={errors.teamId}
                                        margin="normal" 
                                        variant="outlined"   
                                        label="Служител:"
                                    />  
                                    )}
                                    /> }            
                                    {publishingType==5&& <TextField
                                        InputLabelProps={{shrink: true}}  
                                        label="Причина"
                                        className="full-width mb-20" 
                                        multiline
                                        rows={4}
                                        name='reason'
                                        value={reason}
                                        onChange={this.onChange} 
                                        error ={errors.reason?true:false} 
                                        helperText={errors.reason}
                                        variant="outlined"
                                    /> 
                                    }
                            </Grid>     
                            }
                            {publishingType==5 &&<Grid item md={6} sm={12} xs={12}>  
                                <Grid container item xs={12} alignItems="center" className="padding section-title-wrapper"> 
                                    <h3 className="section-title">
                                        <i className="flaticon2-gear"></i>Резолюция на Мениджър:
                                    </h3> 
                                </Grid>  
                                <Grid container spacing={3} className='mb-20'> 
                         
                                    <Grid container item md={6} xs={12} className='p-tb-0'>  
                                        <button type="button"   
                                            className={"btn full-width "+(correctionStatus==1? 'btn-success':'')} 
                                            onClick={()=>this.onChangeStatus(1)}>
                                                одобрен
                                        </button>
                                    </Grid>  
                                    <Grid container item md={6} xs={12} className='p-tb-0'>
                                        <button type="button" 
                                           className={"btn full-width "+(correctionStatus==2? 'btn-danger':'')} 
                                            onClick={()=>this.onChangeStatus(2)}>
                                                отказан
                                        </button>
                                    </Grid>  
                                </Grid> 
                            </Grid>  
                            }
                        </Grid>  
                        
                        {(publishingType==1 || publishingType==2 || publishingType==5 || publishingType==6 || publishingType==7) &&<>
                        <Grid container spacing={3} className="section-title-wrapper mt-20 mb-20"> 
                            <Grid container item xs={12} sm={4} alignItems="center"> 
                                <h3 className="section-title">
                                    <i className="flaticon2-user"></i> Информация за клиента:
                                </h3>
                            </Grid>  
                        </Grid> 
                        <Grid container spacing={3} > 
                            <Grid item md={4} xs={12}>  
                                <TextField inputProps={{'className':'data-hj-allow'}} className="full-width mb-20"
                                    InputLabelProps={{shrink: true}}
                                    disabled={contacts.length===0}
                                    margin="normal"
                                    variant="outlined"
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <i className="flaticon2-browser"></i>
                                            </InputAdornment>
                                        ),
                                        type:"password",
                                        autoComplete: 'new-password'
                                    }}
                                    label="ЕГН:"
                                    name="egn"
                                    value={egn}
                                    error ={errors.egn?true:false}
                                    helperText={errors.egn}
                                    onChange={this.onChangeEgn}
                                />
                                {/* <Autocomplete
                                    freeSolo 
                                    inputValue={egn} 
                                    options={contacts} 
                                    onChange={this.onChangeUserData} 
                                    onInputChange={this.onChangeEgn}  
                                    getOptionLabel={option => option.egn} 
                                    renderInput={params => (
                                        <TextField inputProps={{'className':'data-hj-allow'}} {...params}  
                                        className="full-width mb-20" 
                                        InputLabelProps={{shrink: true}}  
                                        error ={errors.egn?true:false} 
                                        helperText={errors.egn}
                                        margin="normal" 
                                        variant="outlined"   
                                        label="ЕГН:"
                                    />  
                                    )}
                                />  */}
                                 <Autocomplete
                                    filterOptions={filterOptions}
                                    freeSolo
                                    options={contacts}
                                    inputValue={name}
                                    value={name}
                                    onChange={this.onChangeUserData}
                                    onInputChange={this.onChangeUserName}
                                    getOptionLabel={option => option.name}
                                    renderInput={params => (
                                        <TextField {...params}
                                        className="full-width mb-20"
                                        InputLabelProps={{shrink: true}}
                                        error ={errors.name?true:false}
                                        helperText={errors.name}
                                        margin="normal"
                                        variant="outlined"
                                        label="Име:"
                                    />
                                    )}
                                /> 
                                <TextField inputProps={{'className':'data-hj-allow'}}  className="full-width mb-20" 
                                    InputLabelProps={{shrink: true}} 
                                    required 
                                    margin="normal" 
                                    variant="outlined"  
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <i className="flaticon2-phone"></i>  
                                            </InputAdornment>
                                        ),
                                    }}
                                    label="Телефон:"
                                    name="phone"   
                                    value={phone}
                                    error ={errors.phone?true:false} 
                                    helperText={errors.phone}
                                    onChange={this.onChange} 
                                />   
                                 <Autocomplete
                                    filterOptions={filterOptions}
                                    freeSolo
                                    options={contacts}
                                    inputValue={email}
                                    value={email}
                                    onChange={this.onChangeUserData}
                                    onInputChange={this.onChangeUserEmail}
                                    getOptionLabel={option => option.email}
                                    renderInput={params => (
                                        <TextField {...params}
                                        className="full-width mb-20"
                                        InputLabelProps={{shrink: true}}
                                        error ={errors.email?true:false}
                                        helperText={errors.email}
                                        margin="normal"
                                        variant="outlined"
                                        label="E-mail:"
                                    />
                                    )}
                                />    
                            </Grid>   
                            <Grid item md={4} xs={12}>   
                                <TextField inputProps={{'className':'data-hj-allow'}}  className="full-width mb-20" 
                                    InputLabelProps={{shrink: true}} 
                                    required 
                                    margin="normal" 
                                    variant="outlined"  
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <i className="flaticon2-browser"></i>  
                                            </InputAdornment>
                                        ),
                                    }}
                                    label="ЛК/номер:"
                                    name="idCardNumber" 
                                    value={idCardNumber}
                                    error ={errors.idCardNumber?true:false} 
                                    helperText={errors.idCardNumber}
                                    onChange={this.onChange} 
                                />   
                                <TextField inputProps={{'className':'data-hj-allow'}}  className="full-width mb-20" 
                                    InputLabelProps={{shrink: true}} 
                                    required 
                                    margin="normal" 
                                    variant="outlined"  
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <i className="flaticon2-browser"></i>  
                                            </InputAdornment>
                                        ),
                                    }}
                                    label="Издадена от:"
                                    name="idCardPublishedBy" 
                                    value={idCardPublishedBy}
                                    error ={errors.idCardPublishedBy?true:false}  
                                    helperText={errors.idCardPublishedBy} 
                                    onChange={this.onChange} 
                                /> 
                                <TextField inputProps={{'className':'data-hj-allow'}}  className="full-width mb-20" 
                                    InputLabelProps={{shrink: true}} 
                                    required 
                                    margin="normal" 
                                    variant="outlined"  
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <i className="flaticon2-calendar-1"></i>  
                                            </InputAdornment>
                                        ),
                                    }}
                                    label="ЛК/дата на издаване"
                                    name="idCardDate"  
                                    value={idCardDate}
                                    error ={errors.idCardDate?true:false} 
                                    helperText={errors.idCardDate}
                                    onChange={this.onChange} 
                                />  
                                <div className="full-width">
                                    <p className={"text-label " + ( testDrives < 4  ? 'free':'')}>
                                        Брой тестдрайв: {testDrives ? testDrives :0}
                                    </p>
                                </div> 
                            </Grid>   
                            <Grid item md={4} xs={12}>  
                                <TextField inputProps={{'className':'data-hj-allow'}}  className="full-width mb-20" 
                                    InputLabelProps={{shrink: true}} 
                                    required 
                                    margin="normal" 
                                    variant="outlined"  
                                    InputProps={{
                                        startAdornment: (
                                        <InputAdornment position="start">
                                                    <i className="flaticon2-browser"></i>  
                                        </InputAdornment>
                                        ),
                                    }}
                                    label="Шофьорска книжка номер"
                                    name="drivingLicense"  
                                    value={drivingLicense}
                                    error ={errors.drivingLicense?true:false} 
                                    helperText={errors.drivingLicense}
                                    onChange={this.onChange} 
                                />  
                                <TextField inputProps={{'className':'data-hj-allow'}}  className="full-width mb-20" 
                                    InputLabelProps={{shrink: true}} 
                                    required 
                                    margin="normal" 
                                    variant="outlined"  
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <i className="flaticon2-calendar-1"></i>  
                                            </InputAdornment>
                                        ),
                                    }}
                                    label="Книжка/дата на издаване:"
                                    name="drivingLicenseDate"  
                                    value={drivingLicenseDate}
                                    error ={errors.drivingLicenseDate?true:false} 
                                    helperText={errors.drivingLicenseDate}
                                    onChange={this.onChange} 
                                />    
                                <TextField inputProps={{'className':'data-hj-allow'}}  className="full-width mb-20" 
                                    InputLabelProps={{shrink: true}} 
                                    margin="normal" 
                                    variant="outlined"  
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <i className="far fa-building"></i>
                                            </InputAdornment>
                                        ),
                                    }}
                                    label="Представител на фирма:"
                                    name="clientCompany"  
                                    value={clientCompany}
                                    error ={errors.clientCompany?true:false} 
                                    helperText={errors.clientCompany}
                                    onChange={this.onChange} 
                                />  
                                <FormControlLabel style={{'marginTop':'20px'}}
                                    control={
                                    <Checkbox
                                        name='drivingLicenseType'
                                        checked={drivingLicenseType==1}
                                        onChange={this.onChangeDrLS}
                                        value="drivingLicenseType "
                                        color="primary"
                                    />
                                    }
                                    label="Mеждународни Документи"
                                />   
                            </Grid> 
                        </Grid> 
                        {publishingType===7&&
                        <Grid container spacing={3} > 
                            <Grid item md={3} xs={12}> 
                                <TextField inputProps={{'className':'data-hj-allow'}}  className="full-width mb-20"
                                    InputLabelProps={{shrink: true}}
                                    required
                                    margin="normal"
                                    variant="outlined"
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <i className="fas fa-home"></i>
                                            </InputAdornment>
                                        ),
                                    }}
                                    label="Адрес:"
                                    name="address"
                                    value={address}
                                    error ={errors.address?true:false}
                                    helperText={errors.address}
                                    onChange={this.onChange}
                                />
                            </Grid>   
                            <Grid item md={3} xs={12}>  
                                <Autocomplete
                                    filterOptions={filterOptions}
                                    freeSolo
                                    options={clients}
                                    inputValue={leasing_client }
                                    value={leasing_client } 
                                    onChange={this.onChangeOwnerData}
                                    getOptionLabel={option => option.email}
                                    renderInput={params => (
                                        <TextField {...params}
                                        className="full-width mb-20"
                                        InputLabelProps={{shrink: true}}
                                        error ={errors.leasing_client ?true:false}
                                        helperText={errors.leasing_client }
                                        margin="normal"
                                        variant="outlined"
                                        label="Собственик на наемното имущество:"
                                    />
                                    )}
                                /> 
                            </Grid>   
                            <Grid item md={3} xs={12}>  
                                <TextField inputProps={{'className':'data-hj-allow'}}  className="full-width mb-20"
                                    InputLabelProps={{shrink: true}}
                                    required
                                    disabled
                                    margin="normal"
                                    variant="outlined"
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <i className="fas fa-landmark"></i>
                                            </InputAdornment>
                                        ),
                                    }}
                                    label="ЕИК на Собственика:"
                                    name="companyEik"
                                    value={companyEik}
                                    error ={errors.companyEik?true:false}
                                    helperText={errors.companyEik}
                                    onChange={this.onChange}
                                />
                            </Grid> 
                            <Grid item md={3} xs={12}> 
                                {banks.length>0&&<FormControl variant="outlined" error={errors.companyBankId?true:false} margin="normal" className='mb-20 full-width select-styles'>
                                    <InputLabel id="company_bank">
                                        Банка:
                                    </InputLabel>
                                    <Select  
                                        MenuProps={{
                                            anchorOrigin: {
                                            vertical: "bottom",
                                            horizontal: "right"
                                            },
                                            transformOrigin: {
                                            vertical: "top",
                                            horizontal: "right"
                                            },
                                            getContentAnchorEl: null
                                        }} 
                                        labelId="company_bank"
                                        required   
                                        inputProps={{
                                            name: 'company_bank', 
                                        }}  
                                        value={company_bank} 
                                        onChange={this.onChange}
                                        labelWidth={60}  >    
                                        {banks.map(option=>{ 
                                            return <MenuItem key={option.id} value={option.id}>{option.bank}</MenuItem>  
                                        })}
                                    
                                    </Select>
                                    {errors.company_bank &&<FormHelperText>{errors.company_bank }</FormHelperText>}
                                </FormControl>}   
                            </Grid>
                        </Grid> }
                        </>}
                      
                        <Grid container spacing={3} >   
                            <Grid container item md={4} xs={12} >    
                                {roleId!==19&&<button type="submit" disabled={disableSubmit} className="btn btn-info full-width">
                                    <i className="flaticon2-telegram-logo"></i>
                                    {(publishingType ==1 || publishingType==2) ? 'Издай и принтирай': 'Запиши'} 
                                </button> }
                            </Grid> 
                        </Grid>    
                    </form> 
                
                </Card> 
            </React.Fragment>
         );
    }
}
const mapStateToProps = (state, props) => {      
    return{ 
        cars:state.cars.items,
        contacts:state.contacts.items,
        drives_dates:state.driveList.drives_dates,
        users:state.users.items,
        clients:state.clients.items.filter(item=>item.type===2),
        companies:state.companies.items
    } 
}  
export default connect(mapStateToProps, {getBookedDates,fetchSingleClient, createTestDrive,fetchSingleContact, fetchCarsFiltered,fetchUsers, fetchClients, fetchContacts, fetchCompanies })(CreateTestDrive);
 