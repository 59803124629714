import React from 'react'; 
 
export const options = {
    sortOrder: {
        name: 'id',
        direction: 'desc'
    },
    confirmFilters: true,  
    customFilterDialogFooter: (currentFilterList, applyNewFilters) => { 
        return (
            <div style={{ marginTop: '40px' }}>
                <button className="btn btn-info small" onClick={()=>applyNewFilters()}>Приложи филтрите</button>
            </div>
        );
    },
    print: false,
    download: true,
    viewColumns: true,
    filter:true,  
    selectableRows: "none",     
    rowsPerPage:100,
    downloadOptions: {
        filename: 'testDrive.csv',
        separator: ',',
        filterOptions: {
          useDisplayedColumnsOnly: true,
        } 
    },
    customSort: (data, colIndex, order) => {  
        return data.sort((a, b) => {
            // return (a.data[colIndex].length < b.data[colIndex].length ? -1: 1 ) * (order === 'desc' ? 1 : -1);
            a = a.data[colIndex] || '';
            b = b.data[colIndex] || '';
            if (order === 'asc') {
                return a.toString().localeCompare(b, undefined, { numeric: true });
            } else if (order === 'desc') {
                return b.toString().localeCompare(a, undefined, { numeric: true });
            }
        })
    },
    setRowProps: (row) => {    
        if (row[1]==="Върнат") {
            if(row[4]==='Корекция'){
                return {  
                    style: {
                        background: '#ce88ff' 
                    }
                }
            }
            else if(row[4]==="Tестдрайв" && row[5]>30 || row[4]==="Заместващ" && row[5]>100){
                return {  
                    style: {
                        background: '#fff14c' 
                    }
                }
            }else{
                return {  
                    style: {background: '#e3fff1'}
                }
            } 
        }else{
            if(row[4]==='Корекция'){
                return {  
                    style: {
                        background: '#ce88ff' 
                    }
                }
            }
            else if(row[4]==="Tестдрайв" && row[5]>30 || row[4]==="Заместващ" && row[5]>100){
                return {  
                    style: {
                        background: '#fff14c' 
                    }
                }
            }else{
                return {  
                    style: {background: '#ffe1e1'}
                }
            } 
        }  
    },
    textLabels: {
        body: {
            noMatch: "Няма намерени резултати",
            toolTip: "Сортирай",
            columnHeaderTooltip: column => `Сортирай по ${column.label}`
        },
        pagination: {
            next: "Следваща страница",
            previous: "Предишна страница",
            rowsPerPage: "Брой записи на страница:",
            displayRows: "от",
        },
        toolbar: {
            search: "Търси",
            downloadCsv: "Download CSV",
            print: "Принтирай",
            viewColumns: "Колони",
            filterTable: "Филтри",
        },
        filter: {
            all: "Всички",
            title: "Филтри",
            reset: "Откажи",
        },
        viewColumns: {
            title: "Покажи Колони",
            titleAria: "Покажи/Скрии Колони",
        },
        selectedRows: {
            text: "запис/и са селектирани за изтриване",
            delete: "Изтриване",
            deleteAria: "Изтриите избраните записи",
        },
    }
}; 

export const columns = [ 
    
    {
        label: "ID:",
        name: "id",
        options: {
            filter: false,
            sort: true,  
           }
    },
    {
        label: "Статус:",
        name: "status",
        options: {
            filter: true,
            sort: true,
            customBodyRender: (value, tableMeta, updateValue) => {
                if (value===0) {
                    return 'Издаден' 
                }else{
                    return 'Върнат'
                }
            }, 
        }
    },
    {
        label: "Търговец:",
        name: "trader",
        options: {
            filter: true,
            sort: true,
           }
    },
    {
        label: "Дата на предаване:",
        name: "eventDatе",
        options: {
            filter: false,
            sort: true,
           }
    },
    {
        label: "Тип:",
        name: "publishingType",
        options: {
            filter: true,
            sort: true,
            customBodyRender: (value, tableMeta, updateValue) => { 
                if (value===1) {
                    return 'Tестдрайв' 
                }else if (value===2) {
                    return 'Заместващ' 
                }else if (value===3) {
                    return 'Служебно ползване' 
                }else if (value===4) {
                    return 'Репатриране' 
                }else if (value===5) {
                    return 'Корекция' 
                }else if (value===6) {
                    return 'Рекламно шофиране' 
                }else if (value===7) {
                    return 'Наем на автомобил'
                }
            }
           }
    }, 
    {
        label: "Пробег:",
        name: "runTime",
        options: {
            filter: false,
            sort: true,
        }
    },  
    {
        label: "Марка:",
        name: "carBrand",
        options: {
            filter: true,
            sort: true,
        }
    }, 
    {
        label: "Модел:",
        name: "carModel",
        options: {
            filter: true,
            sort: true,
        }
    }, 
    {
        label: "Рег. номер:",
        name: "carRegisterNumber",
        options: {
            filter: false,
            sort: true,
        }
    }, 
    {
        label: "Име:",
        name: "name",
        options: {
            filter: false,
            sort: true,
        }
    }, 
    {
        label: "E-mail:",
        name: "email",
        options: {
            filter: false,
            sort: true,
        }
    }, 
    {
        label: "Телефон:",
        name: "phone",
        options: {
            filter: false,
            sort: true,
        }
    }, 
    // {
    //     label:'Свали:',
    //     name: "pdf",
    //     options: {
    //       filter: false,
    //       sort: false,
    //       empty: true,
    //       customBodyRender: (value, tableMeta, updateValue) => { 
    //         return (
    //             <a href={value} type="submit" target="_blank" className="full-width btn btn-focus"><i className="flaticon2-fax" style={{marginRight:0}}></i></a>
    //         );
    //       }
    //     }
    //   }
];
