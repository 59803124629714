import { apiUrl } from "../helpers/apiUrl";
import { setMessage, removeMessage, setErrorMessage, removeErrorMessage } from "./msgActions";

export const getCompaniesList = () => (dispatch) => {
    return fetch(apiUrl + "companies/autocomplete", {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization:
                "Bearer " +
                ("; " + document.cookie).split("; accessToken=").pop().split(";").shift(),
        },
        body: JSON.stringify(),
    })
        .then((result) => {
            dispatch(setErrorMessage(result.status));
            dispatch(removeErrorMessage());
            return result.json();
        })
        .then((response) => {
            const { status, data } = response;
            if (status === "success") {
                dispatch({
                    type: "GET_COMPANIES_LIST",
                    payload: data,
                });
            }
            return response;
        });
};
export const getCompaniesListTestDrive = () => (dispatch) => {
    return fetch(apiUrl + "test-drives/companies/autocomplete", {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization:
                "Bearer " +
                ("; " + document.cookie).split("; accessToken=").pop().split(";").shift(),
        },
        body: JSON.stringify(),
    })
        .then((result) => {
            dispatch(setErrorMessage(result.status));
            dispatch(removeErrorMessage());
            return result.json();
        })
        .then((response) => {
            const { status, data } = response;
            if (status === "success") {
                dispatch({
                    type: "GET_COMPANIES_LIST_TD",
                    payload: data,
                });
            }
            return response;
        });
};
export const fetchCompanies = () => (dispatch) => {
    return fetch(apiUrl + "companies", {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization:
                "Bearer " +
                ("; " + document.cookie).split("; accessToken=").pop().split(";").shift(),
        },
        body: JSON.stringify(),
    })
        .then((result) => {
            dispatch(setErrorMessage(result.status));
            dispatch(removeErrorMessage());
            return result.json();
        })
        .then((response) => {
            const { status, data } = response;
            if (status === "success") {
                dispatch({
                    type: "FETCH_COMPANIES",
                    payload: data,
                });
            }
            return response;
        });
};

export const fetchSingleCompany = (id) => (dispatch) => {
    return fetch(apiUrl + "companies/" + id, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization:
                "Bearer " +
                ("; " + document.cookie).split("; accessToken=").pop().split(";").shift(),
        },
        body: JSON.stringify(),
    })
        .then((result) => {
            dispatch(setErrorMessage(result.status));
            dispatch(removeErrorMessage());
            return result.json();
        })
        .then((response) => {
            const { status, data } = response;
            if (status === "success") {
                dispatch({
                    type: "FETCH_SINGLE_COMPANY",
                    payload: data,
                });
            }
            return response;
        });
};

export const createCompany = (company) => (dispatch) => {
    return fetch(apiUrl + "companies", {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization:
                "Bearer " +
                ("; " + document.cookie).split("; accessToken=").pop().split(";").shift(),
        },
        body: JSON.stringify(company),
    })
        .then((result) => {
            dispatch(setErrorMessage(result.status));
            dispatch(removeErrorMessage());
            return result.json();
        })
        .then((response) => {
            const { status, data, message } = response;
            if (status === "success") {
                dispatch(setMessage(message));
                dispatch(removeMessage());
                dispatch({
                    type: "CREATE_COMPANY",
                    payload: data,
                });
            }
            return response;
        });
};

export const editCompany = (company) => (dispatch) => {
    const { id } = company;
    return fetch(apiUrl + "companies/" + id, {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization:
                "Bearer " +
                ("; " + document.cookie).split("; accessToken=").pop().split(";").shift(),
        },
        body: JSON.stringify(company),
    })
        .then((result) => {
            dispatch(setErrorMessage(result.status));
            dispatch(removeErrorMessage());
            return result.json();
        })
        .then((response) => {
            const { status, message } = response;

            if (status === "success") {
                dispatch(setMessage(message));
                dispatch(removeMessage());
                dispatch({
                    type: "EDIT_COMPANY",
                    payload: company,
                });
            }
            return response;
        });
};

export const fetchResponsibilityCenters = (id) => (dispatch) => {
    return fetch(apiUrl + "companies/responsibility-centers/" + id, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization:
                "Bearer " +
                ("; " + document.cookie).split("; accessToken=").pop().split(";").shift(),
        },
        body: JSON.stringify(),
    })
        .then((result) => {
            dispatch(setErrorMessage(result.status));
            dispatch(removeErrorMessage());
            return result.json();
        })
        .then((response) => {
            return response;
        });
};
export const fetchCompanyObjects = (id) => (dispatch) => {
    return fetch(apiUrl + "companies/objects/" + id, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization:
                "Bearer " +
                ("; " + document.cookie).split("; accessToken=").pop().split(";").shift(),
        },
        body: JSON.stringify(),
    })
        .then((result) => {
            dispatch(setErrorMessage(result.status));
            dispatch(removeErrorMessage());
            return result.json();
        })
        .then((response) => {
            return response;
        });
};
