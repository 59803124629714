import React, {useState, useEffect} from 'react';
import {Link} from 'react-router-dom';


import { withRouter } from "react-router-dom";  
import {connect} from 'react-redux'

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField'; 

import Grid from '@material-ui/core/Grid';
import Card from "@material-ui/core/Card";   

import {fetchSaleCars} from "../../../_actions/carsSaleActions"
import {fetchBrands} from "../../../_actions/brandsActions"
import {createModifyOffer, getSingleModifyOffer ,editModifyOffer, dispatchSingleModifyOffer} from "../../../_actions/offersActions"
import {fieldList, fieldListState} from '../../../helpers/modifyOffer'

const  ModifyOffer = ({fetchSaleCars,fetchBrands,createModifyOffer, getSingleModifyOffer ,editModifyOffer, brandsList, match, history, singleModifiedOffer, dispatchSingleModifyOffer}) => {
    const initialState ={ 
        brand_id:'88', 
        factory_grade:''
    }
    const [state, setState] = useState({...fieldListState, ...initialState})
    const [errors, setErrors] = useState({})
    const [editMode, setEditMode] = useState(false)

  
    useEffect(() => {
        fetchSaleCars(1)
        fetchBrands() 
        if (match.params._id) {
            setEditMode(true)
            getSingleModifyOffer(match.params._id);
        }

        return () => {
            dispatchSingleModifyOffer(initialState)
        }
        
    }, []);

 

    useEffect(() => {
        if (match.params._id) {
            setState(singleModifiedOffer)
        }
    }, [singleModifiedOffer]);

    const onChange = (e)=>{
        const {name,value} = e.target
        setState({
            ...state,
            [name]:value
        })
    }
 
 

    const [disableSubmit, setDisableSubmit] = useState(false)
    const onSubmit = (event) =>{
        setDisableSubmit(true)
        event.preventDefault(); 
        if (editMode) {
            editModifyOffer(state).then(data => {  
            if(data.status==="success"){
                setErrors({}) 
                history.push("/dashboard/modify-offer/");
            } 
            else if (data.errors) {
                setErrors(data.errors)   
            } 
            setDisableSubmit(false)
            }) 
        }else{
            createModifyOffer(state).then(data => {  
                if(data.status==="success"){
                    setErrors({}) 
                    history.push("/dashboard/modify-offer/");
                } 
                else if (data.errors) {
                    setErrors(data.errors)   
                } 
                setDisableSubmit(false)
                }) 
        }
    }
    const {brand_id, factory_grade}=state; 
    const pageTitle = editMode ? 'Редактиране' : 'Създаване';  
    return ( 
        <Card >
            <Grid container className="section-title-wrapper padding"> 
                <Grid container item xs={8} alignItems="center" className='m-order-1'> 
                    <h3 className="section-title">
                        <i className="flaticon2-gear"></i> {pageTitle}
                        
                    </h3>
                </Grid>  
                <Grid container item xs={4} alignItems="center" justify="flex-end">  
                    <Link  
                        className="btn btn-secondary"
                        to="/dashboard/modify-offer" 
                        ><i className="flaticon2-circular-arrow"/>
                            <span>Назад</span> 
                        </Link>  
                    </Grid>
            </Grid>  
            <form className='form' noValidate onSubmit={onSubmit} className="padding">
                <Grid container spacing={3}> 
                    <Grid item  xs={4} className="step-wrapper">   
                    {brandsList.length>0&&<FormControl variant="outlined" margin="normal" className='mb-20 full-width select-styles'>
                        <InputLabel id="brand_id">
                            Марка 
                        </InputLabel>
                        <Select  
                           
                            MenuProps={{
                                anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "right"
                                },
                                transformOrigin: {
                                vertical: "top",
                                horizontal: "right"
                                },
                                getContentAnchorEl: null
                            }} 
                            labelId="brand_id"
                            required   
                            inputProps={{
                                name: 'brand_id', 
                            }}  
                            value={brand_id} 
                            onChange={onChange}
                            labelWidth={70}  >    
                            {brandsList.map(option=>{ 
                                return <MenuItem disabled={option.id!==4 && option.id!==88} key={option.id} value={option.id}>{option.name}</MenuItem>  
                            })}
                        
                        </Select> 
                    </FormControl>}  
                    </Grid>  
                    <Grid item xs={4} className="step-wrapper">   
                        <TextField inputProps={{'className':'data-hj-allow'}} className="full-width mb-20" 
                            InputLabelProps={{shrink: true}}  
                            margin="normal" 
                            variant="outlined"   
                            label='База / factory_grade'
                            name='factory_grade'
                            value={factory_grade}
                            error ={errors.factoryGrade?true:false} 
                            helperText={errors.factoryGrade} 
                            onChange={onChange}  
                            disabled={editMode}
                        />
                    </Grid>  
                </Grid>  
                <Grid container spacing={2} >   
                    {Object.keys(fieldList).map((key, i) => {     
                        return(
                            <Grid item md={4} xs={12} key={i}>  
                                <TextField inputProps={{'className':'data-hj-allow'}} className="full-width mb-20" 
                                    InputLabelProps={{shrink: true}}  
                                    margin="normal" 
                                    variant="outlined"   
                                    label={fieldList[key]}
                                    name={key}
                                    value={state[key]}
                                    error ={errors[key]?true:false} 
                                    helperText={errors[key]} 
                                    onChange={onChange}  
                                />
                            </Grid>   
                        ) 
                    })} 
                </Grid>  
                <Grid container spacing={2} >   
                         
                    <Grid container item md={4} xs={12}>    
                        <button type="submit" disabled={disableSubmit} className="btn btn-info full-width">
                        {disableSubmit?<div className="lds-dual-ring"></div>:<i className="flaticon2-add-square"></i>}
                        {editMode ? 'Запази' : 'Създай'}
                        </button>  
                    </Grid> 
                </Grid> 
            </form>  
        </Card >
     );
}
const mapStateToProps = state => {   
    return({
        brandsList:state.brands.items,
        singleModifiedOffer:state.offers.singleModifiedOffer
    })
}
 
 export default withRouter(connect(mapStateToProps, { fetchSaleCars, createModifyOffer,getSingleModifyOffer ,editModifyOffer, fetchBrands, dispatchSingleModifyOffer})(ModifyOffer));

 