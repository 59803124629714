import React, { Component } from 'react';

import { withRouter } from "react-router-dom";
import Grid from '@material-ui/core/Grid';
import {Link} from 'react-router-dom';

import {connect} from 'react-redux'

import MUIDataTable from "mui-datatables"; 
import Card from "@material-ui/core/Card";  

import {columns, adminColums} from "../../../helpers/viewOffers";  
import {options} from "../../../helpers/viewOffers";  
import {storeColumnsFilters, getStoredColumnsFilters} from "../../../helpers/storeColumns";  

import {fetchOffers} from '../../../_actions/offersActions'
 
 
class ListOffers extends Component {
   
    state ={
        permissions: JSON.parse(localStorage.getItem('permissions')),
        roleId: JSON.parse(localStorage.getItem('roleId')),
        data:[], 
        options:{},
        columns:[], 
    } 

    componentDidMount (){
        let columnsArr=[];    
        const roleId = JSON.parse(localStorage.getItem('roleId')); 
        if (roleId===1) {
            columnsArr=[
                ...columns.slice(0, 5),
                ...adminColums,
                ...columns.slice(5)
            ]  
        }else{
            columnsArr=columns
        } 

        this.setState({
            options: storeColumnsFilters('offersTable', options),
            columns: getStoredColumnsFilters('offersTable', columnsArr)
        }) 
        this.props.fetchOffers() 
       
    } 

    componentDidUpdate(previousProps, previousState){ 
        if(previousProps.offers !== this.props.offers){
            const query = new URLSearchParams(this.props.location.search);
            const userId = query.get('userId')
            const contactId = query.get('contactId')
            const clientId = query.get('clientId')
            if (userId) { 
                let filtered = this.props.offers.filter(item=>{ 
                    return item.userId ===userId
                })
                this.setState({data:filtered})
            }else if(contactId){
                let filtered = this.props.offers.filter(item=>{ 
                    return item.contactId ===contactId
                })
                this.setState({data:filtered})
            }else if(clientId){
                let filtered = this.props.offers.filter(item=>{ 
                    return item.clientId ===clientId
                })
                this.setState({data:filtered})
            }else{
                this.setState({data:this.props.offers})
            }
        }
    }

    onDownload = (buildHead, buildBody, columns, data) => {
        return "\uFEFF" + buildHead(columns) + buildBody(data); 
    } 
    
    onRowClick=(rowData , rowMeta, e) => {  
        const {permissions= {}} = this.state; 
        if (permissions.offersUpdate) {
            this.props.history.push("/dashboard/offers/"+rowData[0]);
        }
    }

    render() {  
        const {data, permissions= {}, options, columns, roleId} = this.state;  
  
        return ( 
            <React.Fragment>  
                <Grid container spacing={3} className="mb-20 header-section">
                    <Grid container item xs={6} sm={4} alignItems="center"> 
                        {(roleId!==19 && permissions.offersStore)&&<Link className="btn btn-info" to="/dashboard/create-offer"><i className="flaticon-add-circular-button"/>
                            Създай Оферта
                        </Link>}  
                    </Grid>   
                    <Grid container item xs={6} sm={4} alignItems="center"> 
                        {<Link className="btn btn-info" to="/dashboard/modify-offer"><i className="fas fa-clipboard-check"/>
                        Модификация на Оферти
                        </Link>}  
                    </Grid>   
                    <Grid container item xs={6} sm={4} justify="flex-end"> 
                        {roleId!==19 &&<Link className="btn btn-focus" to="/dashboard/offersForApproval">
                            <i className="fas fa-clipboard-check"></i>
                            Oферти за одобрение
                        </Link>}
                    </Grid>   
                </Grid>    
                <Card>  
                    <MUIDataTable
                        title={"Оферти:"}
                        data={data}
                        columns={columns} 
                        options={{...options, onRowClick: this.onRowClick, onDownload:this.onDownload}}  
                    /> 
                </Card> 
            </React.Fragment> 
         );
    }
}
const mapStateToProps = state => {   
    return({offers:state.offers.items})
}
 
 export default withRouter(connect(mapStateToProps, { fetchOffers })(ListOffers));
