const initialState = {
    items: [],
    list: [],
    listTd: [],
    singleCompany: {},
};

export default function (state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case "GET_COMPANIES_LIST":
            return {
                ...state,
                list: payload,
            };
        case "GET_COMPANIES_LIST_TD":
            return {
                ...state,
                listTd: payload,
            };
        case "FETCH_COMPANIES":
            return {
                ...state,
                items: payload,
            };
        case "FETCH_SINGLE_COMPANY":
            return {
                ...state,
                singleCompany: payload,
            };
        case "CREATE_COMPANY":
            return {
                ...state,
                items: [...state.items, payload],
            };
        case "EDIT_COMPANY":
            return {
                ...state,
                items: state.items.map((company) =>
                    company.id === payload.id ? payload : company
                ),
            };
        default:
            return state;
    }
}
