import { apiUrl} from "../helpers/apiUrl";
import { setMessage, removeMessage, setErrorMessage, removeErrorMessage } from "./msgActions"; 


export const fetchOffers = () => dispatch => { 
    return fetch(apiUrl+'offers', {
        method: 'GET', 
        headers:{ 
            'Content-Type': 'application/json', 
            'Accept': 'application/json',
            'Authorization':'Bearer '+("; "+document.cookie).split("; accessToken=").pop().split(";").shift()
        },
        body: JSON.stringify()
      }).then(result => {
        dispatch(setErrorMessage(result.status));
        dispatch(removeErrorMessage()); 
        return result.json()
    })
    .then(response => {      
        const {status,data} = response;
        if (status === "success") { 
            dispatch({
                type:'FETCH_OFFERS',
                payload:data
            })
        } 
        return response
    });  
}


export const fetchSingleOffer = (id) => dispatch => { 
    return fetch(apiUrl+'offers/'+id, {
        method: 'GET', 
        headers:{ 
            'Content-Type': 'application/json', 
            'Accept': 'application/json',
            'Authorization':'Bearer '+("; "+document.cookie).split("; accessToken=").pop().split(";").shift()
        },
        body: JSON.stringify()
    }).then(result => {
        dispatch(setErrorMessage(result.status));
        dispatch(removeErrorMessage()); 
        return result.json()
    })
    .then(response => {      
        const {status,data} = response;
        if (status === "success") {
            dispatch({
                type:'FETCH_SINGLE_OFFER',
                payload:data
            })
        } 
        return response
    }); 
}
export const getPriceAndAccessoaries = (id) => dispatch => { 
    return fetch(apiUrl+'sales-cars/info/'+id, {
        method: 'GET', 
        headers:{ 
            'Content-Type': 'application/json', 
            'Accept': 'application/json',
            'Authorization':'Bearer '+("; "+document.cookie).split("; accessToken=").pop().split(";").shift()
        },
        body: JSON.stringify()
    }).then(result => {
        dispatch(setErrorMessage(result.status));
        dispatch(removeErrorMessage()); 
        return result.json()
    })
    .then(response => {      
        const {status,data} = response;
        if (status === "success") {
            dispatch({
                type:'FETCH_PRICE_AND_ACCESSOARIES',
                payload:data
            })
        } 
        return response
    }); 
}

export const createOffer = (offer) => dispatch => {   
    return fetch(apiUrl+'offers', { 
        method: 'POST', 
        headers:{ 
            'Content-Type': 'application/json', 
            'Accept': 'application/json',
            'Authorization':'Bearer '+("; "+document.cookie).split("; accessToken=").pop().split(";").shift()
        },
        body: JSON.stringify(offer)
     }).then(result => {
        dispatch(setErrorMessage(result.status));
        dispatch(removeErrorMessage()); 
        return result.json()
     })
    .then(response => {    
        const {status, data, message} = response; 
        if (status === "success") {
            dispatch(setMessage(message));
            dispatch(removeMessage());
            dispatch({
                type:'CREATE_OFFER',
                payload:data
            })
        } 
        return response
    }); 
 
}
export const editOffer = (offer) => dispatch => {   
    const {id}=offer;
    return fetch(apiUrl+'offers/'+id, {
        method: 'PUT', 
        headers:{ 
            'Content-Type': 'application/json', 
            'Accept': 'application/json',
            'Authorization':'Bearer '+("; "+document.cookie).split("; accessToken=").pop().split(";").shift()
        },
        body: JSON.stringify(offer)
    }).then(result => {
        dispatch(setErrorMessage(result.status));
        dispatch(removeErrorMessage()); 
        return result.json()
    })
    .then(response => {    
        const {status, message} = response;


        if (status === "success") { 
            dispatch(setMessage(message));
            dispatch(removeMessage()); 
        } 
        return response
    });   
}

export const fetchModifiedOffers = () => dispatch => { 
    return fetch(apiUrl+'brand-grade-description', {
        method: 'GET', 
        headers:{ 
            'Content-Type': 'application/json', 
            'Accept': 'application/json',
            'Authorization':'Bearer '+("; "+document.cookie).split("; accessToken=").pop().split(";").shift()
        },
        body: JSON.stringify()
      }).then(result => {
        dispatch(setErrorMessage(result.status));
        dispatch(removeErrorMessage()); 
        return result.json()
    })
    .then(response => {      
        const {status,data} = response;
        if (status === "success") { 
            dispatch({
                type:'FETCH_MODIFIED_OFFERS',
                payload:data
            })
        } 
        return response
    });  
}

export const getSingleModifyOffer = (id) => dispatch => { 
    return fetch(apiUrl+'brand-grade-description/'+id, {
        method: 'GET', 
        headers:{ 
            'Content-Type': 'application/json', 
            'Accept': 'application/json',
            'Authorization':'Bearer '+("; "+document.cookie).split("; accessToken=").pop().split(";").shift()
        },
        body: JSON.stringify()
    }).then(result => {
        dispatch(setErrorMessage(result.status));
        dispatch(removeErrorMessage()); 
        return result.json()
    })
    .then(response => {      
        const {status,data} = response;
        if (status === "success") {
            dispatch(dispatchSingleModifyOffer(data))
        } 
        return response
    }); 
}
export const dispatchSingleModifyOffer = (data)  => dispatch=>{
    dispatch({
        type:'FETCH_SINGLE_MODIFIED_OFFER',
        payload:data
    })
}

export const createModifyOffer = (offer) => dispatch => {   
    return fetch(apiUrl+'brand-grade-description', { 
        method: 'POST', 
        headers:{ 
            'Content-Type': 'application/json', 
            'Accept': 'application/json',
            'Authorization':'Bearer '+("; "+document.cookie).split("; accessToken=").pop().split(";").shift()
        },
        body: JSON.stringify(offer)
     }).then(result => {
        dispatch(setErrorMessage(result.status));
        dispatch(removeErrorMessage()); 
        return result.json()
     })
    .then(response => {    
        const {status, data, message} = response; 
        if (status === "success") {
            dispatch(setMessage(message));
            dispatch(removeMessage()); 
        } 
        return response
    }); 
 
}

export const editModifyOffer = (offer) => dispatch => {  
    
    const {id}=offer;
    return fetch(apiUrl+'brand-grade-description/'+id, {
        method: 'PUT', 
        headers:{ 
            'Content-Type': 'application/json', 
            'Accept': 'application/json',
            'Authorization':'Bearer '+("; "+document.cookie).split("; accessToken=").pop().split(";").shift()
        },
        body: JSON.stringify(offer)
    }).then(result => {
        dispatch(setErrorMessage(result.status));
        dispatch(removeErrorMessage()); 
        return result.json()
    })
    .then(response => {    
        const {status, message} = response;


        if (status === "success") { 
            dispatch(setMessage(message));
            dispatch(removeMessage()); 
        } 
        return response
    });   
}

export const setMultipleResolutions = (resolutions) => dispatch => {   
    return fetch(apiUrl+'offers/resolutions-update', {
        method: 'PUT', 
        headers:{ 
            'Content-Type': 'application/json', 
            'Accept': 'application/json',
            'Authorization':'Bearer '+("; "+document.cookie).split("; accessToken=").pop().split(";").shift()
        },
        body: JSON.stringify(resolutions)
    }).then(result => {
        dispatch(setErrorMessage(result.status));
        dispatch(removeErrorMessage()); 
        return result.json()
    })
    .then(response => {    
        const {status, message} = response; 
        if (status === "success") { 
            dispatch(setMessage(message));
            dispatch(removeMessage()); 
        } 
        return response
    });   
}

export const setTempOffer = (offer) => dispatch => {   
    dispatch({
        type:'SET_TEMP_OFFER',
        payload:offer
    }) 
}