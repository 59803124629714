export const storeColumnsFilters = (tableName,options )=>{
    if (!JSON.parse(localStorage.getItem(tableName))) {
        localStorage.setItem(tableName, JSON.stringify({}))
    }
    const local = JSON.parse(localStorage.getItem(tableName))
    let tempTable = local ? local : {}; 
 
    return { 
        onColumnViewChange: (column, action) => {  
            if (action==='add') { 
                tempTable[column]= true
            }else if(action==='remove'){
                tempTable[column]=false
            } 
            localStorage.setItem(tableName, JSON.stringify(tempTable)); 
        },
        ...options
    }
}

export const getStoredColumnsFilters = (tableName, defColumns)=>{

    const local = JSON.parse(localStorage.getItem(tableName)) || {}
    return defColumns.map(item =>{   
         return( 
            {
                label: item.label,
                name: item.name, 
                options:{
                    filter:item.options.filter,
                    sort:item.options.sort,
                    // sortDirection: item.name=='id' && item.options.sortDirection? item.options.sortDirection :'none',
                    customBodyRender:item.options.customBodyRender,
                    display: local.hasOwnProperty(item.name) ? local[item.name] : true, 
                    filterType: item.options.filterType ? item.options.filterType : "dropdown",
                    customFilterListOptions:item.options.customFilterListOptions,
                    filterOptions:item.options.filterOptions
                }
            }  
        ) 
    })
}

