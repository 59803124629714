import React, { Component } from 'react';
import {connect} from 'react-redux'
import Grid from '@material-ui/core/Grid';

import {uploadDocuments, fetchSingleSaleCarsDocuments} from '../../../_actions/carsSaleActions'
import {downloadDocument} from '../../../_actions/downloadDocument'

class Documents extends Component {
    state = {  }

    addFile = (event, type) =>{
        const {singleSaleCar}= this.props;
        this.props.uploadDocuments(type, event.target.files, singleSaleCar.id).then(data => {  
            if(data.status==="success"){
                this.props.fetchSingleSaleCarsDocuments(singleSaleCar.id);
            }
        })
 
    }

    download = (id) =>{ 
        downloadDocument('sales-cars',id)
    }   
    filterDocuments =(docs, type)=>{
        const filtered = docs.filter(i=> i.type==type && i.name.length>0 ) 
        if (filtered.length>0) {
            return( 
                filtered.map(item=>{    
                    return <div className="btn mr-10" onClick={()=>{this.download(item.id)}}>{item.name}</div>
                })
            )
        }else{
            return 'няма качени документи'
        } 
    }


    render() { 
        const {singleSaleCar={}, documents=[]} =this.props;
        const {optionalEquipments=[], standardEquipments=[]}= singleSaleCar;
 
        return (
            <>
            <Grid container className='padding'>
                <Grid container spacing={3}>
                    <Grid item md={3} xs={12} className='text-center'> 
                        <div className='btn uploadDocumentWrap full-width'style={{ 
                            }}> 
                            <input onChange={(e)=> {this.addFile(e, 1); e.target.value=null }}   name="file" multiple  type="file" className="btn btn-warning full-width"/>   
                            <i className="fas fa-upload"></i>
                            СОС 
                        </div>
                    </Grid>
                    <Grid item md={3} xs={12} className='text-center'> 
                        <div className='btn uploadDocumentWrap full-width'style={{ 
                            }}> 
                            <input onChange={(e)=> {this.addFile(e, 2); e.target.value=null }}   name="file" multiple  type="file" className="btn btn-warning full-width"/>   
                            <i className="fas fa-upload"></i>
                            Доставна фактура 
                        </div>
                    </Grid> 
                    <Grid item md={3} xs={12} className='text-center'> 
                        <div className='btn uploadDocumentWrap full-width'style={{ 
                            }}> 
                            <input onChange={(e)=> {this.addFile(e, 3); e.target.value=null }}   name="file" multiple  type="file" className="btn btn-warning full-width"/>   
                            <i className="fas fa-upload"></i>
                            Митническа декларация 
                        </div>
                    </Grid> 
                    <Grid item md={3} xs={12} className='text-center'> 
                        <div className='btn uploadDocumentWrap full-width'style={{ 
                            }}> 
                            <input onChange={(e)=> {this.addFile(e, 4); e.target.value=null }}   name="file" multiple  type="file" className="btn btn-warning full-width"/>   
                            <i className="fas fa-upload"></i>
                            Талон
                        </div>
                    </Grid> 
                </Grid>
                <Grid container spacing={3}>
                    <Grid item md={3} xs={12} className='text-center'> 
                        <div className='btn uploadDocumentWrap full-width'style={{ 
                            }}> 
                            <input onChange={(e)=> {this.addFile(e, 7); e.target.value=null }}   name="file" multiple  type="file" className="btn btn-warning full-width"/>   
                            <i className="fas fa-upload"></i>
                            Поръчка за продажба 
                        </div>
                    </Grid>
                    <Grid item md={3} xs={12} className='text-center'> 
                        <div className='btn uploadDocumentWrap full-width'style={{ 
                            }}> 
                            <input onChange={(e)=> {this.addFile(e, 8); e.target.value=null }}   name="file" multiple  type="file" className="btn btn-warning full-width"/>   
                            <i className="fas fa-upload"></i>
                            Договор за покупко-продажба 
                        </div>
                    </Grid>
                    <Grid item md={3} xs={12} className='text-center'> 
                        <div className='btn uploadDocumentWrap full-width'style={{ 
                            }}> 
                            <input onChange={(e)=> {this.addFile(e, 5); e.target.value=null }}   name="file" multiple  type="file" className="btn btn-warning full-width"/>   
                            <i className="fas fa-upload"></i>
                                Фактура към клиента 
                        </div>
                    </Grid>
                    <Grid item md={3} xs={12} className='text-center'> 
                        <div className='btn uploadDocumentWrap full-width'style={{ 
                            }}> 
                            <input onChange={(e)=> {this.addFile(e, 10); e.target.value=null }}   name="file" multiple  type="file" className="btn btn-warning full-width"/>   
                            <i className="fas fa-upload"></i>
                                Приемо-предавателен протокол
                        </div>
                    </Grid>
                </Grid>
                <Grid container spacing={3}>
                    <Grid item md={3} xs={12} className='text-center'> 
                        <div className='btn uploadDocumentWrap full-width'style={{ 
                            }}> 
                            <input onChange={(e)=> {this.addFile(e, 11); e.target.value=null }}   name="file" multiple  type="file" className="btn btn-warning full-width"/>   
                            <i className="fas fa-upload"></i>
                            Копие на голям талон 
                        </div>
                    </Grid>
                </Grid>
            
            
                <Grid container spacing={3}>
                    <table style={{'marginTop':'35px'}} className='custom-table documents'>
                        <thead>
                            <tr>
                                <th>Tип:</th>
                                <th>Документ:</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>COC:</td>
                                <td>{this.filterDocuments(documents,1)}</td>
                            </tr>
                           
                            <tr>
                                <td>Митническа декларация:</td>
                                <td>{this.filterDocuments(documents,3)}</td>
                            </tr> 
                            <tr>
                                <td>Талон от страната, в която е регистриран автомобилът:</td>
                                <td>{this.filterDocuments(documents,4)}</td>
                            </tr>  
                            <tr>
                                <td>Копие на голям талон:</td>
                                <td>{this.filterDocuments(documents,11)}</td>
                            </tr> 
                            <tr>
                                <td>Доставна фактура:</td>
                                <td>{this.filterDocuments(documents,2)}</td>
                            </tr> 
                            <tr>
                                <td>Фактура към клиента:</td>
                                <td>{this.filterDocuments(documents,5)}</td>
                            </tr> 
                            <tr>
                                <td>Проформа фактура:</td>
                                <td>{this.filterDocuments(documents,6)}</td>
                            </tr> 
                            <tr>
                                <td>Поръчка за продажба:</td>
                                <td>{this.filterDocuments(documents,7)}</td>
                            </tr> 
                            <tr>
                                <td>Договор за покупко-продажба:</td>
                                <td>{this.filterDocuments(documents,8)}</td>
                            </tr> 
                             <tr>
                                <td>Оферта:</td>
                                <td>{this.filterDocuments(documents,9)}</td>
                            </tr> 
                            <tr>
                                <td>Приемо-предавателен протокол:</td>
                                <td>{this.filterDocuments(documents,10)}</td>
                            </tr>  
                          
                            
                        </tbody>
                    </table>
                </Grid>
            </Grid>
            
              
            </>
          );
    }
}
const mapStateToProps = (state, props) => {  
    return{ 
        singleSaleCar:state.carsSale.singleSaleCar,  
        documents:state.carsSale.singleSaleCarDocuments,  
    } 
}
export default connect(mapStateToProps, {uploadDocuments ,fetchSingleSaleCarsDocuments})(Documents);

