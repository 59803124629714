const initialState = {
    accessToken:("; "+document.cookie).split("; accessToken=").pop().split(";").shift(),
    isAuthenticated:localStorage.getItem('isAuthenticated'),
    roleId:localStorage.getItem('roleId'),
    adminName:localStorage.getItem('name'),
    companyId: localStorage.getItem('companyId'),
    companyName: localStorage.getItem('companyName'),
    permissions: localStorage.getItem('permissions'),
    code: localStorage.getItem('code')
}
     
const date = new Date(new Date().getTime()+60*60*1000*24*365).toGMTString()
 
const authReducer = (state = initialState, action)=>{
    const {type, payload} = action;
  
    
    switch(type){
        case 'LOGIN_SUCCESS':  
            document.cookie= 'accessToken='+payload.accessToken + '; expires=' + date + '; path=/ ';
            localStorage.setItem('token', payload.accessToken);
            localStorage.setItem('isAuthenticated', true);
            localStorage.setItem('code', payload.code || "");
            localStorage.setItem('roleId', payload.roleId);
            localStorage.setItem('name', payload.name);
            localStorage.setItem('companyId', payload.companyId);
            localStorage.setItem('companyName', payload.companyName);
            localStorage.setItem('permissions', JSON.stringify(payload.permissions || {}));
            return{
                ...state,       
                ...payload,   
                isAuthenticated:true,          
            }
        case 'LOGIN_FAIL':
            localStorage.removeItem('token');
            document.cookie = "accessToken=; expires=Thu, 01 Jan 1970 00:00:00 UTC;";
            document.cookie = "accessToken=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/";
            localStorage.removeItem('isAuthenticated'); 
            localStorage.removeItem('roleId');
            localStorage.removeItem('name')
            localStorage.removeItem('companyId')
            localStorage.removeItem('companyName')
            localStorage.removeItem('permissions')
            localStorage.removeItem('code')
            return{     
                accessToken:null, 
                refreshToken:null, 
                isAuthenticated:null,     
                roleId:null
            }
        case 'LOGOUT':
            document.cookie = "accessToken=; expires=Thu, 01 Jan 1970 00:00:00 UTC;";
            document.cookie = "accessToken=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/";
            localStorage.removeItem('isAuthenticated'); 
            localStorage.removeItem('roleId');
            localStorage.removeItem('name')
            localStorage.removeItem('companyId')
            localStorage.removeItem('companyName')
            localStorage.removeItem('permissions')
            localStorage.removeItem('code')
            return{  
                accessToken:null, 
                refreshToken:null, 
                isAuthenticated:null,     
                roleId:null 
            }
        default:
            return state;
    }
}

export default authReducer