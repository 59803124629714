import React, { Component } from 'react';
 
import { withRouter } from "react-router-dom";
import Grid from '@material-ui/core/Grid';
import {Link} from 'react-router-dom';

import {connect} from 'react-redux'

import MUIDataTable from "mui-datatables"; 
import Card from "@material-ui/core/Card";  
import {downloadDocument} from '../../../_actions/downloadDocument'
import {sendOrderIdToNav} from '../../../_actions/ordersActions'

import {options} from "../../../helpers/viewОrders";    
import {storeColumnsFilters, getStoredColumnsFilters} from "../../../helpers/storeColumns"; 
import {fetchOrders} from '../../../_actions/ordersActions'



class OrdersList extends Component {
    state={
        permissions: JSON.parse(localStorage.getItem('permissions')),
        data:[], 
        options:{},
        columns:[], 
        columnsResendLoad:[]
     }

    componentDidMount (){
 
        this.setState({
            options: storeColumnsFilters('ordersTable', options)
        }) 
        this.props.fetchOrders() 
    } 

    btnClick = (id)=>{  
        const {columnsResendLoad}=this.state;

        this.setState({
            columnsResendLoad:[
                ...columnsResendLoad,
                {id, loading:true}
            ]
        }) 
        this.props.sendOrderIdToNav(id).then(data => {
            if(data.status==="success"){
                this.props.fetchOrders() 
            } 
            this.setState({ 
                columnsResendLoad: columnsResendLoad.map(el => (el.id === id ? {...el, loading:false} : el))
            });
        }); 
    }

    componentDidUpdate(previousProps, previousState){ 
        if(previousProps.orders !== this.props.orders){
            const query = new URLSearchParams(this.props.location.search);
            const userId = query.get('userId')
            const contactId = query.get('contactId')
            const clientId = query.get('clientId')
            if (userId) { 
                let filtered = this.props.orders.filter(item=>{ 
                    return item.userId === userId
                })
                this.setState({data:filtered})
            }else if(contactId){
                let filtered = this.props.orders.filter(item=>{ 
                    return item.contactId === contactId
                })
                this.setState({data:filtered})
            }else if(clientId){
                let filtered = this.props.orders.filter(item=>{ 
                    return item.clientId === clientId
                })
                this.setState({data:filtered})
            }else{
                this.setState({data:this.props.orders})
            }
        }
    }

    onDownload = (buildHead, buildBody, columns, data) => {
        return "\uFEFF" + buildHead(columns) + buildBody(data); 
    } 
  
    render() {  
    
    const columns = [ 
        {
            label: "ID:",
            name: "id",
            options: {
                filter: false, 
                sort:true
            }
        }, 
        {
            label: "Дата:",
            name: "orderDate",
            options: {
                filter: false,
                sort: true,
            }
        }, 
        // {
        //     label: "Статус:",
        //     name: "status",
        //     options: {
        //         filter: true,
        //         sort: true,
        //         customBodyRender: (value, tableMeta, updateValue) => {
        //             if (value==='0') {
        //                 return 'Създаден' 
        //             }else if (value==='1') {
        //                 return 'Очаква одобрение' 
        //             }else if (value==='2') {
        //                 return 'Одобрен' 
        //             }else if (value==='3') {
        //                 return 'Подписан' 
        //             }
        //         }
        //     }
        // },
        {
            label: "Tърговец:",
            name: "traderName",
            options: {
                filter: true,
                sort: true, 
            }
        },
        {
            label: "Клиент:",
            name: "client",
            options: {
                filter: false,
                sort: true,
            }
        },
        {
            label: "Контакт:",
            name: "contactName",
            options: {
                filter: true,
                sort: true,
            }
        },   
        {
            label: "НАВ №:",
            name: "navId",
            options: {
                filter: true,
                sort: true, 
            }
        },   
        {
            label: "Resend:",
            name: "",
            options: {
                filter: false,
                sort: false,
                customBodyRender: (value, tableMeta, updateValue) => {   
                    const {columnsResendLoad}=this.state;
                    let id = tableMeta.rowData[0];
                    const currentLoading = columnsResendLoad.find(x => x.id === id)==undefined ? false : columnsResendLoad.find(x => x.id === id).loading 
               
                  
                    return <button disabled={currentLoading} onClick={()=>this.btnClick(id)}
                    className='small btn'><i className={"fas fa-sync-alt mr-0 "+(currentLoading?'loading':'')}></i></button>
                
                }
            }
        },   

        {
            label: "Поръчка:",
            name: "agreementPdf",
            options: {
                filter: false,
                sort: false,
                customBodyRender: (value, tableMeta, updateValue) => {  
                    let id = tableMeta.rowData[0];
                    return <div onClick={()=>downloadDocument('order',id)}
                    className='small btn'><i className='fas fa-file-signature'></i>Виж Поръчка</div>
                }
            }
        },   
    
        {
            label: "Редактирай:",
            name: "edit",
            options: {
                filter: false,
                sort: false, 
                customBodyRender: (value, tableMeta, updateValue) => {  
                    let id = tableMeta.rowData[0];
                    const permissions = JSON.parse(localStorage.getItem('permissions'))
                    if (permissions.ordersUpdate) {
                        return <Link to={'/dashboard/orders/'+id} className='small btn btn-info'><i className='flaticon-edit'></i>Редактирай</Link>
                    }else{
                        return 'нямате достъп'
                    }
                    
                }
            }
        },   
        
    ];
        const {data} = this.state; 
        const {options} = this.state; 
        return (  
            <React.Fragment>  
                <Grid container className="mb-20">
                    <Grid container item xs={6} sm={6} alignItems="center"> 
                    
                    </Grid>   
                </Grid>    
                <Card>  
                    <MUIDataTable
                        title={"Поръчки:"}
                        data={data}
                        columns={columns} 
                        columns={getStoredColumnsFilters('ordersTable', columns)} 
                        options={{...options, onDownload:this.onDownload}}    
                    /> 
                </Card> 
            </React.Fragment> 
         );
    }
}
 
const mapStateToProps = state => {   
   return({orders:state.orders.items})
}

export default withRouter(connect(mapStateToProps, { fetchOrders, sendOrderIdToNav })(OrdersList));
// export default ClientsList;