import React, { Component } from 'react';
import {connect} from 'react-redux'
import Grid from '@material-ui/core/Grid';

class Equipment extends Component {
    state = {  }

    render() { 
        const {singleSaleCar={}} =this.props;
        const {optionalEquipments=[], standardEquipments=[], vehicle}= singleSaleCar;
        const {brandId} = vehicle
        const typesNames=['','Произв. опция','Собствена опция','Продукт','Аксесоар']
        const optionTypesNames=['Опция','Цвят','Тапицерия','Табло','Задължит. опция', 'Собствена опция']
 
        return (
            <>
            <Grid container className="padding cars-form" spacing={3}>
                <Grid item md={6} xs={12}>
                    <div className="equipment-wrapper">
                        <table className='custom-table equipment'>
                            <thead>
                                <tr>
                                    <th>Стандартно оборудване:</th>
                                    <th>Цена:</th>
                                </tr>
                            </thead>
                            <tbody>
                                {standardEquipments.map((item, i)=><tr key={i}><td>{item.name}</td><td>{item.price}</td></tr>)}
                                {standardEquipments.length<1&&<tr><td>няма резултати</td></tr>} 
                            </tbody>
                        </table>
                    </div>
                </Grid>
                <Grid item md={6} xs={12}>
                    <div className="equipment-wrapper">
                        <table className='custom-table equipment'>
                            <thead>
                                <tr>
                                    <th>Допълнително оборудване:</th>
                                    <th>Тип:</th>
                                    <th>Option Type:</th>
                                    <th>Цена:</th>
                                </tr>
                            </thead>
                            <tbody>
                                {optionalEquipments.map((item, i)=><tr key={i}>
                                    <td>{item.name}</td>
                                    {brandId===1&&<td>{typesNames[Number(item.type)-1]}</td>}
                                    {brandId===1&&<td>{optionTypesNames[Number(item.optionType)-1]}</td>}
                                    <td>{item.price}</td>
                                </tr>)} 
                                {optionalEquipments.length<1&&<tr><td>няма резултати</td></tr>} 
                            </tbody>
                        </table>
                    </div>
                </Grid>
            </Grid>
            </>
          );
    }
}
const mapStateToProps = (state, props) => {  
    return{ 
        singleSaleCar:state.carsSale.singleSaleCar,  
    } 
}
export default connect(mapStateToProps, { })(Equipment);

