import React, { Component } from 'react'; 
import {Link} from 'react-router-dom';

import {connect} from 'react-redux'


import Grid from '@material-ui/core/Grid';
import Card from "@material-ui/core/Card";   
 

import ContactInfo from './ContactInfo'
import CarInfo from './CarInfo'
import Acessories from './Acessories'
import Prices from './Prices' 

import {fetchFormUserPermissions} from '../../../_actions/rolesActions'
import {fetchSingleSaleCars} from '../../../_actions/carsSaleActions'
import {fetchClientsAutoComplete} from '../../../_actions/clientsActions'
import {fetchSingleTestDrive} from '../../../_actions/driveListActions'
import {fetchSingleContact} from '../../../_actions/contactsActions'
import {fetchSingleOffer, getPriceAndAccessoaries, createOffer, editOffer} from '../../../_actions/offersActions'
import {validator} from '../../../helpers/validator'

import {downloadDocument} from '../../../_actions/downloadDocument'

class EditOffer extends Component { 
    state={
        data:{
            id:'',
            accessories:{
                brand:[], 
                model:[]
            },
            resolutionsStatus:'',
        },
        equipments:[],
        accTaken:false,
        editMode:false,
        client_Id:'',
        activeStep:1,   
        errors:[],
        unlockedSteps:false,
        refresh:false,
        disableSubmit:false, 
        userPermisions: JSON.parse(localStorage.getItem('permissions')),
        roleId: JSON.parse(localStorage.getItem('roleId'))
    }

    componentDidMount(){  
        this.props.fetchFormUserPermissions();
        const query = new URLSearchParams(this.props.location.search);
        const selected_car = query.get('selected_car')
        
        if (this.props.match.params._id) {
            this.setState({ 
                editMode:true,
                data:{
                    ...this.state.data,
                    id:this.props.match.params._id
                }
            }) 
            
            if (selected_car) {   

            }else{ 
                this.props.fetchSingleOffer(this.props.match.params._id).then(data=>{
                    if(data.status==="success"){  
                        this.getPriceAndAcc(1, data.data.car.carId)
                    }
                });   
            }
        } 

      
        // if (selected_car && Object.keys(this.props.tempOffer).length>0) { 
        if (selected_car) { 
            
            this.props.fetchSingleSaleCars(selected_car).then(data => { 
                if(data.status==="success"){  
                    
                    const {optionalEquipments={}, id, brand,brandId, model,modelId ,engineType,externalColorDescription,vin,inventoryNumber }=data.data.vehicle
                    const { transmission }=data.data.technical
                    const { listPrice }=data.data.price 

                    let eq = data.data.optionalEquipments;
                    let equipmentsDisalbed = eq.map(item=>{
                         return {...item, disabled:item.checked ?true:false}
                    })
                    
                   
                    let equipments=equipmentsDisalbed.sort((a, b) =>{ 
                        return Number(a.type) - Number(b.type) 
                    });  
                    this.setState({ 
                        data:{ 
                            ...this.state.data,
                            ...this.props.tempOffer,
                            equipments,
                            car:{
                                ...this.state.data.car,
                                brand,brandId, model,modelId,transmission,engineType,externalColorDescription,vin,inventoryNumber,listPrice,carId:id
                            },
                            accessories:{
                                brand:[],
                                model:[]
                            },
                        },
                        equipments:[],
                        unlockedSteps:true,
                        activeStep:2,
                        selected_car:true
                    })
                }  
            })
        }
        
        const contactId = query.get('contactId');
        if(contactId){ 
            this.props.fetchSingleContact(contactId).then(data => { 
                if(data.status==="success"){
                    this.setState({
                        data:{
                            ...this.state.data,
                            contact:data.data
                        }
                    }) 
                } 
            }) 
        }  

        this.unlisten = this.props.history.listen((location, action) => {
        
            const query = new URLSearchParams(location.search);
            const refresh = query.get('refresh');
            if(refresh){  
                this.props.fetchSingleOffer(this.props.match.params._id);   
                this.setState({editMode:true},()=>{
                    this.switchStep(4)
                }) 
            }  
        });

        

        const testdriveId = query.get('testdriveId');
        if(testdriveId){
            this.props.fetchSingleTestDrive(testdriveId).then(data => { 
                if(data.status==="success"){
                    const {clientId,drivingLicense,drivingLicenseDate,egn,email,idCardDate,idCardNumber,idCardPublishedBy, name, phone, createdBy}=data.data;
                    this.setState({
                        data:{
                            ...this.state.data,
                            contact:{
                                ...this.state.data.contact,
                                contacts:[],
                                id:clientId,drivingLicense,drivingLicenseDate,egn,email,idCardDate,idCardNumber,idCardPublishedBy, name, phone, createdBy
                            }
                        }
                    }) 
                    
                } 
            }) 
        }  
    }

    componentWillUnmount() {
        this.unlisten();
    }
   

    componentDidUpdate(previousProps, previousState){
        if(previousProps.offer !== this.props.offer){  
      
            this.setState({
                data:this.props.offer
            })
            if (this.props.offer.car.carId) {
                this.setState({unlockedSteps:true})
            }
        } 
    }

    getClientAutocomplete = (name, type) => {
        this.props.fetchClientsAutoComplete(name, type);   
    }
 
    handleData=(obj, data)=>{   
        if (obj==='none') {
            this.setState({
                data:{
                    ...this.state.data,
                    ...data 
                }, 
            }) 
        }else{ 
            this.setState({
                data:{
                    ...this.state.data,
                    [obj]:data 
                }, 
            
            })
        }  
    }
  
    onChange =(event)=>{
        const {name, value} = event.target;
        this.setState({ 
            data:{
                ...this.state.data,
                [name]:value  
            }
        })
    } 

    switchStep = (stepNumber) => {  
        const {unlockedSteps, editMode, activeStep}=this.state;
        if (activeStep===1) {
            this.checkForEmptyFields(stepNumber)
        }
        else if (stepNumber==4&&editMode) {   
            this.setState({activeStep:stepNumber}) 
        } 
        else if ((stepNumber==3 || stepNumber==4)&&!unlockedSteps) {  
             
        }else if (stepNumber==3 && unlockedSteps) {   
            const {accTaken}=this.state;
            if (!accTaken) {
                this.getPriceAndAcc(stepNumber, this.state.data.car.carId)
            }else{ 
                this.setState({activeStep:stepNumber}) 
            }
        }else{ 
            this.setState({activeStep:stepNumber}) 
        }
    } 

    getPriceAndAcc = (stepNumber, carId)=>{
        this.props.getPriceAndAccessoaries(carId).then(data => {  
            if(data.status==="success"){   
                let brand=[
                    ...this.state.data.accessories.brand,
                    ...data.data.accessories.brand
                ]

                let model=[
                    ...this.state.data.accessories.model,
                    ...data.data.accessories.model 
                ]
                brand= brand.filter((v,i,a)=>a.findIndex(t=>(t.price === v.price && t.name===v.name))===i)
                model= model.filter((v,i,a)=>a.findIndex(t=>(t.price === v.price && t.name===v.name))===i)
        
                let eq = data.data.optionalEquipments;
                let equipmentsDisalbed = eq.map(item=>{
                     return {...item, disabled:item.checked ?true:false}
                })
                let eqDuplicates=[];
                let withoutDuplicates= [];
                let eqState = this.state.data.equipments; 

                if (eqState.length>0) {
                    for(let i = 0; i < equipmentsDisalbed.length; i++) {
                        for(let j = 0; j< eqState.length; j++) {
                            if(equipmentsDisalbed[i].name == eqState[j].name) {  
                                if (equipmentsDisalbed[i].checked) {
                                    eqDuplicates.push({...eqState[j],disabled:true}) 
                                }else{ 
                                    eqDuplicates.push({...eqState[j]})
                                }   
                            }  
                        } 
                    }  
                    const resultMap = new Map(equipmentsDisalbed.map((item) => [item['name'], item]));
                    eqDuplicates.forEach((item) => {
                        const mapItem = resultMap.get(item['name']);
                        if (mapItem) Object.assign(mapItem, item);
                        else resultMap.set(item['name'], item);
                    });
                    withoutDuplicates = [...resultMap.values()];
                }else{
                    withoutDuplicates = equipmentsDisalbed
                }
               
                let equipments=withoutDuplicates.sort((a, b) =>{ 
                    return Number(a.type) - Number(b.type) 
                });    
                
                this.setState({
                    activeStep:stepNumber,
                    accTaken:true,
                    data:{  
                        ...this.state.data, 
                        accessories:{
                            brand:brand,
                            model:model
                        }, 
                        equipments,
                        carImages:data.data.carImages, 
                        // image:data.data.image,
                        lightTruck:data.data.lightTruck,
                        accessoryImage:data.data.accessoryImage,
                        price:{
                            ...this.state.data.price,
                            ...data.data.price
                        }, 
                    },
                }) 
            }   
        })   
    }

    nextStep = (id, step) => {
        if (this.state.data.car.carId) {
            this.setState({unlockedSteps:true},
                ()=>{this.switchStep(step)}
            )
     
        } 
    } 
    
    checkForEmptyFields = (id)=>{ 
        const {name='', phone='', email='', title=''}=this.state.data.contact || {};
        const fields = {name, phone, email,title} 
        if ( Object.keys(validator(fields)).length) {
            this.setState({errors:{contact:validator(fields)}}) 
        }else{
            this.setState({activeStep:id, errors:{}})
        } 
    }

    createContract =(id)=>{
        this.props.history.push("/dashboard/create-order/?offerId="+id);
    }

    onSubmit = (event) =>{
        this.setState({disableSubmit:true})
        event.preventDefault();
        const {editMode} = this.state;
        const {company,  ...foo } = this.state.data; 
        const stateFiltered = {...foo}
        if (editMode) {
  
            this.props.editOffer(stateFiltered).then(data => {  
                if(data.status==="success"){
                    this.setState({errors:[]}) 
                    this.switchStep(4)
                } 
                else if (data.errors) {
                    this.setState({errors:data.errors})   
                }  
                this.setState({disableSubmit:false}) 
            }) 
        }else{
         
            this.props.createOffer(stateFiltered).then(data => { 
                if(data.status==="success"){  
                    this.setState({
                        data:{},
                        errors:[]
                    }) 
                    this.props.history.push("/dashboard/offers/"+data.data.id+"/?refresh=true");
                } 
                else if (data.errors) {
                    this.setState({errors:data.errors})   
                    // this.switchStep(1)
                } 
                
                this.setState({disableSubmit:false}) 
            }) 
        }
    }

    forSecondApproval = (event) =>{
        this.setState({disableSubmit:true})
        event.preventDefault();
        const {editMode, userPermisions} = this.state;
        const {company,  ...foo } = this.state.data; 
       
        let stateFiltered = foo 
     
        const {resolutions=[]} = stateFiltered || {}
    
        const updatedResolution = resolutions.map(item=>{
            if (Boolean(userPermisions[item.permissionName])) {
                return ({resolutionStatus:item.resolutionStatus=2, ...item})
            } 
        })
 
        stateFiltered = {...stateFiltered,resolutions:updatedResolution} 
        if (editMode) { 
            this.props.editOffer(stateFiltered).then(data => {  
                if(data.status==="success"){
                    this.setState({errors:[]}) 
                    this.switchStep(4)
                } 
                else if (data.errors) {
                    this.setState({errors:data.errors})   
                }  
                this.setState({disableSubmit:false}) 
            }) 
        }else{
         
            this.props.createOffer(stateFiltered).then(data => { 
                if(data.status==="success"){  
                    this.setState({
                        data:{},
                        errors:[]
                    }) 
                    this.props.history.push("/dashboard/offers/"+data.data.id+"/?refresh=true");
                } 
                else if (data.errors) {
                    this.setState({errors:data.errors})   
                    // this.switchStep(1)
                } 
                
                this.setState({disableSubmit:false}) 
            }) 
        }
    }


    render() { 
        const {editMode, refresh, activeStep,unlockedSteps, errors, data,disableSubmit, roleId}= this.state; 
        const {id, resolutionsStatus}= this.state.data;
        const pageTitle = editMode ? 'Редактиране на Оферта:' : 'Създаване на Оферта:'; 
        const {clients, priceAndAcessoaries}=this.props; 
 
        return (  
  
            <>  
                <Card >
                    <Grid container className="section-title-wrapper padding"> 
                        <Grid container item md={4} xs={8} alignItems="center" className='m-order-1'> 
                            <h3 className="section-title">
                                <i className="flaticon2-gear"></i> {pageTitle}
                                
                            </h3>
                        </Grid>  
                        <Grid container alignItems="center" item md={4} xs={12} className='m-order-3'>    
                            {resolutionsStatus===1&&roleId!==19 &&<div className="btn btn-focus full-width" onClick={()=>{this.createContract(id)}}>
                                <i className="flaticon2-add-square"></i>Създай поръчка
                            </div>}    
                        </Grid> 
                       <Grid container item md={4} xs={4} alignItems="center" className='m-order-2' justify="flex-end">  
                            <Link  
                                className="btn btn-secondary"
                                to="/dashboard/offers" 
                                ><i className="flaticon2-circular-arrow"/>
                                <span>Назад</span> 
                            </Link>   
                        </Grid>
                    </Grid>  
                    <form  className='form' noValidate onSubmit={this.onSubmit} className="padding">
                        <Grid container spacing={3}> 
                            <Grid item md={12} xs={12} className="step-wrapper">   
                                <div className={"single-step " +(activeStep === 1 ?"active ":"") + (errors.contact ?"error":"")} 
                                    onClick={()=>{this.switchStep(1)}}>
                                    <div className='step'>
                                        <i className="flaticon2-user"></i>
                                    </div>
                                    <p className="step-title">
                                        Информация за клиента
                                    </p>
                                </div> 
                                <div className='separator'></div>
                                <div className={"single-step " +(activeStep === 2 ?"active ":"")+(errors.car ?"error":"")} 
                                    onClick={()=>{this.switchStep(2)}}>
                                    <div className='step'>
                                        <i className="fas fa-car"></i>
                                    </div>
                                    <p className="step-title">
                                        Информация за автомобила
                                    </p>
                                </div> 
                                <div className='separator'></div>
                                <div className={"single-step " +(activeStep === 3 ?"active ":"") + (unlockedSteps?'':'locked ')} 
                                    onClick={()=>{this.switchStep(3)}}>
                                    <div className='step'>
                                        <i className="flaticon-cogwheel-1"></i>
                                    </div>
                                    <p className="step-title">
                                        Аксесоари към автомобила
                                    </p>
                                </div> 
                                <div className='separator'></div>
                                <div className={"single-step " +(activeStep === 4 ?"active ":"") + (unlockedSteps?'':'locked ')+(errors.price ?"error":"")} 
                                    onClick={()=>{this.switchStep(4)}}>
                                    <div className='step'>
                                        <i className="flaticon-price-tag"></i>
                                    </div>
                                    <p className="step-title">
                                        Цена
                                    </p>
                                </div>  
                            </Grid>  
                        </Grid> 
                        <br/><br/>
                        {activeStep === 1 && <ContactInfo  
                            offer={data}
                            switchStep={this.switchStep} 
                            clients={clients}
                            editMode={editMode}
                            handleData={this.handleData}
                            getClientAutocomplete={this.getClientAutocomplete}
                            errors={errors.contact}
                        /> }
                        {activeStep === 2 &&<CarInfo
                            offer={data} 
                            nextStep={this.nextStep}
                            editMode={editMode}
                            handleData={this.handleData} 
                            errors={errors.car}
                        />}
                        {activeStep === 3 &&<Acessories
                            offer={data}
                            nextStep={this.nextStep}
                            priceAndAcessoaries={priceAndAcessoaries}
                            handleData={this.handleData}   
                            errors={errors}
                            editMode={editMode}
                      
                        />}
                        {activeStep === 4 &&<><Prices
                            offer={data}
                            priceAndAcessoaries={priceAndAcessoaries}
                            nextStep={this.nextStep}
                            handleData={this.handleData}   
                            errors={errors.price}
                            editMode={editMode}
                        />
                        <Grid container spacing={2} >   
                            {resolutionsStatus!==0&&
                            <Grid container item md={4} xs={12}>    
                                {roleId!==19 &&<button type="submit" disabled={disableSubmit} className="btn btn-info full-width">
                                {disableSubmit?<div className="lds-dual-ring"></div>:<i className="flaticon2-add-square"></i>}
                                ЗАПАЗИ
                                </button>}   
                            </Grid> 
                            }
                            {resolutionsStatus==0&& 
                            <Grid container item md={4} xs={12}>    
                                <button type="button" onClick={this.forSecondApproval} disabled={disableSubmit} className="btn btn-info full-width">
                                {disableSubmit?<div className="lds-dual-ring"></div>:<i className="flaticon2-add-square"></i>}
                                ЗА ОДОБРЕНИЕ
                                </button>   
                            </Grid> 
                            }
                            
                            {(resolutionsStatus!==2&&editMode)&&<Grid container item md={4} xs={12}>     
                                <div className="btn btn-focus full-width" 
                                onClick={()=>downloadDocument('offer',this.props.match.params._id)}><i className="fas fa-download"></i> Свали документ</div>
                            </Grid> }
                          
                        </Grid> 
                        </>
                        } 
                        
                    </form> 
                </Card> 
            </>
         );
    }
}
 
const mapStateToProps = (state, props) => {     
    return{
        offer:state.offers.singleOffer,  
        clients:state.clients.clientsAutoComplete, 
        priceAndAcessoaries:state.offers.priceAndAcessoaries,   
        tempOffer:state.offers.tempOffer,   
    }
}

export default connect(mapStateToProps, {fetchFormUserPermissions, fetchSingleSaleCars, fetchSingleTestDrive, fetchSingleContact, fetchSingleOffer, fetchClientsAutoComplete, getPriceAndAccessoaries, createOffer, editOffer })(EditOffer);  
