import React, { Component } from 'react';
import {connect} from 'react-redux'
import Grid from '@material-ui/core/Grid'; 
import TextField from '@material-ui/core/TextField'; 
 

import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from '@material-ui/core/InputLabel';

import MomentUtils from "@date-io/moment";
import moment from "moment";
import {MuiPickersUtilsProvider, KeyboardDatePicker} from '@material-ui/pickers';

import {updateSingleSaleCarsState} from '../../../_actions/carsSaleActions'
import {salesCarsNames} from '../../../helpers/formsPermissions'
 
class Administration extends Component {
    
    state={
        data:this.props.data ? this.props.data :{}, 
    }  

    onChange = (event, index) =>{ 
        const {name,value} = event.target;   
        this.setState({
            data:{
                ...this.state.data,
                [name]:value
            }
        })   
    }
    
    componentWillUnmount(){
        this.props.updateSingleSaleCarsState('administration', this.state.data)
    }

    componentDidUpdate(previousProps, previousState){ 
        if(previousProps.data !== this.props.data){
            this.setState({
                data:this.props.data
            })
        }

        if(previousProps.pushToState !== this.props.pushToState){
            if (this.props.pushToState) {
                this.props.updateSingleSaleCarsState('administration', this.state.data)
            } 
        }
    } 


    onChangeDate = (input, date, value) => {   
        this.setState({
            data:{
                ...this.state.data, 
                [input]:value 
            }  
        })  
    }

    render() {  
        const {data} = this.state;  
        const {formUserPermissions={},errors={}}=this.props; 
        const {administration={}} = errors
        return (  
            
            <>    
                <Grid container spacing={3}> 
                    {Object.keys(data).map((key, i) => {  
                        if (formUserPermissions['administration.'+key]) {
                            if (key==='guaranteeType') { 
                                return( 
                                    <Grid item md={3} xs={12} key={i}>  
                                         <FormControl variant="outlined" error={administration[key]?true:false} margin="normal" className='mb-20 full-width select-styles'>
                                            <InputLabel id={key}>
                                                {salesCarsNames['administration.'+key]}
                                            </InputLabel>
                                            <Select  
                                                MenuProps={{anchorOrigin: {vertical: "bottom",horizontal:"right"},transformOrigin: { vertical: "top",horizontal: "right"},getContentAnchorEl: null}} 
                                                labelId={key}
                                                required   
                                                inputProps={{ name: key}}  
                                                value={data[key]} 
                                                onChange={(e)=>this.onChange(e, i)}  
                                                labelWidth={100}  >    
                                                <MenuItem value='0'>Cтандартна</MenuItem>  
                                                <MenuItem value='1'>Удължена</MenuItem>   
                                            </Select>
                                            {administration[key]&&<FormHelperText>{administration[key]}</FormHelperText>}
                                        </FormControl>   
                                    </Grid>   
                                
                                ) 
                            }else if(key==='dateSosRequires'){    
                                return(
                                    <Grid item md={3} xs={12} key={i}>  
                                        <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}   >
                                            <KeyboardDatePicker inputProps={{'className':'data-hj-allow'}} 
                                                autoOk={true}
                                                showTodayButton={true} 
                                                InputLabelProps={{shrink: true}} 
                                                className="full-width date mb-20"  
                                                inputVariant="outlined" 
                                                margin="normal"
                                                label={salesCarsNames['administration.'+key]} 
                                                format="DD.MM.YYYY" 
                                                value=''
                                                inputValue={data[key]} 
                                                onChange={(e,d)=>this.onChangeDate(key, e ,d)}
                                                rifmFormatter={this.dateFormatter} 
                                                error ={administration[key]?true:false}  
                                                helperText={administration[key]} 
                                            />
                                        </MuiPickersUtilsProvider>
                                    </Grid> 
                                ) 
                            }else if(key==='guarantee'){
                                if (data['guaranteeType']===1) {
                                    return(
                                        <Grid item md={3} xs={12} key={i}>  
                                            <TextField inputProps={{'className':'data-hj-allow'}} className="full-width mb-20" 
                                                InputLabelProps={{shrink: true}}  
                                                margin="normal" 
                                                variant="outlined"   
                                                label={salesCarsNames['administration.'+key]}
                                                name={key}
                                                value={data[key]}
                                                error ={administration[key]?true:false} 
                                                helperText={administration[key]} 
                                                onChange={(e)=>this.onChange(e, i)}  
                                            />
                                        </Grid>   
                                    ) 
                                }
                            }else{
                                return(
                                    <Grid item md={3} xs={12} key={i}>  
                                        <TextField inputProps={{'className':'data-hj-allow'}} className="full-width mb-20" 
                                            InputLabelProps={{shrink: true}}  
                                            margin="normal" 
                                            variant="outlined"   
                                            label={salesCarsNames['administration.'+key]}
                                            name={key}
                                            value={data[key]}
                                            error ={administration[key]?true:false} 
                                            helperText={administration[key]} 
                                            onChange={(e)=>this.onChange(e, i)}  
                                        />
                                    </Grid>   
                                ) 
                            } 
                        } 
                    })} 
                </Grid>  
            </>
         );
    }
}

const mapStateToProps = (state, props) => {  
    return{ 
        formUserPermissions:state.roles.formUserPermissions,  
    } 
}
export default connect(mapStateToProps, { updateSingleSaleCarsState})(Administration);
 
 

 