import React, { Component } from 'react';

import {Link} from 'react-router-dom';
import {connect} from 'react-redux'
import { withRouter } from "react-router-dom";

import Grid from '@material-ui/core/Grid';
import {MuiPickersUtilsProvider,DatePicker } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import "moment/locale/bg";
 
import MUIDataTable from "mui-datatables"; 
import Card from "@material-ui/core/Card";  
import {columns} from "../../../helpers/viewCars";  
import {options} from "../../../helpers/viewCars";  
import {storeColumnsFilters, getStoredColumnsFilters} from "../../../helpers/storeColumns";  

import {fetchCars} from '../../../_actions/carsActions'


class CarsList extends Component {
    state={
        permissions: JSON.parse(localStorage.getItem('permissions')),
        roleId: JSON.parse(localStorage.getItem('roleId')),
        startDate:'',
        endDate:'',
        errors:[], 
        options:{},
        columns:[] 
    }
    componentDidMount (){
            this.setState({
                options: storeColumnsFilters('carsTable', options),
                columns: getStoredColumnsFilters('carsTable', columns)
            }) 
        this.props.fetchCars('')
        this.getTodayDate();
    } 

    getTodayDate = () =>{
        let today = new Date();
        let firstDayOfMonth = '';
        let dd = String(today.getDate()).padStart(2, '0');
        let mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
        let yyyy = today.getFullYear();

        firstDayOfMonth = yyyy + '-' + mm + '-' + '01'; 
        today = yyyy + '-' + mm + '-' + dd;  
        
        this.setState({
            startDate:firstDayOfMonth,
            endDate:today
        })
    }
    
    onDownload = (buildHead, buildBody, columns, data) => {
        return "\uFEFF" + buildHead(columns) + buildBody(data); 
    } 
    
    onRowClick=(rowData , rowMeta, e) => {  
        const {permissions= {}} = this.state; 
        if (permissions.carsUpdate) {
            this.props.history.push("/dashboard/cars/"+rowData[0]);
        }
    }

    onChangeDate = (date, name) =>{
        let dd = String(date._d.getDate()).padStart(2, '0');
        let mm = String(date._d.getMonth() + 1).padStart(2, '0'); //January is 0!
        let yyyy = date._d.getFullYear();
        let day = yyyy + '-' + mm + '-' + dd;  
        this.setState({
            [name]:day
        })  
    } 

    onClick = () =>{
        const {startDate, endDate} = this.state; 
        this.props.fetchCars('?fromDate='+startDate+'&toDate='+endDate)
    }
    render() {  
        const {cars} = this.props; 
        const {errors, startDate, endDate, permissions= {}, roleId} = this.state; 
        const {options, columns} = this.state;  
        return (  
            <React.Fragment>  
                <Grid container className="mb-20">
                    <Grid container item xs={12} md={5} alignItems="center"> 
                        {roleId!==19&&permissions.carsStore&&<Link className="btn btn-info mb-20-m" to="/dashboard/create-car"><i className="flaticon-add-circular-button"/>
                            Добави нов тестови aвтомобил
                        </Link>} 
                    </Grid>   
                    <Grid container item xs={12} spacing={3} sm={7} alignItems="center"> 
                        <MuiPickersUtilsProvider locale="bg" utils={MomentUtils}> 
                            <Grid container item xs={6} sm={4} alignItems="center"> 
                                <DatePicker
                                    autoOk
                                    className='full-width'
                                    ampm={false}  
                                    inputVariant="outlined"
                                    // format="DD.MM.YYYY"
                                    format="DD.MM.YYYY"
                                    margin="normal"
                                    name='startDate'
                                    value={startDate}
                                    onChange={(date)=>this.onChangeDate(date,'startDate')}
                                    error ={errors.startDate?true:false} 
                                    helperText={errors.startDate}
                                    label="Пробег от:"
                                />
                            
                            </Grid>   
                            <Grid container item xs={6} sm={4} alignItems="center">  
                                <DatePicker
                                    autoOk
                                    className='full-width'
                                    ampm={false}  
                                    inputVariant="outlined"
                                    format="DD.MM.YYYY"
                                    margin="normal"
                                    name='endDate'
                                    value={endDate}
                                    onChange={(date)=>this.onChangeDate(date,'endDate')}
                                    label="Пробег до:"
                                    error ={errors.endDate?true:false} 
                                    helperText={errors.endDate}
                                />
                            </Grid>   
                            <Grid container item xs={12} sm={4} alignItems="center"  className="p-tb-0">  
                                <button className="btn btn btn-focus full-width" onClick={this.onClick}>Покажи</button>
                            </Grid>   
                        </MuiPickersUtilsProvider>
                    </Grid>  
                </Grid> 
                   
                <Card>
                    <MUIDataTable
                        title={"Тестови автомобили:"}
                        data={cars}
                        columns={columns} 
                        options={{...options, onRowClick: this.onRowClick, onDownload:this.onDownload}}  
                    /> 
                </Card> 
            </React.Fragment> 
         );
    }
}
 
const mapStateToProps = state => {   
   return({cars:state.cars.items})
}

export default withRouter(connect(mapStateToProps, { fetchCars })(CarsList));
// export default ViewUsers;