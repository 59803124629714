import React, { Component } from 'react';
 
import Grid from '@material-ui/core/Grid';

import {Link} from 'react-router-dom';
import {connect} from 'react-redux'
import { withRouter } from "react-router-dom";

import MUIDataTable from "mui-datatables"; 
import Card from "@material-ui/core/Card";  
import { CircularProgress } from '@material-ui/core';

import {columns} from "../../../helpers/viewDriveList";  
import {options} from "../../../helpers/viewDriveList";  
import {storeColumnsFilters, getStoredColumnsFilters} from "../../../helpers/storeColumns";  

import {fetchDriveList, suncFixi} from '../../../_actions/driveListActions'
import {setMessage, removeMessage} from '../../../_actions/msgActions'

class DriveList extends Component {
    state={
        permissions: JSON.parse(localStorage.getItem('permissions')),
        roleId: JSON.parse(localStorage.getItem('roleId')),
        data:[], 
        options:{},
        columns:[],
        isLoading:false,
    }
    componentWillMount (){
        this.setState({
            options: storeColumnsFilters('driveListTable', options),
            columns: getStoredColumnsFilters('driveListTable', columns),
            isLoading:true
        }) 
        this.props.fetchDriveList().then(data => {  
            this.setState({isLoading:false}) 
        }) 
    }  

    componentDidUpdate(previousProps, previousState){ 
        if(previousProps.driveList !== this.props.driveList){
            const query = new URLSearchParams(this.props.location.search);
            const userId = query.get('userId')
            const contactId = query.get('contactId')
            const clientId = query.get('clientId')
            if (userId) { 
                let filtered = this.props.driveList.filter(item=>{ 
                    return item.userId === userId
                })
                this.setState({data:filtered})
            }else if(contactId){
                let filtered = this.props.driveList.filter(item=>{ 
                    return item.contactId === contactId
                })
                this.setState({data:filtered})
            }else if(clientId){
                let filtered = this.props.driveList.filter(item=>{ 
                    return item.clientId === clientId
                })
                this.setState({data:filtered})
            }else{
                this.setState({data:this.props.driveList})
            }
        }
    }
    
    onRowClick=(rowData , rowMeta, e) => {  
        const {permissions= {}} = this.state; 
        if (permissions.testDrivesUpdate) {
            this.props.history.push("/dashboard/drive-list/"+rowData[0]);
        } 
    }

    onDownload = (buildHead, buildBody, columns, data) => { 
        return "\uFEFF" + buildHead(columns) + buildBody(data); 
    } 
    onSuncFixi = () => {  
        this.props.suncFixi().then(data => {  
            if(data.status==="success"){ 
                this.props.setMessage(data.message)
                this.props.removeMessage() 
                this.props.fetchDriveList()
            }  
        }) 
    } 

    render() {  

        const {data, isLoading, permissions= {}, roleId} = this.state; 
      
        const {options, columns} = this.state;   
        const loadingComponent = (
            <div style={{position: 'absolute', zIndex: 110, top: 0, left: 0, width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', background: 'rgba(255,255,255,0.8)'}}>
            <CircularProgress size={24} />
        </div>
        );
        return (  
            <React.Fragment> 
                <Grid container spacing={3} className="mb-20 header-section"> 
                    <Grid item xs={6}>  
                        {(permissions.testDrivesStore && roleId!==19)&&<Link  
                            className="btn btn-info"
                            to="/dashboard/create-testDrive" >
                            <i className="flaticon-add-circular-button"/>
                            Добави TestDrive
                        </Link>} 
                    </Grid>
                    <Grid container item xs={6} justify="flex-end"> 
                        {roleId!==19&&<button  
                            onClick={this.onSuncFixi}
                            className="btn btn btn-focus"> 
                            Синхронизирай
                        </button>} 
                    </Grid>   
                </Grid> 
                   
                <Card style={{'position':'relative'}}>
                    {isLoading && loadingComponent}
                    <MUIDataTable
                        title={"Списък с Test Drive:"}
                        data={data}
                        columns={columns} 
                        options={{...options, onRowClick: this.onRowClick, onDownload:this.onDownload }}  
                    /> 
                </Card> 
            </React.Fragment> 
         );
    }
}
 
const mapStateToProps = state => { 
   return({driveList:state.driveList.items})
}

export default withRouter(connect(mapStateToProps, { fetchDriveList, suncFixi,setMessage, removeMessage })(DriveList));
// export default ViewUsers;