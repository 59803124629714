import React, { Component } from 'react';
import {connect} from 'react-redux'
import {Link} from 'react-router-dom';
 
import Grid from '@material-ui/core/Grid';
import Card from "@material-ui/core/Card";  

import Vehicle from "./Vehicle";  
import Tehnical from "./Technical";  
import Prices from "./Prices";  
import Administration from "./Administration";  
import Customer from "./Customer";  
import Logistic from "./Logistic";  
import Equipment from "./Equipment";  
import Documents from "./Documents";  

import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';

import MomentUtils from "@date-io/moment";
import moment from "moment";
import {MuiPickersUtilsProvider, KeyboardDatePicker} from '@material-ui/pickers';

import {fetchSingleSaleCars, setStatusSaleCar, editSingleSaleCars, fetchSingleSaleCarsDocuments,bookCar} from '../../../_actions/carsSaleActions'
import {fetchFormUserPermissions} from '../../../_actions/rolesActions'


class EditCar extends Component {
    
    state={
        data:{},
        permissions: JSON.parse(localStorage.getItem('permissions')),
        roleId: JSON.parse(localStorage.getItem('roleId')),
        editMode:false,
        activeStep:1,
        status:1,
        errors:[],
        pushToState:false,
        showEquipment:false,
        showDocuments:false,
        toDate:'',
        showBookOptions:false,
        openReturnPopUp:false
    }
    
    componentDidMount(){  
        this.props.fetchSingleSaleCars(this.props.match.params._id);
        this.props.fetchSingleSaleCarsDocuments(this.props.match.params._id);
        this.props.fetchFormUserPermissions();
        if (this.props.match.params._id) {
            this.setState({editMode:true})
        }

        this.unlisten = this.props.history.listen((location, action) => { 
            const query = new URLSearchParams(location.search);
            const refresh = query.get('refresh');
            if(refresh){  
                this.props.fetchSingleSaleCars(this.props.match.params._id);   
                this.setState({editMode:true, showBookOptions:true}) 
            }  
        });
    } 
    componentWillUnmount() {
        this.unlisten();
    }
   
    componentDidUpdate(previousProps, previousState){ 
        if(previousProps.singleSaleCar !== this.props.singleSaleCar){
 
            this.setState({
                data:this.props.singleSaleCar,
                status:this.props.singleSaleCar.vehicle.status,
                toDate:this.props.singleSaleCar.vehicle.statusFinishOn,
                id:this.props.singleSaleCar.id
            },()=>{
                if (this.props.singleSaleCar.vehicle.statusFinishOn.length>0) {
                    this.setState({ 
                        showBookOptions:true
                    })
                }
            })
        }
    }

    switchStep = (id) => { this.setState({activeStep:id})} 

    toggleEquipment = () => { 
        this.setState({
            showEquipment:!this.state.showEquipment,
            showDocuments:false
        })
    } 

    toggleDocuments = () => { 
        this.setState({
            showDocuments:!this.state.showDocuments,
            showEquipment:false
        })
    } 

    onSubmit = (event) => {
        event.preventDefault(); 
        this.setState({ pushToState:true},()=>{this.setState({pushToState:false },
            ()=>{
                this.props.editSingleSaleCars(this.props.singleSaleCar).then(data => { 
        
                    if(data.status==="success"){
                        this.setState({errors:[]}) 
                        this.props.history.push('/dashboard/sale_cars')
                    } 
                    else if (data.errors) {
                        this.setState({errors:data.errors})   
                    } 
                })  
            }
        )})
    }

    onChange=(event)=>{
        const {value, name} = event.target;
        this.setState({  
            [name]: value
        })
    }

    createOffer = () => { 
        this.props.history.push("/dashboard/create-offer?selected_car="+this.state.data.vehicle.id);
    }

    changeStatus = ()=>{ 
        this.props.setStatusSaleCar(this.state.id,this.state.status) 
    }

    onChangeDate = (input, date, value) => {   
        this.setState({ 
            [input]:value,
            errors:{
                ...this.state.errors,
                [input]:''
            } 
        })  
    }

    reserve = () =>{
        const obj={
            book:1,
            toDate:this.state.toDate
        }
        this.props.bookCar(this.props.match.params._id, obj).then(data => {  
            if(data.status==="success"){  
                this.props.history.push("/dashboard/sale_cars/"+data.data.id+"/?refresh=true");
                this.setState({
                    toDate:this.props.singleSaleCar.vehicle.statusFinishOn,
                    showBookOptions:true,
                })
            }
        })
    }

    showDatePicker = () =>{
        this.setState({showBookOptions:true})
    }

    returnVehicle = () =>{
        this.props.setStatusSaleCar(this.state.id,1).then(data => {  
            if(data.status==="success"){  
                this.setState({
                    openReturnPopUp:false
                    ,status:1
                })
            }
        }) 
    }

    openReturnPopUp = (val) => {
        this.setState({openReturnPopUp:val})
    }
    render() { 
        const {administration,customer,logistic,vehicle,company,technical, price}= this.state.data;
        const {errors,editMode,activeStep, pushToState, status,showEquipment,showDocuments,toDate, permissions= {}, showBookOptions, openReturnPopUp, roleId} = this.state; 
        const pageTitle = editMode ? 'Редактиране на aвтомобил:' : 'Създаване на aвтомобил:';  
 
        const errorStep1= errors.vehicle;
        const errorStep2= errors.technical;
        const errorStep3= errors.price;
        const errorStep4= errors.administration;
        const errorStep5= errors.customer;
        const errorStep6= errors.logistic;
         
        return (  
            
            <React.Fragment>  
                <div className={"returnVehicle-pop-up " +(openReturnPopUp ? 'show':'') }>
                    <div className="inner">
                    <div className="close" onClick={()=>this.openReturnPopUp(false)}><i className="fas fa-times-circle"></i></div>
                        <h3>Този автомобил ще бъде пуснат за проджба със статус:<br/> 
                        <strong>"Свободен"</strong>'</h3> 
                        <div className="btn-wrapper">
                            <button type="button" onClick={this.returnVehicle} className="btn btn-success small">да</button>
                            <button type="button" className="btn btn-danger small" onClick={()=>this.openReturnPopUp(false)}>отказ</button>
                        </div>
                    </div>
                </div>
                <Card >
                    <Grid container className="section-title-wrapper padding" spacing={3}> 
                        <Grid container item md={4}  xs={12} alignItems="center"> 
                            <h3 className="section-title">
                                <i className="flaticon2-gear"></i> {pageTitle}
                            </h3>
                        </Grid>  
                        <Grid container item xs={12} md={3}  alignItems="center"> 
                            {permissions.salesCarsUpdateStatus&&<FormControl style={{'marginTop': '9px'}} variant="outlined" margin="normal" className='full-width select-styles'>
                                    <InputLabel id="status">
                                        Статус 
                                    </InputLabel>
                                    <Select  
                                        MenuProps={{
                                            anchorOrigin: {
                                            vertical: "bottom",
                                            horizontal: "right"
                                            },
                                            transformOrigin: {
                                            vertical: "top",
                                            horizontal: "right"
                                            },
                                            getContentAnchorEl: null
                                        }} 
                                        labelId="status"
                                        required   
                                        inputProps={{
                                            name: 'status', 
                                        }} 
                                        value={status} 
                                        onChange={this.onChange}
                                        labelWidth={60}  >   
                                        <MenuItem value={1}>Свободен</MenuItem>  
                                        <MenuItem value={2}>Резервиран</MenuItem>  
                                        <MenuItem value={3}>Капариран</MenuItem>  
                                        <MenuItem value={4}>Промоционален</MenuItem>  
                                        <MenuItem value={5}>Резервиран Промоционален</MenuItem>  
                                        <MenuItem value={6}>Поръчан</MenuItem>  
                                        <MenuItem value={7}>Продаден</MenuItem>  
                                 
                                    </Select> 
                            </FormControl> }
                        </Grid>  
                        <Grid container item md={2} xs={12} alignItems="center"> 
                            {(roleId!==19&&permissions.salesCarsUpdateStatus)&&<button onClick={this.changeStatus}className="btn btn-focus full-width "><i className="fas fa-save"></i>смени статус</button>}
                        </Grid>        
                        <Grid container item md={3} xs={12}alignItems="center" justify="flex-end">  
                            {roleId!==19&&<button onClick={this.createOffer}className="btn btn-success mr-10">Създай оферта</button>}  
                            <Link  
                                className="btn btn-secondary"
                                to="/dashboard/sale_cars" 
                                ><i className="flaticon2-circular-arrow"/>
                                Назад
                            </Link>   
                        </Grid>  
                    </Grid>  
                    <Grid container className="padding cars-form">
                        <Grid container spacing={3}  alignItems={'flex-start'} style={{'margin':'20px 0 40px'}}> 
                            {!showBookOptions&&<><Grid container item md={4} justify={'flex-end'}>
                                {roleId!==19&&<button type="button" onClick={this.showDatePicker} className='btn btn-success full-width'>
                                    резервирай Автомобил
                                </button>}
                            </Grid></> 
                            }
                            {showBookOptions&&<><Grid container item md={2} justify={'flex-end'}>
                                <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                                    <KeyboardDatePicker inputProps={{'className':'data-hj-allow'}} 
                                        autoOk={true}
                                        showTodayButton={true} 
                                        InputLabelProps={{shrink: true}} 
                                        style={{'marginTop':'0', 'marginBottom':"0"}}
                                        className="full-width date"  
                                        inputVariant="outlined" 
                                        margin="normal"
                                        label="Резервирана до дата:" 
                                        format="DD.MM.YYYY"
                                        value=''
                                        inputValue={toDate} 
                                        onChange={(e,d)=>this.onChangeDate('toDate', e ,d)}
                                        // rifmFormatter={this.dateFormatter} 
                                        error ={errors.toDate?true:false}  
                                        helperText={errors.toDate} 
                                    />
                                </MuiPickersUtilsProvider>
                            </Grid> 
                            <Grid container item md={2} justify={'flex-end'}>
                                <button type="button" onClick={this.reserve} className='btn btn-focus full-width'>резервирай    
                                </button>
                            </Grid></> 
                            }
                             <Grid container item md={2} justify={'flex-end'}>
                             {(roleId!==19&&status!==1)&& <button type="button" onClick={()=>this.openReturnPopUp(true)} className='btn btn-focus full-width'>върни    
                                </button>} 
                            </Grid> 
                            <Grid container item md={6} xs={12} justify={'flex-end'}>
                                {!showEquipment&&<button 
                                onClick={this.toggleDocuments} 
                                className={"btn btn-primary " +(showDocuments?'':'mr-10')}>
                                    {showDocuments?<i className="flaticon2-circular-arrow"></i>:<i className="far fa-folder-open"></i>} 
                                    {showDocuments? ' назад към автомобилa':'Документи'} 
                                </button>}     
                                {!showDocuments&&<button 
                                onClick={this.toggleEquipment}
                                className={"btn btn-primary"}> 
                                    {showEquipment?<i className="flaticon2-circular-arrow"></i>:<i className="fas fa-tools"></i>} 
                                    {showEquipment?'назад към автомобилa':'оборудване'} 
                                </button>} 
                            </Grid> 
                        </Grid>  
                        {(!showDocuments && !showEquipment)&&<>
                        <Grid container spacing={3}> 
                            <Grid item md={12} xs={12} className="step-wrapper" style={{'justifyContent':'space-between', 'marginBottom':'50px'}}>   
                                <div className={"single-step " +(activeStep === 1 ?"active ":"")+ (errorStep1 ? "error":"")}
                                    onClick={()=>{this.switchStep(1)}}>
                                    <div className='step'>
                                        <i className="fas fa-car"></i>
                                    </div>
                                    <p className="step-title">
                                        Aвтомобил
                                    </p>
                                </div> 
                                <div className='separator'></div>
                                <div className={"single-step " +(activeStep === 2 ?"active ":"")+ (errorStep2 ? "error":"")}
                                    onClick={()=>{this.switchStep(2)}}>
                                    <div className='step'>
                                        <i className="fas fa-car"></i>
                                    </div>
                                    <p className="step-title">
                                        Teхн. спецификация
                                    </p>
                                </div> 
                                <div className='separator'></div>
                                <div className={"single-step " +(activeStep === 3 ?"active ":"")+ (errorStep3 ? "error":"")}
                                    onClick={()=>{this.switchStep(3)}}>
                                    <div className='step'>
                                        <i className="flaticon-price-tag"></i>
                                    </div>
                                    <p className="step-title">
                                        Цени
                                    </p>
                                </div> 
                                <div className='separator'></div>
                                <div className={"single-step " +(activeStep === 4 ?"active ":"")+ (errorStep4 ? "error":"")}
                                    onClick={()=>{this.switchStep(4)}}>
                                    <div className='step'>
                                        <i className="flaticon-cogwheel-1"></i>
                                    </div>
                                    <p className="step-title">
                                        Администрация
                                    </p>
                                </div> 
                                <div className='separator'></div>
                                <div className={"single-step " +(activeStep === 5 ?"active ":"") + (errorStep5 ? "error":"")} 
                                    onClick={()=>{this.switchStep(5)}}>
                                    <div className='step'>
                                        <i className="fas fa-user-cog"></i>
                                    </div>
                                    <p className="step-title">
                                        Клиент
                                    </p>
                                </div>  
                                <div className='separator'></div>
                                <div className={"single-step " +(activeStep === 6 ?"active ":"")+ (errorStep6 ? "error":"")}  
                                    onClick={()=>{this.switchStep(6)}}>
                                    <div className='step'>
                                        <i className="fas fa-shipping-fast"></i>
                                    </div>
                                    <p className="step-title">
                                        Логистика
                                    </p>
                                </div>   
                            </Grid>  
                        </Grid> 
                      
                        
                        {activeStep === 1 &&<Vehicle pushToState= {pushToState} errors={errors} data={vehicle} company={company}/>}
                        {activeStep === 2 &&<Tehnical pushToState= {pushToState} errors={errors} data={technical}/>}
                        {activeStep === 3 &&<Prices pushToState= {pushToState} errors={errors} data={price}/>}
                        {activeStep === 4 &&<Administration pushToState= {pushToState} errors={errors} data={administration}/>}
                        {activeStep === 5 &&<Customer errors={errors} data={customer}/>}
                        {activeStep === 6 &&<Logistic pushToState= {pushToState} errors={errors} data={logistic}/>}
                     
                        {(activeStep === 1 || activeStep === 3 || activeStep === 4 || activeStep === 6)&&<Grid container spacing={2} >   
                            <Grid container item md={4} xs={12} >    
                                {roleId!==19&&<button type="button" onClick={this.onSubmit} className="btn btn-info full-width">
                                    <i className="flaticon2-add-square"></i>
                                    {editMode?'ЗАПАЗИ':'Създай'}
                                </button>}   
                            </Grid> 
                        </Grid>}   
                        </>}
                        {showEquipment&& <Equipment/>}
                        {showDocuments&& <Documents/>}
                    </Grid>
                   
                </Card> 
            </React.Fragment>
         );
    }
}

const mapStateToProps = (state, props) => {  
    return{ 
        singleSaleCar:state.carsSale.singleSaleCar,  
    } 
}
export default connect(mapStateToProps, {fetchSingleSaleCars,bookCar, editSingleSaleCars, setStatusSaleCar, fetchFormUserPermissions,fetchSingleSaleCarsDocuments })(EditCar);
 
 
 