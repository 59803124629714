import React, { Component } from 'react';
import ReactDOM from "react-dom";

import {Link} from 'react-router-dom';
import {connect} from 'react-redux'
import { withRouter } from "react-router-dom";

import Grid from '@material-ui/core/Grid';
 

import MUIDataTable from "mui-datatables"; 
import Card from "@material-ui/core/Card";  
import {columns, options} from "../../../../helpers/viewRoles";   
import {storeColumnsFilters, getStoredColumnsFilters} from "../../../../helpers/storeColumns";
import {fetchRoles} from '../../../../_actions/rolesActions'


class RolesList extends Component { 
    state={
        permissions: JSON.parse(localStorage.getItem('permissions')),
        options:{},
        columns:[],
        roleId: JSON.parse(localStorage.getItem('roleId')), 
     }

    componentDidMount (){
        this.setState({
            options: storeColumnsFilters('rolesTable', options),
            columns: getStoredColumnsFilters('rolesTable', columns)
        }) 
        this.props.fetchRoles() 
    } 

    onDownload = (buildHead, buildBody, columns, data) => {
        return "\uFEFF" + buildHead(columns) + buildBody(data); 
    } 
 
    render() {  
        const {roles} = this.props;  
        const {options, columns, permissions= {}, roleId} = this.state; 
        return (  
            <React.Fragment>  
                <Grid container className="mb-20">
                    <Grid container item xs={12} sm={6} alignItems="center"> 
                        {(roleId!==19&&permissions.rolesStore)&&<Link className="btn btn-info" to="/dashboard/create-role"><i className="flaticon-add-circular-button"/>
                            Създай нова роля
                        </Link>}  
                    </Grid>    
                </Grid> 
                   
                <Card>
                    <MUIDataTable
                        title={"Роли:"}
                        className="roles-table"
                        data={roles}
                        columns={columns} 
                        options={{...options, onDownload:this.onDownload}}  
                    /> 
                </Card> 
            </React.Fragment> 
         );
    }
}
 
const mapStateToProps = state => {   
   return({roles:state.roles.items})
}

export default withRouter(connect(mapStateToProps, { fetchRoles })(RolesList));
// export default ViewUsers;